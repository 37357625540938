<template>
  <div class="card-container" v-if="users.length">

    <table>
      <thead>
        <th>No</th>
        <th>Names</th>
        <th>Phone</th>
        <th>Action</th>
      </thead>
      <tbody>
        <tr v-for="(user, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>{{ user.phone }}</td>
          <td>
            <button class="permission-button" @click="viewPermissions(user.id)">View Permissions</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
    <div class="modal-wrapper">
      <teleport to="body">
        <transition name="fade">
          <div v-if="showModal" class="modal-overlay" @click="closeModal">
            <div class="modal" @click.stop>
              <div class="modal-header">

                <p>Permissions for {{ modalUserName }}</p>

                <svg @click="closeModal" width="36" height="36" viewBox="0 0 36 36" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
                    fill="#757780" />
                </svg>

              </div>
              <div class="modal-body">
                <div class="modal-body">
                  <div class="modal-body-container">
                    <div v-for="(permissions, type) in permissions" :key="type">
                      <h3>{{ type }}</h3>
                      <ul>
                        <li v-for="permission in permissions" :key="permission.id">{{ permission.description }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </teleport>
    </div>
  </div>
  <div class="spinner-container" v-else>
    <loading_spinner />
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import loading_spinner from "@/components/loading_spinner.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");

export default {
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 7,
      users: [],
      showModal: false,
      permissions: [],
      modalUserName: '',
    };
  },
  mounted() {
    this.fetch_admin();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter((item) => regex.test(item.name));
      }
    },

    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "admins.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text("Admins", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("admins.pdf");
    },
    async fetch_admin() {
      try {
        const response = await axios.get("admin/all/admin");

        this.users = response.data.data;

        console.log(this.users);
      } catch (error) {
        setTimeout(() => {
          this.fetch_admin();
        }, 5000);
      }
    },
    async viewPermissions(userId) {
      try {
        const response = await axios.get(`admin/permissions/${userId}`);
        const permissions = response.data.data;

        // Group permissions by type
        const groupedPermissions = permissions.reduce((acc, permission) => {
          if (!acc[permission.type]) {
            acc[permission.type] = [];
          }
          acc[permission.type].push(permission);
          return acc;
        }, {});

        this.permissions = groupedPermissions;
        const user = this.users.find(user => user.id === userId);
        this.modalUserName = `${user.first_name} ${user.last_name}`;
        this.showModal = true;
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  components: {
    loading_spinner
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid #E4E7EC;

    thead {
      background-color: #F9FAFB;
      color: #344054;
      border-bottom: 1px solid #E4E7EC;

      th {
        padding: 15px 16px;
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
        }

        border-bottom: 1px solid #E4E7EC;
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}

.spinner-container {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: relative;
  // overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  // backdrop-filter: blur(15px);
  z-index: 99999999;
}

.modal {
  background: white;
  padding: 32px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  width: 542px;
  min-height: 258px;

  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: sticky;
    top: 0;

    p {
      font-size: 20px;
      color: #344054;
      font-weight: 400;
    }

    svg {
      cursor: pointer;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;


    .modal-body-container {
      display: flex;
      gap: 30px;
      flex-direction: column;
      gap: 24px;

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 6px;


        label {
          font-size: 14px;
          color: #344054;
        }


        input {
          padding: 12px 14px;
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          outline: 2px solid transparent;
          transition: 200ms ease;
          color: #344054;


          &:focus,
          &:valid {
            outline: 2px solid #5582e2;
          }


          &::placeholder {
            font-size: 16px;
          }
        }

      }

      .btn-container {
        button {
          width: 169px;
          height: 44px;
          border: none;
          border-radius: 8px;


          background-color: rgba(0, 106, 245, 1);
          color: white;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            background-color: rgb(4, 91, 206);
          }
        }
      }
    }
  }
}

.fade-enter-active {
  $modalOpen: True;

  @if $modalOpen ==True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.select-container {
  position: relative;
}
.permission-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    outline: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.permission-button:hover {
    background-color: #0056b3;
}
</style>
