<template>
    <div class="bulk-sms-container">
        <div class="header">
            <p>Send personalized messages to multiple recipients with ease. Choose to send SMS manually to individual
                recipients or upload an Excel file for bulk messaging.</p>
        </div>

        <div class="page-body">
            <div class="side-tabs">
                <button :class="{ 'active': selectedTab === 'manual' }" @click="selectedTab = 'manual'">Manually
                    Receiver</button>
                <button :class="{ 'active': selectedTab === 'excel' }" @click="selectedTab = 'excel'">Excel File
                    Receiver</button>
                <button :class="{ 'active': selectedTab === 'database' }" @click="selectedTab = 'database'">Add Existing
                    Database</button>
            </div>

            <div class="side-content" v-if="selectedTab === 'manual'">
                <h1>Manual SMS</h1>
                <div class="form-container">
                    <form @submit.prevent="sendManualSms">
                        <div class="form-group">
                            <label for="senderId">Sender ID</label>
                            <input type="text" v-model="manualSms.senderId" placeholder="Sender" disabled required>
                        </div>
                        <div class="form-group">
                            <label for="messageBody">Message Body</label>
                            <textarea v-model="manualSms.messageBody" @input="calculateManualSmsCount"
                                placeholder="Type Your message here" required></textarea>
                            <p>{{ manualSmsCountMessage }}</p>
                        </div>
                        <div v-for="(phone, index) in phoneList" :key="index" class="form-group">
                            <label for="phoneNumber">Phone Number</label>
                            <input type="text" v-model="phoneList[index]" placeholder="Phone Number" required>
                            <button v-if="index !== phoneList.length - 1" @click="removePhone(index)"
                                class="remove-button">Remove Phone</button>
                        </div>
                        <div class="foot-content">
                            <button type="button" @click="addPhone" class="add-button">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9 7.5C10.6569 7.5 12 6.15685 12 4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5C6 6.15685 7.34315 7.5 9 7.5ZM9 15.75C11.8995 15.75 14.25 14.4069 14.25 12.75C14.25 11.0931 11.8995 9.75 9 9.75C6.1005 9.75 3.75 11.0931 3.75 12.75C3.75 14.4069 6.1005 15.75 9 15.75Z"
                                        fill="#006AF5" />
                                </svg>
                                Add New
                            </button>
                            <button type="submit" class="send-button">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M9.22156 14.3539L8.81499 15.0408C8.45262 15.653 7.54735 15.653 7.18498 15.0408L6.7784 14.3539C6.46305 13.8211 6.30537 13.5547 6.05209 13.4074C5.79882 13.2601 5.47994 13.2546 4.84218 13.2436C3.90066 13.2274 3.31017 13.1697 2.81494 12.9645C1.89608 12.5839 1.16605 11.8539 0.785452 10.9351C0.5 10.2459 0.5 9.37228 0.5 7.625V6.875C0.5 4.41993 0.5 3.19239 1.0526 2.29063C1.36181 1.78605 1.78605 1.36181 2.29063 1.0526C3.19239 0.5 4.41993 0.5 6.875 0.5H9.125C11.5801 0.5 12.8076 0.5 13.7094 1.0526C14.214 1.36181 14.6382 1.78605 14.9474 2.29063C15.5 3.19239 15.5 4.41993 15.5 6.875V7.625C15.5 9.37228 15.5 10.2459 15.2145 10.9351C14.8339 11.8539 14.1039 12.5839 13.1851 12.9645C12.6898 13.1697 12.0993 13.2274 11.1578 13.2436C10.52 13.2546 10.2011 13.2601 9.94788 13.4074C9.6946 13.5547 9.53692 13.8211 9.22156 14.3539ZM5 7.8125C4.68934 7.8125 4.4375 8.06434 4.4375 8.375C4.4375 8.68566 4.68934 8.9375 5 8.9375H9.125C9.43566 8.9375 9.6875 8.68566 9.6875 8.375C9.6875 8.06434 9.43566 7.8125 9.125 7.8125H5ZM4.4375 5.75C4.4375 5.43934 4.68934 5.1875 5 5.1875H11C11.3107 5.1875 11.5625 5.43934 11.5625 5.75C11.5625 6.06066 11.3107 6.3125 11 6.3125H5C4.68934 6.3125 4.4375 6.06066 4.4375 5.75Z"
                                        fill="white" />
                                </svg>
                                Send SMS
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="side-content" v-if="selectedTab === 'excel'">
                <h1>Excel File Receiver</h1>
                <button @click="downloadSampleFile" class="download-button"><i class="fa fa-download"></i> Sample
                    File</button>
                <form @submit.prevent="sendBulkSms">
                    <div class="form-group">
                        <label for="bulkSenderId">Sender ID</label>
                        <input type="text" id="bulkSenderId" v-model="bulkSms.senderId" placeholder="Sender" disabled required>
                    </div>
                    <div class="form-group">
                        <label for="bulkMessageBody">Message Body</label>
                        <textarea id="bulkMessageBody" v-model="bulkSms.messageBody" @input="calculateBulkSmsCount"
                            placeholder="Type Your message here" required></textarea>
                        <p>{{ bulkSmsCountMessage }}</p>
                    </div>
                    <div class="input-container">
                        <label for="phoneNumbersFile">Upload Phone Numbers (Excel)</label>
                        <div class="file-upload-container" @click="openFileDialog('introQuestions')">
                            <div class="file-upload-message">
                                <svg width="46" height="46" viewBox="0 0 46 46" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
                                    <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB"
                                        stroke-width="6" />
                                    <g clip-path="url(#clip0_187_7398)">
                                        <path
                                            d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                                            stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_187_7398">
                                            <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p class="text">Drag & drop files here, or click to select files</p>
                            </div>
                            <input type="file" id="phoneNumbersFile" accept=".xls,.xlsx,.csv"
                                @change="onFileChange('introQuestions', $event)" ref="introQuestionsFileInput"
                                style="display: none;" />
                        </div>
                        <div v-if="introQuestionsFile" class="file-preview">
                            <p class="text">{{ introQuestionsFile.name }}</p>
                            <button @click.stop="removeFile('introQuestions')">Remove</button>
                        </div>
                    </div>
                    <div class="foot-content" style="padding-top: 12px;">
                        <button type="submit" class="send-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M9.22156 14.3539L8.81499 15.0408C8.45262 15.653 7.54735 15.653 7.18498 15.0408L6.7784 14.3539C6.46305 13.8211 6.30537 13.5547 6.05209 13.4074C5.79882 13.2601 5.47994 13.2546 4.84218 13.2436C3.90066 13.2274 3.31017 13.1697 2.81494 12.9645C1.89608 12.5839 1.16605 11.8539 0.785452 10.9351C0.5 10.2459 0.5 9.37228 0.5 7.625V6.875C0.5 4.41993 0.5 3.19239 1.0526 2.29063C1.36181 1.78605 1.78605 1.36181 2.29063 1.0526C3.19239 0.5 4.41993 0.5 6.875 0.5H9.125C11.5801 0.5 12.8076 0.5 13.7094 1.0526C14.214 1.36181 14.6382 1.78605 14.9474 2.29063C15.5 3.19239 15.5 4.41993 15.5 6.875V7.625C15.5 9.37228 15.5 10.2459 15.2145 10.9351C14.8339 11.8539 14.1039 12.5839 13.1851 12.9645C12.6898 13.1697 12.0993 13.2274 11.1578 13.2436C10.52 13.2546 10.2011 13.2601 9.94788 13.4074C9.6946 13.5547 9.53692 13.8211 9.22156 14.3539ZM5 7.8125C4.68934 7.8125 4.4375 8.06434 4.4375 8.375C4.4375 8.68566 4.68934 8.9375 5 8.9375H9.125C9.43566 8.9375 9.6875 8.68566 9.6875 8.375C9.6875 8.06434 9.43566 7.8125 9.125 7.8125H5ZM4.4375 5.75C4.4375 5.43934 4.68934 5.1875 5 5.1875H11C11.3107 5.1875 11.5625 5.43934 11.5625 5.75C11.5625 6.06066 11.3107 6.3125 11 6.3125H5C4.68934 6.3125 4.4375 6.06066 4.4375 5.75Z"
                                    fill="white" />
                            </svg>
                            Send SMS
                        </button>
                    </div>
                </form>
            </div>

            <div class="side-content" v-if="selectedTab === 'database'">
                <h1>Existing Database</h1>
                <form @submit.prevent="sendDatabaseSms">
                    <div class="form-group">
                        <br><label for="databaseCategory">Select Category</label><br>
                        <div class="radio-group">
                            <label>
                                <input type="radio" value="patient" v-model="databaseSms.category" required> Patient
                            </label>
                            <label>
                                <input type="radio" value="health_member" v-model="databaseSms.category" required>
                                Health Member
                            </label>
                            <label>
                                <input type="radio" value="community_member" v-model="databaseSms.category" required>
                                Community Member
                            </label>
                            <label>
                                <input type="radio" value="service_provider" v-model="databaseSms.category" required>
                                Service Provider
                            </label>
                            <label>
                                <input type="radio" value="all" v-model="databaseSms.category" required> All
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="senderId">Sender ID</label>
                        <input type="text" v-model="databaseSms.senderId" placeholder="Sender" disabled required>
                    </div>
                    <div class="form-group">
                        <label for="messageBody">Message Body</label>
                        <textarea v-model="databaseSms.messageBody" placeholder="Type Your message here"
                            @input="calculateDatabaseSmsCount" required></textarea>
                        <p>{{ databaseSmsCountMessage }}</p>
                    </div>
                    <button type="submit" class="send-button">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.22156 14.3539L8.81499 15.0408C8.45262 15.653 7.54735 15.653 7.18498 15.0408L6.7784 14.3539C6.46305 13.8211 6.30537 13.5547 6.05209 13.4074C5.79882 13.2601 5.47994 13.2546 4.84218 13.2436C3.90066 13.2274 3.31017 13.1697 2.81494 12.9645C1.89608 12.5839 1.16605 11.8539 0.785452 10.9351C0.5 10.2459 0.5 9.37228 0.5 7.625V6.875C0.5 4.41993 0.5 3.19239 1.0526 2.29063C1.36181 1.78605 1.78605 1.36181 2.29063 1.0526C3.19239 0.5 4.41993 0.5 6.875 0.5H9.125C11.5801 0.5 12.8076 0.5 13.7094 1.0526C14.214 1.36181 14.6382 1.78605 14.9474 2.29063C15.5 3.19239 15.5 4.41993 15.5 6.875V7.625C15.5 9.37228 15.5 10.2459 15.2145 10.9351C14.8339 11.8539 14.1039 12.5839 13.1851 12.9645C12.6898 13.1697 12.0993 13.2274 11.1578 13.2436C10.52 13.2546 10.2011 13.2601 9.94788 13.4074C9.6946 13.5547 9.53692 13.8211 9.22156 14.3539ZM5 7.8125C4.68934 7.8125 4.4375 8.06434 4.4375 8.375C4.4375 8.68566 4.68934 8.9375 5 8.9375H9.125C9.43566 8.9375 9.6875 8.68566 9.6875 8.375C9.6875 8.06434 9.43566 7.8125 9.125 7.8125H5ZM4.4375 5.75C4.4375 5.43934 4.68934 5.1875 5 5.1875H11C11.3107 5.1875 11.5625 5.43934 11.5625 5.75C11.5625 6.06066 11.3107 6.3125 11 6.3125H5C4.68934 6.3125 4.4375 6.06066 4.4375 5.75Z"
                                fill="white" />
                        </svg>
                        Send SMS
                    </button>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
import * as XLSX from 'xlsx';
import { useToast } from "vue-toastification";
import axios from "@/helpers/axios.js";

const Toast = useToast();

export default {
    data() {
        return {
            selectedTab: 'manual',
            manualSms: {
                senderId: 'iCLM',
                messageBody: ''
            },
            phoneList: [''],
            bulkSms: {
                senderId: 'iCLM',
                messageBody: ''
            },
            databaseSms: {
                category: '',
                senderId: 'iCLM',
                messageBody: ''
            },
            introQuestionsFile: null,
            phoneNumbers: [],
            manualSmsCountMessage: '',
            bulkSmsCountMessage: '',
            databaseSmsCountMessage: ''
        };
    },
    methods: {
        onFileChange(type, event) {
            const file = event.target.files[0];
            if (file && this.isValidFileType(file)) {
                if (type === 'introQuestions') {
                    this.introQuestionsFile = file;
                    this.handleFileUpload(file);
                }
            } else {
                Toast.error('Invalid file type. Please select a .xls, .xlsx, or .csv file.');
                this.removeFile(type);
            }
        },
        isValidFileType(file) {
            const validExtensions = ['.xls', '.xlsx', '.csv'];
            const fileExtension = file.name.split('.').pop();
            return validExtensions.includes(`.${fileExtension}`);
        },
        openFileDialog(type) {
            if (type === 'introQuestions') {
                this.$refs.introQuestionsFileInput.click();
            }
        },
        removeFile(type) {
            if (type === 'introQuestions') {
                this.introQuestionsFile = null;
                this.$refs.introQuestionsFileInput.value = null;
            }
        },
        calculateManualSmsCount() {
            const smsParts = Math.ceil(this.manualSms.messageBody.length / 160);
            this.manualSmsCountMessage = `Message will be sent as ${smsParts} SMS part(s).`;
        },
        calculateBulkSmsCount() {
            const smsParts = Math.ceil(this.bulkSms.messageBody.length / 160);
            this.bulkSmsCountMessage = `Each message will be sent as ${smsParts} SMS part(s).`;
        },
        addPhone() {
            this.phoneList.push('');
        },
        removePhone(index) {
            this.phoneList.splice(index, 1);
        },
        handleFileUpload(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                this.phoneNumbers = XLSX.utils.sheet_to_json(worksheet, { header: 1 }).flat();
                console.log(this.phoneNumbers);
            };
            reader.readAsArrayBuffer(file);
        },
        downloadSampleFile() {
            const data = [
                { PhoneNumber: '250788000000' },
                { PhoneNumber: '250782643555' }
            ];
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SampleFile");
            XLSX.writeFile(wb, "SampleFile.xlsx");
        },
        async sendManualSms() {
            try {
                const response = await axios.post('/bulksms', {
                    receiver_phone: this.phoneList,
                    sms: this.manualSms.messageBody,
                    type: 'manual',
                    sender: this.manualSms.senderId,
                    created_by: 1
                });
                Toast.success(response.data.message);
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to send SMS.');
            }
        },
        async sendBulkSms() {
            try {
                const formData = new FormData();
                formData.append('sms', this.bulkSms.messageBody);
                formData.append('type', 'file');
                formData.append('sender', this.bulkSms.senderId);
                formData.append('created_by', 1); 

                if (this.introQuestionsFile) {
                    formData.append('file', this.introQuestionsFile);
                }

                const response = await axios.post('/bulksms', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                Toast.success(response.data.message);
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to send SMS.');
            }
        },

        async sendDatabaseSms() {
            try {
                const response = await axios.post('/bulksms', {
                    receiver_phone: this.databaseSms.category,
                    sms: this.databaseSms.messageBody,
                    type: 'database',
                    sender: this.databaseSms.senderId,
                    created_by: 1
                });
                Toast.success(response.data.message);
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to send SMS.');
            }
        }
    }
};
</script>


<style scoped>
.foot-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bulk-sms-container {
    width: 100%;
    min-height: 100%;
    padding: 0 3%;


    .header {
        width: 760px;
    }

    .page-body {
        padding-top: 36px;
        display: flex;
        align-items: flex-start;
        gap: 20px;


        .side-tabs {
            width: 240px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            background-color: rgba(33, 72, 192, 0.1);
            padding: 8px;
            border-radius: 8px;


            button {
                padding: 12px 16px;
                background-color: transparent;
                border: none;
                border-radius: 8px;
                color: #0A2472;
                font-size: 16px;
                cursor: pointer;
            }

            .active {
                border: 1px solid #E4E7EC;
                background-color: white;
                font-weight: bold
            }
        }

        .side-content {
            flex-grow: 1;
            border-radius: 8px;
            border: 1px solid #E4E7EC;
            padding: 16px;

            h1 {
                color: #003E8F;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}





.main-container {
    overflow-y: auto !important;
}

.bulk-sms-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-card {
    text-align: center;
    background-color: #007bff;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.header-card h1 {
    margin: 0;
    font-size: 24px;
}

.header-card p {
    margin-top: 10px;
    font-size: 16px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    background-color: #f9f9f9;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 10px;
    outline: none;
    display: flex;
    align-items: center;
}

.tabs button i {
    margin-right: 8px;
}

.tabs button.active {
    background-color: #007bff;
    color: white;
}

.tabs button:hover {
    background-color: #0056b3;
    color: white;
}



.single-sms-form,
.excel-sms-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.form-group input[type="text"],
.form-group textarea {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    outline: 2px solid transparent;
    transition: 200ms ease;
    color: #08182e;


    &:focus,
    &:valid {
        outline: 2px solid #006AF5;
    }
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.radio-group label {
    display: inline-block;
    margin-right: 20px;
}


.send-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    outline: none;
    display: flex;
    align-items: center;
    gap: 10px;
    /* font-size: 16px; */
}

.send-button:hover {
    background-color: #0056b3;
}

.loading {
    font-size: 1.2em;
    text-align: center;
    margin-top: 20px;
}

.download-sample {
    margin-top: 10px;
}

.download-sample a {
    color: #007bff;
    text-decoration: none;
}

.download-sample a:hover {
    text-decoration: underline;
}

.add-button {
    background-color: #F2F3FA;
    color: #006AF5;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    outline: none;
    margin-top: 10px;
    border: 1px solid #E4E7EC;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        background-color: #dcdde6;
    }

}

.remove-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    margin-top: 10px;
}

.remove-button {
    margin-left: 10px;
}

.sample-file a {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.sample-file a:hover {
    text-decoration: underline;
}

.phone-group {
    display: flex;
    align-items: center;
}

.phone-group input {
    flex: 1;
    margin-right: 10px;
}



.remove-button:hover {
    background-color: #0056b3;
}

.manual-sms-form,
.excel-sms-form,
.database-sms-form {
    margin-top: 20px;
}


.file-upload-container {
    border: 1px solid #E4E7EC;
    padding: 60px;
    border-radius: 5px;
    background: #f9f9f9;
    text-align: center;
    position: relative;
    cursor: pointer;

    margin-top: 12px;
}

.file-upload-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #007bff;
}

.file-upload-message i {
    margin-bottom: 10px;
}

.file-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.file-preview p {
    margin: 0;
}

.file-preview button {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.download-button {
    background-color: #64b447;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    margin-left: 78%;
}

.download-button:hover {
    background-color: #4f9b34;
}

.download-button:active {
    background-color: #4f9b34;
}
</style>