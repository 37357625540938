<template>
  <div class="see-users-container">
    <div class="sidebar">
      <ul>
        <router-link v-if="hasPermission('other', 'create_monitors')" :to="{ name: 'health_facility_team_form' }" :class="{
          'active-user-link': $route.path.includes('/health-facility-team'),
        }">
          <li>Health Facility Team</li>
        </router-link>
        <router-link v-if="hasPermission('other', 'create_monitors')" :to="{ name: 'community_health_worker_form' }" :class="{
          'active-user-link': $route.path.includes('/community-based'),
        }">
          <li>Community  Based team</li>
        </router-link>
        <router-link v-if="hasPermission('other', 'create_monitors')" :to="{ name: 'health_facility_lead_form' }" :class="{
          'active-user-link': $route.path.includes('/health-facility-lead'),
        }">
          <li>Health Facility Lead</li>
        </router-link>
        <router-link v-if="hasPermission('other', 'create_admin')" :to="{ name: 'admins_form' }" :class="{
          'active-user-link': $route.path.includes('/admins'),
        }">
          <li>Admins</li>
        </router-link>
      </ul>
    </div>
    <div class="see-users-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  }
};
</script>

<style lang="scss" scoped>
.see-users-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;

  padding: 20px 0;

  .sidebar {
    min-height: 25px;
    width: 25%;
    background-color: #F9FAFB;
    position: sticky;
    top: 0px;


    ul {
      list-style-type: none;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      a {
        text-decoration: none;
        color: var(--dark);

        li {
          padding: 10px;
          background-color: transparent;
          border-radius: 7px;
          
        }

        
      }

      .active-user-link {
        li {
          color: #0A2472;
          font-weight: bold;
          border: 1px solid #E4E7EC;
          border-radius: 8px;
          background-color: white;
        }
      }
    }
  }

  .see-users-body {
    width: 75%;
  }
}
</style>
