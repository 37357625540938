<template>
    <div class="bulk-sms-container">
        <div class="header">
            <p>Send personalized messages to multiple recipients with ease. Choose to send SMS manually to individual
                recipients or upload an Excel file for bulk messaging.</p>
        </div>

        <div class="page-body">
            <div class="side-tabs">
                <button :class="{ 'active': selectedTab === 'pending' }"
                    @click="selectedTab = 'pending'">Pending</button>
                <button :class="{ 'active': selectedTab === 'approved' }" @click="selectedTab = 'approved'">Approved
                    SMS</button>
            </div>

            <div class="side-content" v-if="selectedTab === 'pending'">
                <h1>Pending</h1><br>
                <div v-if="loading" class="loading">Loading...</div>
                <table v-else-if="pendingMessages.length" class="messages-table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Sender Id</th>
                            <th>Content</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="message in pendingMessages" :key="message.reference_id">
                            <td>{{ message.created_by.name }}</td>
                            <td>{{ message.sender }}</td>
                            <td>
                                <button @click="viewSmsContent(message.sms)">View SMS</button>
                            </td>

                            <td>{{ message.status }}</td>
                            <td>
                                <button @click="viewRecipients(message.reference_id)">View Recipients</button>
                                <button @click="openApproveModal(message.reference_id)"
                                    class="approve-btn">Approve</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-else>No pending messages found.</p>
            </div>

            <div class="side-content" v-if="selectedTab === 'approved'">
                <h1>Approved SMS</h1><br>
                <div v-if="loading" class="loading">Loading...</div>
                <table v-else-if="approvedMessages.length" class="messages-table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Sender Id</th>
                            <th>Reference ID</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="message in approvedMessages" :key="message.reference_id">
                            <td>{{ message.created_by.name }}</td>
                            <td>{{ message.sender }}</td>
                            <td>
                                <button @click="viewSmsContent(message.sms)">View SMS</button>
                            </td>
                            <td>{{ message.status }}</td>
                            <td>
                                <button @click="viewRecipients(message.reference_id)">View Recipients</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-else>No approved messages found.</p>
            </div>
        </div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <center>
                    <h2>Recipients</h2>
                </center>
                <table class="recipients-table">
                    <thead>
                        <tr>
                            <th>Phone Number</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="recipient in recipients" :key="recipient.phone_number">
                            <td>{{ recipient.phone_number }}</td>
                            <td>{{ recipient.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="showApprovalModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeApprovalModal">&times;</span>
                <center>
                    <h2>Approve SMS</h2><br>
                </center>
                <p>Are you sure you want to approve this request?</p><br>
                <button @click="approveSms" class="approve-button">Yes</button>
                <button @click="closeApprovalModal" class="cancel-button">No</button>
            </div>
        </div>
        <div v-if="showSmsModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeSmsModal">&times;</span>
                <center>
                    <h2>SMS Content</h2><br>
                </center>
                <p>{{ currentSmsContent }}</p>
                <button @click="closeSmsModal" class="cancel-button">Close</button>
            </div>
        </div>

    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "@/helpers/axios.js";

const Toast = useToast();

export default {
    data() {
        return {
            selectedTab: 'pending',
            pendingMessages: [],
            approvedMessages: [],
            showModal: false,
            showApprovalModal: false,
            showSmsModal: false,
            currentReferenceId: null,
            recipients: [],
            loading: false,
            currentSmsContent: '',
        };
    },
    methods: {
        async fetchMessages(approved) {
            this.loading = true;
            try {
                const response = await axios.get('/bulksms/fetchMessages', {
                    params: { approved }
                });
                if (approved) {
                    this.approvedMessages = response.data.data;
                } else {
                    this.pendingMessages = response.data.data;
                }
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to fetch messages.');
            } finally {
                this.loading = false;
            }
        },
        async viewRecipients(referenceId) {
            // this.loading = true;
            try {
                const response = await axios.get(`/bulksms/fetchRecipients`, {
                    params: { reference_id: referenceId }
                });
                this.recipients = response.data.data;
                this.currentReferenceId = referenceId;
                this.showModal = true;
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to fetch recipients.');
            } finally {
                this.loading = false;
            }
        },
        openApproveModal(referenceId) {
            this.currentReferenceId = referenceId;
            this.showApprovalModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.recipients = [];
            this.currentReferenceId = null;
        },
        closeApprovalModal() {
            this.showApprovalModal = false;
            this.currentReferenceId = null;
        },
        viewSmsContent(sms) {
            this.currentSmsContent = sms;
            this.showSmsModal = true;
        },
        closeSmsModal() {
            this.showSmsModal = false;
            this.currentSmsContent = '';
        },
        async approveSms() {
            // this.loading = true;
            try {
                const response = await axios.post('/bulksms/approve', {
                    approved_by: 1,
                    reference_id: this.currentReferenceId
                });
                Toast.success(response.data.message || 'SMS approved successfully.');
                this.fetchMessages(0);
                this.fetchMessages(1);
                this.closeApprovalModal();
            } catch (error) {
                Toast.error(error.response.data.message || 'Failed to approve SMS.');
            } finally {
                this.loading = false;
            }
        },
        truncate(text, length) {
            return text.length > length ? text.substring(0, length) + ' ...' : text;
        }
    },
    mounted() {
        this.fetchMessages(0);
        this.fetchMessages(1);
    },
    watch: {
        selectedTab(newTab) {
            if (newTab === 'pending') {
                this.fetchMessages(0);
            } else if (newTab === 'approved') {
                this.fetchMessages(1);
            }
        }
    }
};
</script>


<style scoped>
.bulk-sms-container {
    width: 100%;
    min-height: 100%;
    padding: 0 3%;

    .header {
        width: 760px;
    }

    .page-body {
        padding-top: 36px;
        display: flex;
        align-items: flex-start;
        gap: 20px;

        .side-tabs {
            width: 240px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            background-color: rgba(33, 72, 192, 0.1);
            padding: 8px;
            border-radius: 8px;

            button {
                padding: 12px 16px;
                background-color: transparent;
                border: none;
                border-radius: 8px;
                color: #0A2472;
                font-size: 16px;
                cursor: pointer;
            }

            .active {
                border: 1px solid #E4E7EC;
                background-color: white;
                font-weight: bold
            }
        }

        .side-content {
            flex-grow: 1;
            border-radius: 8px;
            border: 1px solid #E4E7EC;
            padding: 16px;

            h1 {
                color: #003E8F;
                font-size: 20px;
                font-weight: bold;
            }

            .messages-table {
                width: 100%;
                border-collapse: collapse;

                th,
                td {
                    border: 1px solid #E4E7EC;
                    padding: 8px;
                    text-align: left;
                }

                th {
                    background-color: #f9f9f9;
                }

                td button {
                    background-color: #007bff;
                    color: white;
                    border: none;
                    padding: 6px 12px;
                    cursor: pointer;
                    border-radius: 4px;
                }

                td button:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 35%;
    border-radius: 8px;
    text-align: center;
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    white-space: pre-wrap; 
    max-height: 70vh; 
    overflow-y: auto;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.recipients-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #E4E7EC;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f9f9f9;
    }
}

.approve-btn {
    background-color: #3dc06b !important;
    margin-left: 2%;
}

.approve-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
}

.approve-button {
    background-color: #28a745;
    color: white;
}

.approve-button:hover {
    background-color: #218838;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

.cancel-button:hover {
    background-color: #c82333;
}
</style>
