<template>
  <div class="form-container">
    <!-- <div class="tabs">
      <button :class="{ 'active': selectedTab === 'introQuestions' }" @click="selectedTab = 'introQuestions'">
        <i class="fas fa-question-circle"></i>  Questions
      </button>
    </div> -->

    <div v-if="selectedTab === 'introQuestions'" class="form-section">
      <p>Questions</p>
      <form @submit.prevent="handleSubmit('introQuestions')" autocomplete="off">
        <div class="row">
          <div class="col">
            <div class="input-container">
              
              <div class="file-upload-container" @click="openFileDialog('questionnaire')">
                <div class="file-upload-message">
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
                    <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6" />
                    <g clip-path="url(#clip0_187_7398)">
                      <path
                        d="M26.3335 26.3334L23.0002 23M23.0002 23L19.6669 26.3334M23.0002 23V30.5M29.9919 28.325C30.8047 27.8819 31.4467 27.1808 31.8168 26.3322C32.1868 25.4837 32.2637 24.5361 32.0354 23.6389C31.807 22.7418 31.2865 21.9463 30.5558 21.3779C29.8251 20.8095 28.9259 20.5006 28.0002 20.5H26.9502C26.698 19.5244 26.2278 18.6186 25.5752 17.8509C24.9225 17.0831 24.1042 16.4732 23.182 16.0672C22.2597 15.6612 21.2573 15.4695 20.2503 15.5066C19.2433 15.5437 18.2578 15.8086 17.3679 16.2814C16.4779 16.7542 15.7068 17.4226 15.1124 18.2363C14.518 19.0501 14.1158 19.988 13.936 20.9795C13.7563 21.9711 13.8036 22.9905 14.0746 23.9611C14.3455 24.9317 14.8329 25.8282 15.5002 26.5834"
                        stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_187_7398">
                        <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                      </clipPath>
                    </defs>
                  </svg>

                  <p class="text">Drag & drop files here, or click to select files</p>
                </div>
                <input type="file" accept=".xls,.xlsx,.csv" @change="onFileChange('questionnaire', $event)"
                  ref="questionnaireFileInput" style="display: none;" />
              </div>
              <div v-if="questionnaireFile" class="file-preview">
                <p class="text">{{ questionnaireFile.name }}</p>
                <button @click.stop="removeFile('questionnaire')">Remove</button>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"><br>
            <button type="submit" :disabled="loading">
              <span v-if="loading">
                <spinner-vue />
              </span>
              <span v-else>Upload</span>
            </button>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import SpinnerVue from "@/components/spinner.vue";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  components: {
    SpinnerVue
  },
  data() {
    return {
      selectedTab: 'introQuestions',
      condition: '',
      kvp: '',
      introQuestionsFile: null,
      questionnaireFile: null,
      loading: false
    };
  },
  methods: {
    onFileChange(type, event) {
      const file = event.target.files[0];
      if (file && this.isValidFileType(file)) {
        if (type === 'introQuestions') {
          this.introQuestionsFile = file;
        } else if (type === 'questionnaire') {
          this.questionnaireFile = file;
        }
      } else {
        Toast.error('Invalid file type. Please select a .xls, .xlsx, or .csv file.');
        this.removeFile(type);
      }
    },
    isValidFileType(file) {
      const validExtensions = ['.xls', '.xlsx', '.csv'];
      const fileExtension = file.name.split('.').pop();
      return validExtensions.includes(`.${fileExtension}`);
    },
    openFileDialog(type) {
      if (type === 'introQuestions') {
        this.$refs.introQuestionsFileInput.click();
      } else if (type === 'questionnaire') {
        this.$refs.questionnaireFileInput.click();
      }
    },
    removeFile(type) {
      if (type === 'introQuestions') {
        this.introQuestionsFile = null;
        this.$refs.introQuestionsFileInput.value = null;
      } else if (type === 'questionnaire') {
        this.questionnaireFile = null;
        this.$refs.questionnaireFileInput.value = null;
      }
    },
    getSessionIdFromUrl() {
      const url = window.location.href;
      const segments = url.split('/');
      return segments[segments.length - 1];
    },
    handleSubmit(type) {
      const sessionId = this.getSessionIdFromUrl();
      if (type === 'introQuestions' && this.questionnaireFile) {
        const url = 'iclm-questions/import';
        this.uploadFile(this.introQuestionsFile, url, sessionId);
      } else if (type === 'questionnaire' && this.questionnaireFile) {
        if (!this.condition) {
          Toast.error('Please select a condition.');
          return;
        }
        const url = 'iclm-questions/import';
        // this.uploadFile(this.questionnaireFile, url, sessionId, this.condition);
        this.uploadFile(this.questionnaireFile, url, sessionId, this.condition, this.kvp);
      } else {
        Toast.error('Please select a file to upload.');
      }
    },
    uploadFile(file, url, sessionId, conditionId = null, subConditionId = null) {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('session_id', sessionId);
      formData.append('tool_id', 4);
      if (conditionId) {
        formData.append('condition_id', conditionId);
      }
      if (subConditionId) {
        formData.append('sub_condition_id', subConditionId);
      }
      axios.post(url, formData)
        .then(response => {
          this.handleFileUpload(response);
        })
        .catch(error => {
          this.handleFileError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFileUpload(response) {
      if (response.data.status) {
        Toast.success(response.data.message);
        this.clearForm();
      } else {
        Toast.error(response.data.message);
      }
    },
    handleFileError(error) {
      if (error.response && error.response.data && error.response.data.message) {
        Toast.error(`File upload error: ${error.response.data.message}`);
      } else {
        // Toast.error('An error occurred during file upload.');
      }
    },
    clearForm() {
      this.introQuestionsFile = null;
      this.questionnaireFile = null;
      this.$refs.introQuestionsFileInput.value = null;
      this.$refs.questionnaireFileInput.value = null;
      this.condition = '';
      this.kvp = '';
    }
  }
};
</script>


<style scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    button {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      background-color: var(--gray-200);
      transition: background-color 200ms ease;

      &.active {
        background-color: var(--blue-500);
        color: var(--white);
      }

      i {
        margin-right: 5px;
      }

      &:hover {
        background-color: var(--blue-300);
      }
    }
  }

  p {
    font-size: 1.5em;
    font-weight: bold;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row {
      display: flex;
      gap: 20px;

      .col {
        width: 100%;

        button {
          background-color: var(--blue-500);
          color: var(--white);
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 5px;
          transition: 200ms ease;

          &:hover {
            background-color: var(--blue-600);
            cursor: pointer;
          }
        }

        .input-container {
          position: relative;
          display: flex;
          flex-direction: column;

          input[type='file'],
          select {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);
            background-color: white;

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);

              +label {
                left: 9px;
                scale: 0.9;
                transform: translateY(-20px);
                background-color: white;
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 12px;
            left: 10px;
            transition: 200ms ease;
          }
        }
      }
    }
  }
}

.file-upload-container {
  border: 1px solid #E4E7EC;
  padding: 60px;
  border-radius: 5px;
  background: transparent;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-top: 12px;
}

.file-upload-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #007bff;
}

.file-upload-message i {
  margin-bottom: 10px;
}

.file-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.file-preview p {
  margin: 0;
}

.file-preview button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.text {
  font-size: 1em !important;
}

.spinner {
  margin-left: 40% !important;
}
</style>
