<template>
  <div class="card-container" v-if="users.length">
    <div class="card-header">
      <div class="left">
        <h3>Educators</h3>
      </div>
      <div class="right">
        <ul>
          <li class="export excel" @click="exportToExcel">
            <i class="fas fa-file-excel"></i>
          </li>
          <li class="export pdf" @click="exportToPdf">
            <i class="fas fa-file-pdf"></i>
          </li>
          <li>
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                v-model="searchQuery"
                @input="filterData"
                placeholder="Search for user"
              />
              <div class="icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <table>
      <thead>
        <th>No</th>
        <th>Names</th>
        <th>Phone</th>
        <th>Otp</th>
        <th>Gender</th>
        <th>Dob</th>
        <th>Location</th>
      </thead>
      <tbody>
        <tr v-for="(user, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>+{{ user.phone }}</td>
          <td>{{ user.otp ? user.otp : "N/A" }}</td>
          <td>{{ user.gender }}</td>
          <td>{{ user.dob }}</td>
          <td>{{}}</td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
  </div>
  <div class="spinner-container" v-else>
    <loading_spinner />
  </div>
</template>

<script>
import loading_spinner from "@/components/loading_spinner.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");
import axios from "axios";

export default {
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 9,
      users: [],
    };
  },
  mounted() {
    this.fetch_community_members();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter(
          (item) => regex.test(item.first_name) || regex.test(item.last_name)
        );
      }
    },
    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Community Members.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text("Community Members", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("community members.pdf");
    },
    async fetch_community_members() {
      try {
        const response = await axios.get(
          "http://iclm.ubumenyi.rw/api/admin/all/users/educator"
        );

        this.users = response.data.data;

        console.log(this.users);
      } catch (error) {
        setTimeout(() => {
          this.fetch_community_members();
        }, 5000);
      }
    },

  },
  components: {
    loading_spinner,
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container {
  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid var(--blue-500);

    thead {
      background-color: var(--blue-500);
      color: var(--white);
      position: sticky;
      top: 50px;

      th {
        padding: 10px;
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
        }

        &:nth-child(even) {
          background-color: rgb(218, 218, 218);
        }
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
