<template>
  <div class="form-container">
    <p>Please fill the input with Session Name</p>
    <form @submit.prevent autocomplete="off">
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input type="text" spellcheck="false" id="firstname" required />
            <label for="firstname">Session Name</label>
          </div>
        </div>
      </div>
 
      <div class="row">
        <div class="col">
          <button>Create</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  gap: 20px;
  

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .row {
      display: flex;
      gap: 20px;

      .col {
        width: 50%;

        button {
          background-color: var(--blue-500);
          color: var(--white);
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 5px;
          transition: 200ms ease;

          &:hover {
            background-color: var(--blue-600);
            cursor: pointer;
          }
        }

        .input-container {
          position: relative;

          input,
          select {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);
            background-color: white;

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);
              + label {
                left: 9px;
                scale: 0.9;
                transform: translateY(-20px);
                background-color: white;
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 12px;
            left: 10px;
            transition: 200ms ease;
          }
        }
        .dob {
          position: relative;

          input {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);
              + label {
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 12px;
            background-color: white;
            left: 9px;
            scale: 0.9;
            transform: translateY(-20px);
          }
        }
      }
    }
  }
}
</style>
