<template>
  <div class="card-container">
    <table>
      <thead>
        <tr>
          <th>No</th>
          <th>Names</th>
          <th>Phone</th>
          <th>Role</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ getUserTypeLabel(user.user_type) }}</td>
          <td>{{ formatDateTime(user.created_at) }}</td>
          <td><button class="deactivate-btn" @click="showDeactivationModal(user.id)">Deactivate</button></td>
        </tr>
      </tbody>
    </table>

    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <h5 class="modal-title">Confirm Deactivation</h5>
        <button type="button" class="close" @click="closeModal">&times;</button>
      </div>
      <div class="modal-body">
        Are you sure you want to deactivate this user?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
        <button type="button" class="btn btn-primary" @click="confirmDeactivation">Yes, Deactivate</button>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "@/helpers/axios.js";
import loading_spinner from "@/components/loading_spinner.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");

export default {
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 9,
      users: [],
      displayedData: [],
      userIdToDeactivate: null,
      showModal: false,
    };
  },
  mounted() {
    this.fetch_users();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter((item) => regex.test(item.name));
      }
    },
    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Deactivated Accounts.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text(
        "Deactivated Accounts",
        doc.internal.pageSize.getWidth() / 2,
        10,
        {
          align: "center",
        }
      );

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("Deactivated Accounts.pdf");
    },
    async fetch_users() {
      try {
        const response = await axios.get("admin/all/users");

        this.users = response.data.data;
      } catch (error) {
        setTimeout(() => {
          this.fetch_users();
        }, 5000);
      }
    },
    showDeactivationModal(userId) {
      this.userIdToDeactivate = userId;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.userIdToDeactivate = null;
    },
    confirmDeactivation() {
      if (this.userIdToDeactivate !== null) {
        axios.delete(`/users/deactivate/${this.userIdToDeactivate}`)
          .then(response => {
            if (response.data.status) {
              this.displayedData = this.displayedData.filter(user => user.id !== this.userIdToDeactivate);
              this.fetch_users();
            } else {
              alert('Failed to deactivate user. Please try again.');
            }
            this.closeModal();
          })
          .catch(error => {
            console.error('An error occurred while deactivating the user:', error);
            alert('An error occurred. Please try again.');
            this.closeModal();
          });
      }
    },
    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      return date.toLocaleString();
    },
    getUserTypeLabel(userType) {
      switch (userType) {
        case 'community_based_team':
          return 'Community Based Team';
        case 'health_facility_team':
          return 'Health Facility Team';
        case 'health_facility_admin':
          return 'Health Facility Lead';
        default:
          return 'N/A';
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.card-container {
  padding: 20px 0;

  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid #E4E7EC;


thead {
  background-color: #F9FAFB;
  color: #344054;
  border-bottom: 1px solid #E4E7EC;

  th {
    padding: 15px 16px;
  }
}


    tbody {
      tr {
        td {
          padding: 10px;
          border-bottom: 1px solid #E4E7EC;

          .activate {
            padding: 7px;
            border: none;
            background-color: rgb(5, 172, 102);
            color: var(--white);
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: rgb(0, 117, 68);
            }
          }

          .deactivate-btn {
            width: 95px; 
            height: 32px;
            padding: 4px 12px;
            border: none;
            border-radius: 8px;
            background-color: #DA342E;
            color: var(--white);
            transition: 200ms ease;
            cursor: pointer;

            &:hover {
              background-color: #8C0009;
            }
          }
        }

        
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-header .close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  margin-bottom: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-secondary {
  background: #ccc;
}

.btn-primary {
  background: #007bff;
  color: white;
}
</style>
