<template>
  <div class="community_member-container">
    <div class="sidebar">
      <ul>
        <router-link
          :to="{ name: 'standards_of_living_educator' }"
          :class="{
            'active-user-link': $route.path.includes('/educator'),
          }"
        >
          <li>Educator</li>
        </router-link>
        <router-link
          :to="{ name: 'standards_of_living_health_worker' }"
          :class="{
            'active-user-link': $route.path.includes('/worker'),
          }"
        >
          <li>Community Health Worker</li>
        </router-link>
      </ul>
    </div>
    <div class="community_member-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.community_member-container {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-items: flex-start;

  padding: 20px 0;

  .sidebar {
    min-height: 20px;
    width: 25%;
    background-color: var(--white);
    position: sticky;
    top: 0px;

    ul {
      list-style-type: none;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      a {
        text-decoration: none;
        color: var(--dark);

        li {
          padding: 10px;
          background-color: transparent;
          border-radius: 7px;
          transition: 300ms ease;
        }

        &:hover {
          li {
            background-color: rgb(211, 211, 211);
          }
        }
      }

      .active-user-link {
        li {
          background-color: rgb(211, 211, 211);
        }
      }
    }
  }

  .community_member-body {
    width: 75%;
  }
}
</style>
