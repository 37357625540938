<template>
  <div class="users-container">
    <div class="users-header">
      <ul>
        <!-- <router-link
          :to="{ name: 'diagnosis_community_member' }"
          :class="{
            'active-user-link': $route.path.includes('/reports/diagnosis'),
          }"
        >
          <li><strong>Diagnosis</strong></li>
        </router-link> -->
        <!-- <router-link
          :to="{ name: 'standards_of_living_educator' }"
          :class="{
            'active-user-link': $route.path.includes('reports/standards-of-living'),
          }"
        >
          <li><strong>Standards of living</strong></li>
        </router-link> -->
        <router-link :to="{ name: 'patient_tool' }" :class="{
          'active-user-link': $route.path.includes('reports/health-facility-team'),
        }">
          <li><strong>Health Facilty Team</strong></li>
        </router-link>
        <router-link :to="{ name: 'community_based_tool' }" :class="{
          'active-user-link': $route.path.includes('reports/community-based-team'),
        }">
          <li><strong>Community Based Team</strong></li>
        </router-link>
        <router-link :to="{ name: 'health_facility_tool' }" :class="{
          'active-user-link': $route.path.includes('reports/health-facility-lead'),
        }">
          <li><strong>Health Facility Lead</strong></li>
        </router-link>
      </ul>
    </div>

    <div class="users-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var searchInput = document.getElementById('searchInput');
    var tr = document.getElementsByTagName('tr');

    searchInput.addEventListener('keyup', () => {
      var myValue = searchInput.value.toUpperCase();

      for (var i = 0; i < tr.length; i++) {
        var td = tr[i].getElementsByTagName('td');

        for (var index = 0; index < td.length; index++) {
          if (td[index].innerHTML.toUpperCase().indexOf(myValue) > -1) {
            tr[i].style.display = '';
            break;
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.users-container {
  height: 100%;
  padding: 0px 3%;
  display: flex;
  flex-direction: column;

  .users-header {

    ul {
      display: flex;
      list-style-type: none;
      gap: 20px;

      a {
        text-decoration: none;
        border-bottom: 3px solid transparent;
        color: rgb(182, 182, 182);
        transition: 300ms ease;

        &:hover {
          border-bottom: 3px solid;
        }

        li {
          padding: 10px;
        }
      }

      .active-user-link {
        border-bottom: 3px solid;
        color: var(--blue-500);
      }
    }
  }
}
</style>
