<template>
  <div class="form-container">
    <p>Please fill the input with Session Name</p>
    <form @submit.prevent="createSession" autocomplete="off">
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input v-model="sessionName" type="text" spellcheck="false" id="sessionName" required />
            <label for="sessionName">Session Name</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button :disabled="loading">
            <span v-if="loading">
              <SpinnerVue />
            </span>
            <span v-else>Create</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import axios from "@/helpers/axios.js";
import SpinnerVue from "@/components/spinner.vue";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  components: {
    SpinnerVue
  },
  data() {
    return {
      sessionName: '',
      loading: false
    };
  },
  methods: {
    async createSession() {
      if (!this.sessionName) {
        Toast.error("Session Name is required");
        return;
      }

      this.loading = true;
      try {
        const response = await axios.post('/admin/sessions', {
          name: this.sessionName,
          tool_id: 4
        });

        console.log('Response:', response);

        if (response.data.status) {
          Toast.success(response.data.message);

          const sessionId = response.data.data.id;
          console.log('Session ID:', sessionId);

          this.$router.push({ name: 'observation_uploads_session_files', params: { id: sessionId } });
        } else {
          Toast.error(response.data.message);
        }
      } catch (error) {
        Toast.error("Failed to create session");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row {
      display: flex;
      gap: 20px;

      .col {
        width: 50%;

        button {
          background-color: var(--blue-500);
          color: var(--white);
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 5px;
          transition: 200ms ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: var(--blue-600);
            cursor: pointer;
          }

          &:disabled {
            background-color: var(--blue-300);
            cursor: not-allowed;
          }
        }

        .input-container {
          position: relative;

          input,
          select {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);
            background-color: white;

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);

              +label {
                left: 9px;
                scale: 0.9;
                transform: translateY(-20px);
                background-color: white;
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 12px;
            left: 10px;
            transition: 200ms ease;
          }
        }
      }
    }
  }
}
</style>
