<template>
  <div class="all-session-container">
    <div class="all-session-body">
      <div v-for="session in sessions" :key="session.id" class="session-container"
        :class="{ activeSession: session.active === '0' }" @click="viewSession(session.id)">

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5"
            d="M2.5 12C2.5 7.28595 2.5 4.92893 3.96447 3.46447C5.42893 2 7.78595 2 12.5 2C17.214 2 19.5711 2 21.0355 3.46447C22.5 4.92893 22.5 7.28595 22.5 12C22.5 16.714 22.5 19.0711 21.0355 20.5355C19.5711 22 17.214 22 12.5 22C7.78595 22 5.42893 22 3.96447 20.5355C2.5 19.0711 2.5 16.714 2.5 12Z"
            stroke="#1C274C" stroke-width="1.5" />
          <path
            d="M10.625 8.875C10.625 7.83947 11.4645 7 12.5 7C13.5355 7 14.375 7.83947 14.375 8.875C14.375 9.56245 14.005 10.1635 13.4534 10.4899C12.978 10.7711 12.5 11.1977 12.5 11.75V13"
            stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
          <circle cx="12.5" cy="16" r="1" fill="#1C274C" />
        </svg>

        <p>{{ session.name }}</p>
        <!-- <span v-if="session.active == '0'" style="margin-left: 45%; color: #5cc99b; font-weight: 400;"> Active</span> -->
      </div>
      <div v-if="error" class="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
export default {
  data() {
    return {
      sessions: [],
      error: null,
    };
  },
  mounted() {
    this.fetchSessions();
  },
  methods: {
    viewSession(index) {
      this.$router.push({
        name: "community_based_tool_uploads_session_files",
        params: { id: index },
      });
    },
    fetchSessions() {
      axios
        .get('/admin/sessions/tool/3')
        .then(response => {
          if (response.data.status) {
            this.sessions = response.data.data;
          } else {
            this.error = response.data.message;
          }
        })
        .catch(error => {
          this.error = 'Failed to load sessions. Please try again later.';
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.all-session-container {
  width: 100%;
  min-height: 20vh;
  background-color: var(--white);
  padding: 24px;
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  margin-top: 16px;


  .all-session-header {
    .search-input-container {
      width: 100%;
      position: relative;
      border: 2px solid var(--blue-500);
      border-radius: 50px;
      overflow: hidden;

      input {
        width: 100%;
        padding: 12px;
        border: none;
        outline: none;
        color: var(--blue-500);
      }

      .icon {
        position: absolute;
        top: 9px;
        right: 20px;
      }
    }
  }

  .all-session-body {
    width: 100%;
    // padding-top: 20px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;




    .session-container {

      width: 254px;
      min-height: 65px;
      background-color: var(--white);

      font-size: 20px;
      padding: 5px 10px;
      transition: 300ms ease;
      border: 1px solid #E4E7EC;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-shadow: 0px 8px 7px -5px rgba(16, 24, 40, 0.1);
      

      &:hover {
        background-color: rgba(0, 106, 245, 0.05);
        border: 1px solid rgba(33, 72, 192, 0.5);

        p {
          font-size: 16px;
          font-weight: bold;
        }

        svg {
          path {
            fill: #1C274C;
            fill-opacity: 1;
            opacity: 1;
            stroke: white;
          }

          circle {
            fill: white;
          }
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      svg {
        path {
          transition: all 200ms ease;
          // fill: red;
          // stroke:  white;
        }

        circle {
          transition: all 200ms ease;
          // fill: white;
        }
      }



      &:hover {

        cursor: pointer;
      }
    }

    .activeSession {



      p {
        font-size: 16px;
        font-weight: bold;
      }

      svg {
        path {
          fill: #1C274C;
          fill-opacity: 1;
          opacity: 1;
          stroke: white;
        }

        circle {
          fill: white;
        }
      }

    }
  }
}
</style>
