<template>
  <div class="card-container">
    <div class="card-header">
      <div class="left">
        <div class="back-btn">
          <button @click="this.$router.go(-1)">
            <div class="icon"><i class="fa fa-arrow-left"></i> back</div>
          </button>
        </div>
        <div class="conditions">
          <button :class="currentCondition == 'hiv' ? 'show' : ''" @click="changeCondition('hiv')">
            HIV/AIDS
          </button>
          <button :class="currentCondition == 'tuberculosis' ? 'show' : ''" @click="changeCondition('tuberculosis')">
            Tuberculosis
          </button>
          <button :class="currentCondition == 'malaria' ? 'show' : ''" @click="changeCondition('malaria')">
            Malaria
          </button>
        </div>
      </div>
      <div class="right">
        <ul>
          <li>
            <div class="input-container">
              <input type="text" spellcheck="false" v-model="searchQuery" @input="filterData"
                placeholder="Search for questions" />
              <div class="icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="tb-container">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Questions</th>
            <th>Response</th>
            <th v-if="hasPermission('other', 'edit_tool')">Action</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(question, index) in displayedData" :key="question.id">
            <td>{{ calculateIndex(index) }}</td>
            <td>{{ question.question }}</td>
            <td>
              <table>
                <tbody>
                  <tr v-for="response in question.response_options" :key="response.id">
                    <td class="right-td">{{ response.response }}</td>
                    <td class="left-td" v-if="hasPermission('other', 'edit_tool')">
                      <button class="edit-button skip-button" @click="openSkipQuestionModal(response.id)">
                        Skip
                      </button>  
                      <button class="edit-button" @click="openEditModal('response', response)">
                        <svg width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.0367 2.375C10.8804 2.375 10.7179 2.4375 10.5992 2.55625L9.4554 3.7L11.7992 6.04375L12.9429 4.9C13.1867 4.65625 13.1867 4.2625 12.9429 4.01875L11.4804 2.55625C11.3554 2.43125 11.1992 2.375 11.0367 2.375ZM8.78652 6.13751L9.36152 6.71251L3.69902 12.375H3.12402V11.8L8.78652 6.13751ZM1.87402 11.2812L8.78652 4.36871L11.1303 6.71246L4.21777 13.625H1.87402V11.2812Z"
                            fill="#1C3454" fill-opacity="0.26" />
                        </svg>
                      </button>

                      <button class="delete-button" @click="confirmDelete('response', response.id)">
                        <svg width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1.71875 4.35417C1.71875 4.06653 1.93461 3.83334 2.20089 3.83334L3.86604 3.83306C4.19689 3.82401 4.48876 3.59676 4.60134 3.26057C4.6043 3.25173 4.6077 3.24083 4.61991 3.20127L4.69166 2.96871C4.73556 2.82612 4.77381 2.70189 4.82734 2.59085C5.03881 2.15218 5.43005 1.84756 5.88217 1.76957C5.99661 1.74983 6.1178 1.74992 6.25691 1.75001H8.4307C8.56981 1.74992 8.691 1.74983 8.80544 1.76957C9.25756 1.84756 9.6488 2.15218 9.86027 2.59085C9.91379 2.70189 9.95205 2.82612 9.99595 2.96871L10.0677 3.20127C10.0799 3.24083 10.0833 3.25173 10.0863 3.26057C10.1988 3.59676 10.5486 3.82429 10.8795 3.83334H12.4866C12.7529 3.83334 12.9688 4.06653 12.9688 4.35417C12.9688 4.64182 12.7529 4.875 12.4866 4.875H2.20089C1.93461 4.875 1.71875 4.64182 1.71875 4.35417Z"
                            fill="#1C3454" fill-opacity="0.26" />
                          <path
                            d="M7.25423 14.2499H7.74606C9.43825 14.2499 10.2843 14.2499 10.8345 13.7104C11.3846 13.1709 11.4409 12.2859 11.5534 10.516L11.7156 7.96565C11.7767 7.0053 11.8072 6.52513 11.5312 6.22085C11.2553 5.91656 10.7892 5.91656 9.85713 5.91656H5.14316C4.21107 5.91656 3.74502 5.91656 3.46905 6.22085C3.19307 6.52513 3.22361 7.0053 3.28468 7.96565L3.44688 10.516C3.55944 12.2859 3.61572 13.1709 4.16584 13.7104C4.71595 14.2499 5.56205 14.2499 7.25423 14.2499Z"
                            fill="#1C3454" fill-opacity="0.26" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td v-if="hasPermission('other', 'edit_tool')"><button class="edit-button-action" @click="openEditModal('question', question)">
                <svg width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.0367 2.375C10.8804 2.375 10.7179 2.4375 10.5992 2.55625L9.4554 3.7L11.7992 6.04375L12.9429 4.9C13.1867 4.65625 13.1867 4.2625 12.9429 4.01875L11.4804 2.55625C11.3554 2.43125 11.1992 2.375 11.0367 2.375ZM8.78652 6.13751L9.36152 6.71251L3.69902 12.375H3.12402V11.8L8.78652 6.13751ZM1.87402 11.2812L8.78652 4.36871L11.1303 6.71246L4.21777 13.625H1.87402V11.2812Z"
                    fill="#1C3454" fill-opacity="0.26" />
                </svg>
              </button>
              <button class="delete-button-action" @click="confirmDelete('question', question.id)">
                <svg width="18" height="18" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.71875 4.35417C1.71875 4.06653 1.93461 3.83334 2.20089 3.83334L3.86604 3.83306C4.19689 3.82401 4.48876 3.59676 4.60134 3.26057C4.6043 3.25173 4.6077 3.24083 4.61991 3.20127L4.69166 2.96871C4.73556 2.82612 4.77381 2.70189 4.82734 2.59085C5.03881 2.15218 5.43005 1.84756 5.88217 1.76957C5.99661 1.74983 6.1178 1.74992 6.25691 1.75001H8.4307C8.56981 1.74992 8.691 1.74983 8.80544 1.76957C9.25756 1.84756 9.6488 2.15218 9.86027 2.59085C9.91379 2.70189 9.95205 2.82612 9.99595 2.96871L10.0677 3.20127C10.0799 3.24083 10.0833 3.25173 10.0863 3.26057C10.1988 3.59676 10.5486 3.82429 10.8795 3.83334H12.4866C12.7529 3.83334 12.9688 4.06653 12.9688 4.35417C12.9688 4.64182 12.7529 4.875 12.4866 4.875H2.20089C1.93461 4.875 1.71875 4.64182 1.71875 4.35417Z"
                    fill="#1C3454" fill-opacity="0.26" />
                  <path
                    d="M7.25423 14.2499H7.74606C9.43825 14.2499 10.2843 14.2499 10.8345 13.7104C11.3846 13.1709 11.4409 12.2859 11.5534 10.516L11.7156 7.96565C11.7767 7.0053 11.8072 6.52513 11.5312 6.22085C11.2553 5.91656 10.7892 5.91656 9.85713 5.91656H5.14316C4.21107 5.91656 3.74502 5.91656 3.46905 6.22085C3.19307 6.52513 3.22361 7.0053 3.28468 7.96565L3.44688 10.516C3.55944 12.2859 3.61572 13.1709 4.16584 13.7104C4.71595 14.2499 5.56205 14.2499 7.25423 14.2499Z"
                    fill="#1C3454" fill-opacity="0.26" />
                </svg>
              </button>
            </td>
          </tr>
          <tr v-if="!displayedData.length">
            <td colspan="5">Not Found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="tb-footer">
      <div class="prev">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-caret-left"></i>
        </button>
      </div>
      <div class="center">

        <!-- Generate pagination buttons -->
        <button v-for="page in totalPages" :key="page" @click="gotoPage(page)"
          :class="{ activePagination: currentPage === page }">
          {{ page }}
        </button>

      </div>
      <div class="next">
        <button @click="nextPage" :disabled="currentPage === totalPages">
          <i class="fa fa-caret-right"></i>
        </button>
      </div>
    </div>


    <!-- Edit Modal -->
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h3>Edit {{ modalType === 'question' ? 'Question' : 'Response' }}</h3>
        <div v-if="modalType === 'question'">
          <label for="editQuestion">Question:</label>
          <textarea v-model="editData.question" id="editQuestion" rows="5"></textarea>

          <label for="editQuestionType">Question Type:</label>
          <select v-model="editData.question_type.id" id="editQuestionType" class="form-container">
            <option v-for="type in questionTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
          </select>

          <label for="editQuestionCode">Question Code:</label>
          <select v-model="editData.question_code.id" id="editQuestionCode" class="form-container">
            <option v-for="code in questionCodes" :key="code.id" :value="code.id">{{ code.name }}</option>
          </select>
        </div>
        <div v-else>
          <label for="editResponse">Response:</label>
          <input type="text" v-model="editData.response" id="editResponse" />
        </div><br>
        <div class="modal-actions">
          <button class="save" @click="saveEdit">Save</button>
          <button class="cancel" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="isDeleteModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeDeleteModal">&times;</span>
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete this {{ deleteType === 'question' ? 'question' : 'response' }}?</p>
        <div class="modal-actions">
          <button class="delete" @click="deleteItem">Delete</button>
          <button class="cancel" @click="closeDeleteModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useToast } from "vue-toastification";
import { mapGetters } from 'vuex';

const Toast = useToast();
var XLSX = require("xlsx");

export default {
  data() {
    return {
      currentCondition: "hiv",
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 9,
      questions: [],
      condition: {
        hiv: 1,
        tuberculosis: 3,
        malaria: 2,
      },
      toolId: 2,
      isModalOpen: false,
      isDeleteModalOpen: false,
      modalType: '',
      editData: {},
      itemIdToDelete: null,
      deleteType: '',
      questionTypes: [],
      questionCodes: [],
    };
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(this.currentPage * this.itemsPerPage, this.filteredData.length);
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.questions;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.questions.filter((item) => regex.test(item.question));
      }
    },
    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    gotoPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
    changeCondition(condition) {
      this.currentCondition = condition;
      this.currentPage = 1;
      this.fetchQuestions();
    },
    async fetchQuestions() {
      const sessionId = this.getSessionIdFromUrl();
      const conditionId = this.condition[this.currentCondition];
      console.log(`Fetching questions for conditionId: ${conditionId} and sessionId: ${sessionId}`);
      try {
        const response = await axios.post("/admin/session/questions/all", {
          condition_id: conditionId,
          tool_id: this.toolId,
          session_id: sessionId
        });
        console.log('Response from server:', response);
        if (response.data.status) {
          this.questions = response.data.data;
        } else {
          this.questions = [];
        }
      } catch (error) {
        console.error("There was an error fetching the questions!", error);
      }
    },
    async fetchQuestionTypes() {
      try {
        const response = await axios.get("/admin/question-types");
        if (response.data.status) {
          this.questionTypes = response.data.data;
        } else {
          Toast.error("Failed to fetch question types.");
        }
      } catch (error) {
        console.error("There was an error fetching the question types!", error);
        Toast.error("An error occurred while fetching question types.");
      }
    },
    async fetchQuestionCodes() {
      try {
        const response = await axios.get("/admin/question-codes");
        if (response.data.status) {
          this.questionCodes = response.data.data;
        } else {
          Toast.error("Failed to fetch question codes.");
        }
      } catch (error) {
        console.error("There was an error fetching the question codes!", error);
        Toast.error("An error occurred while fetching question codes.");
      }
    },
    getSessionIdFromUrl() {
      const url = window.location.href;
      const segments = url.split('/');
      return segments[segments.length - 1];
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.questions);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Community Members.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["No", "Questions", "Response"]];
      const data = this.questions.map((item, index) => [
        this.calculateIndex(index), item.question, item.response_options.map(option => option.response).join(', ')
      ]);

      // Add heading
      doc.text("Community Members", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("community members.pdf");
    },
    openEditModal(type, item) {
      this.isModalOpen = true;
      this.modalType = type;
      if (type === 'question') {
        this.editData = { ...item };
      } else {
        this.editData = { ...item };
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.editData = {};
    },
    saveEdit() {
      if (this.modalType === 'question') {
        axios.put(`/admin/iclm-questions/${this.editData.id}`, {
          question: this.editData.question,
          question_type_id: this.editData.question_type.id,
          question_code_id: this.editData.question_code.id
        })
          .then(response => {
            if (response.data.status) {
              this.fetchQuestions();
              Toast.success(response.data.message);
            } else {
              Toast.error('Failed to update question. Please try again.');
            }
          })
          .catch(error => {
            console.error('An error occurred while updating the question:', error);
            Toast.error('An error occurred. Please try again.');
          });
      } else {
        axios.put(`/admin/iclm-response-options/${this.editData.id}`, { response: this.editData.response })
          .then(response => {
            if (response.data.status) {
              this.fetchQuestions();
              Toast.success(response.data.message);
            } else {
              Toast.error('Failed to update response. Please try again.');
            }
          })
          .catch(error => {
            console.error('An error occurred while updating the response:', error);
            Toast.error('An error occurred. Please try again.');
          });
      }
      this.closeModal();
    },
    confirmDelete(type, id) {
      this.itemIdToDelete = id;
      this.deleteType = type;
      this.isDeleteModalOpen = true;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
      this.itemIdToDelete = null;
      this.deleteType = '';
    },
    deleteItem() {
      let url = '';
      if (this.deleteType === 'question') {
        url = `/admin/iclm-questions/${this.itemIdToDelete}`;
      } else {
        url = `/admin/iclm-response-options/${this.itemIdToDelete}`;
      }
      axios.delete(url)
        .then(response => {
          if (response.data.status) {
            this.fetchQuestions();
            Toast.success(response.data.message);
          } else {
            Toast.error('Failed to delete item. Please try again.');
          }
          this.closeDeleteModal();
        })
        .catch(error => {
          console.error('An error occurred while deleting the item:', error);
          Toast.error('An error occurred. Please try again.');
          this.closeDeleteModal();
        });
    }
  },
  async mounted() {
    this.fetchQuestions();
    await this.fetchQuestionTypes();
    await this.fetchQuestionCodes();
  }
};
</script>



<style lang="scss" scoped>
.card-container {
  .card-header {
    background-color: #195093;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .left {
      display: flex;
      gap: 20px;
      align-items: center;

      .back-btn {
        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          color: var (--white);
        }
      }

      .conditions {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          padding: 5px;
          border-radius: 10px;
          border: none;
          cursor: pointer;
          background-color: transparent;
          color: var(--white);
          transition: 200ms ease;
        }

        .show {
          background-color: var(--white);
          color: var(--blue-500);
        }
      }
    }

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  .tb-container {
    border: 1px solid #E4E7EC;
    // padding: 10px;
    border-radius: 8px;

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;
      background-color: white;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #E4E7EC;



      thead {
        background-color: #F9FAFB;
        color: #344054;
        border-radius: 8px;
        // border-bottom: 1px solid #E4E7EC;


        th {
          padding: 15px 16px;

          border-left: 1px solid #E4E7EC;
          border-right: 1px solid #E4E7EC;
          border-bottom: 1px solid #E4E7EC;

          &:nth-child(1) {
            border-left: none;
          }
        }
      }

      tbody {
        tr {
          // border-bottom: 1px solid     #E4E7EC;

          td {
            // padding: 10px;
            padding: 5px 0;
            font-size: 14px;
            border: 1px solid #E4E7EC;




            .argency-badge {
              padding: 4px 2px;
              border-radius: 30px;
              font-size: 14px;
              background-color: rgba(110, 64, 50, 0.829);
              display: flex;
              color: var(--white);
              font-weight: bolder;
            }

            .viewAnswers {
              color: var(--blue-500);
              // color: var(--white);
              padding: 5px;
              border: none;
              cursor: pointer;
              transition: 200ms ease;


            }
          }

        }
      }
    }

  }

  .tb-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px;

    .center {
      display: flex;
      gap: 8px;

      .activePagination {
        background-color: #D4DBE2;

      }
    }


    button {
      width: 32px;
      height: 32px;
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid #D4DBE2;
      cursor: pointer;

      &:hover {
        background-color: #dfe4e9;
      }
    }
  }

  .edit-button,
  .delete-button {
    background-color: transparent;
    // color: rgba(28, 52, 84, 0.26);
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 200ms ease;
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: #006AF5;
          fill-opacity: 1;
        }
      }
    }
  }


  .delete-button-action,
  .edit-button-action {
    border: none;
    width: 31px;
    height: 31px;
    border-radius: 8px;
    margin-left: 8px;
    background-color: transparent;
    transition: 200ms ease;
    cursor: pointer;

    &:hover {
      background-color: #006AF5;


      svg {
        path {
          fill: white;
          fill-opacity: 1;
        }
      }

    }
  }
}

.right-td {
  width: 70%;
  border-left: none;
  border-right: none;
}

.left-td {
  width: 30%;
  border-left: none;
  border-right: none;
}


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 200ms ease;

    &.save {
      background-color: var(--blue-500);
      color: var(--white);

      &:hover {
        background-color: var(--blue-600);
      }
    }

    &.cancel,
    &.delete {
      background-color: var(--gray);
      color: var(--white);

      &:hover {
        background-color: #504e74;
      }
    }
  }
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--gray);
  border-radius: 5px;
  resize: vertical;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--gray);
  border-radius: 5px;
}

.form-container {
  padding: 7px;
  border-radius: 6px;
}

.btn-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.activate {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.activate:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.icon {
  display: inline-block;
  margin-right: 5px;
}
.skip-button {
  text-decoration: underline;
}
</style>
