import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/@fortawesome/fontawesome-free/js/all.js";
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';


createApp(App).use(store).use(Toast).use(router).mount("#app");
