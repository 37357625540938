<template>
  <div class="form-container" v-if="hasPermission('other', 'create_monitors')">
    <p>Please fill the inputs with <b>Health Facility Team</b> information</p>
    <form @submit.prevent autocomplete="off">
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.first_name" spellcheck="false" id="firstname" required />
            <label for="firstname">First Name</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.last_name" spellcheck="false" id="lastname" required />
            <label for="lastname">Last Name</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.phone" spellcheck="false" id="phone" required />
            <label for="phone">Phone Number</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.nid" spellcheck="false" id="nid" required />
            <label for="nid">National ID (NID)</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container dob">
            <select required v-model="formData.gender" id="gender">
              <option value="" hidden>-- Choose Gender --</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <label for="gender">Gender</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="password" v-model="formData.password" spellcheck="false" id="password" required />
            <label for="password">Password</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container dob">
            <select required id="province" v-model="formData.province_id" @change="fetchDistricts">
              <option value="" hidden>-- Choose Province --</option>
              <option :value="province.id" v-for="(province, index) in provinces" :key="index">
                {{ province.name }}
              </option>
            </select>
            <label for="province">Province</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container dob">
            <select required id="district" v-model="formData.district_id" @change="fetchDistrictHospitals">
              <option value="" hidden>-- Choose District --</option>
              <option :value="district.id" v-for="(district, index) in districts" :key="index">
                {{ district.name }}
              </option>
            </select>
            <label for="district">District</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>
            <input type="radio" v-model="facilityType" value="district_hospital">
            District Hospital
          </label>
        </div>
        <div class="col">
          <label>
            <input type="radio" v-model="facilityType" value="health_center">
            Health Center
          </label>
        </div>
      </div>
      <div v-if="facilityType === 'district_hospital'">
        <div class="row">
          <div class="col">
            <div class="input-container dob">
              <select v-model="selectedDistrictHospital" required>
                <option value="" hidden>-- Choose District Hospital --</option>
                <option v-for="hospital in districtHospitals" :key="hospital.id" :value="hospital.id">{{
              hospital.hospital_name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="facilityType === 'health_center'">
        <div class="row">
          <div class="col">
            <div class="input-container dob">
              <select v-model="selectedLocationType" required @change="fetchHealthCenters">
                <option value="" hidden>-- Choose Location Type --</option>
                <option value="1">Rural</option>
                <option value="2">Urban</option>
              </select>
            </div>
          </div>
          <div class="col" v-if="selectedLocationType">
            <div class="input-container dob">
              <select v-model="selectedHealthCenter" required>
                <option value="" hidden>-- Choose Health Center --</option>
                <option v-for="center in healthCenters" :key="center.id" :value="center.id">{{ center.health_center_name
                  }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="submit" @click="createUser">
            <span v-if="!showSpinner" class="btn-wrapper">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9 7.5C10.6569 7.5 12 6.15685 12 4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5C6 6.15685 7.34315 7.5 9 7.5ZM9 15.75C11.8995 15.75 14.25 14.4069 14.25 12.75C14.25 11.0931 11.8995 9.75 9 9.75C6.1005 9.75 3.75 11.0931 3.75 12.75C3.75 14.4069 6.1005 15.75 9 15.75Z"
                  fill="white" />
              </svg> <span>Create</span>
            </span>
            <spinnerVue v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "@/helpers/axios.js";
import spinnerVue from "@/components/spinner.vue";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        gender: "",
        nid: "",
        password: "",
        user_type: "health_facility_team",
        province_id: "",
        district_id: "",
        sector_id: "",
        cell_id: "",
        village_id: "",
      },
      showSpinner: false,
      facilityType: "",
      selectedDistrictHospital: "",
      selectedLocationType: "",
      selectedHealthCenter: "",
      districtHospitals: [],
      healthCenters: [],
      provinces: [],
      districts: [],
    };
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  mounted() {
    this.fetchProvinces();
  },

  methods: {
    async fetchProvinces() {
      try {
        const response = await axios.get("province");
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },

    async fetchDistricts() {
      try {
        const response = await axios.get(`districts/${this.formData.province_id}`);
        this.districts = response.data.data;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },

    async fetchDistrictHospitals() {
      try {
        if (this.formData.district_id) {
          const response = await axios.get(`district-hospital/${this.formData.district_id}`);
          this.districtHospitals = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching district hospitals:", error);
      }
    },

    async fetchHealthCenters() {
      try {
        if (this.formData.district_id && this.selectedLocationType) {
          const response = await axios.get(`health-center/${this.formData.district_id}/${this.selectedLocationType}`);
          this.healthCenters = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching health centers:", error);
      }
    },

    async createUser() {
      this.showSpinner = true;
      try {
        // Prepare data to send to backend based on selected facility type
        const requestData = {
          ...this.formData,
          district_hospital_id: this.facilityType === 'district_hospital' ? this.selectedDistrictHospital : null,
          health_center_id: this.facilityType === 'health_center' ? this.selectedHealthCenter : null,
        };

        const response = await axios.post(`admin/create-users`, requestData);
        if (response.data.status) {
          Toast.success("User Created Successfully");
          this.$router.push({ name: "health_facility_teams" });
        }
        this.showSpinner = false;
      } catch (error) {
        this.showSpinner = false;
        console.error("Error creating user:", error);
        Toast.error(error.response.data.message);
      }
    },
  },

  components: {
    spinnerVue,
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #E4E7EC;
  border-radius: 8px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .row {
    display: flex;
    gap: 20px;

    .col {
      width: 50%;

      label {
        display: block;
        margin-bottom: 10px;
      }

      button {
        background-color: #006AF5;
        color: var(--white);
        width: 100px;
        height: 40px;
        border: none;
        border-radius: 5px;
        transition: 200ms ease;
        padding: 11px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }

        &:hover {
          background-color: var(--blue-600);
          cursor: pointer;
        }
      }

      .input-container {
        position: relative;

        input,
        select {
          width: 100%;
          border: none;
          padding: 12px;
          outline: none;
          border-radius: 5px;
          border: 2px solid rgb(202, 202, 202);
          background-color: white;

          &:focus,
          &:valid {
            border: 2px solid var(--blue-500);
            color: var(--blue-500);

            +label {
              left: 14px;
              scale: 0.9;
              transform: translateY(-20px);
              background-color: white;
              color: var(--blue-500);
              font-weight: bolder;
            }
          }
        }

        label {
          position: absolute;
          top: 10px;
          left: 14px;
          transition: 200ms ease;
        }
      }

      .dob {
        position: relative;

        input {
          width: 100%;
          border: none;
          padding: 12px;
          outline: none;
          border-radius: 5px;
          border: 2px solid rgb(202, 202, 202);

          &:focus,
          &:valid {
            border: 2px solid var(--blue-500);
            color: var(--blue-500);

            +label {
              color: var(--blue-500);
              font-weight: bolder;
            }
          }
        }

        label {
          position: absolute;
          top: 12px;
          background-color: white;
          left: 9px;
          scale: 0.9;
          transform: translateY(-20px);
        }
      }
    }
  }
}
</style>
