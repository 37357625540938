// helpers/axios.js
import axios from "axios";

let ApiUrl = "";
if (window.location.origin.includes("localhost")) {
  ApiUrl = "https://iclm.ubumenyi.rw/api/";

  // ApiUrl = "http://127.0.0.1:8000/api/";

} else {
  ApiUrl = "https://iclm.ubumenyi.rw/api/";
}

const instance = axios.create({
  baseURL: ApiUrl,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default instance;
