<template>
  <div class="card-container">
    <div class="card-header">
      <div class="left">
        <div class="back-btn">
          <button @click="this.$router.go(-1)">
            <div class="icon"><i class="fa fa-arrow-left"></i> back</div>
          </button>
        </div>
        <div class="conditions">
          <!-- <button
            :class="currentCondition == 'hiv' ? 'show' : ''"
            @click="changeCondition('hiv')"
          >
            HIV/AIDS
          </button>
          <button
            :class="currentCondition == 'tuberculosis' ? 'show' : ''"
            @click="changeCondition('tuberculosis')"
          >
            Tuberculosis
          </button>
          <button
            :class="currentCondition == 'malaria' ? 'show' : ''"
            @click="changeCondition('malaria')"
          >
            Malaria
          </button> -->
        </div>
      </div>
      <div class="right">
        <ul>
          <li>
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                v-model="searchQuery"
                @input="filterData"
                placeholder="Search for quesitions"
              />
              <div class="icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <table>
      <thead>
        <th>id</th>
        <th>Questions</th>
        <th>Response</th>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">Not found</td>
        </tr>
        <!-- <tr v-for="(item, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>{{ item.name }}</td>
          
        </tr> -->
      </tbody>
    </table>
    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");

export default {
  data() {
    return {
      currentCondition: "hiv",
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 9,
      users: [
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
      ],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter((item) => regex.test(item.name));
      }
    },

    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    changeCondition(condition) {
      this.currentCondition = condition;
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Community Members.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text("Community Members", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("community members.pdf");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .left {
      display: flex;
      gap: 20px;
      align-items: center;

      .back-btn {
        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          color: var(--white);
        }
      }

      .conditions {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
          padding: 5px;
          border-radius: 10px;
          border: none;
          cursor: pointer;
          background-color: transparent;
          color: var(--white);
          transition: 200ms ease;
        }

        .show {
          background-color: var(--white);
          color: var(--blue-500);
        }
      }
    }

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid var(--blue-500);

    thead {
      background-color: var(--blue-500);
      color: var(--white);
      position: sticky;
      top: 50px;

      th {
        padding: 10px;
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;
        }

        &:nth-child(even) {
          background-color: rgb(218, 218, 218);
        }
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
  