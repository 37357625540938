<template>
  <div class="community_member-container">
    <div class="navbar">
      <ul>
        <router-link v-if="hasPermission('tools','community_based_tool')" :to="{ name: 'community_based_tool' }" :class="{
          'active-user-link': $route.path.includes('/community-based-tool'),
        }">
          <li>Community Based Tool</li>
        </router-link>
      </ul>


      <div class="input-container">
        <input type="text" name="" spellcheck="false" autocomplete="off" id="searchInput" placeholder="Search">

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
            stroke="#D3E4FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="community_member-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  }
};
</script>


<style lang="scss" scoped>
.community_member-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;


  .navbar {
    width: 100%;
    background-color: #0A2472;
    border-radius: 8px;
    position: sticky;
    top: 0px;
    padding: 8px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      list-style-type: none;

      display: flex;
      justify-content: flex-start;
      gap: 16px;

      a {
        text-decoration: none;
        color: white;

        li {
          padding: 10px 20px;
          background-color: transparent;
          border-radius: 7px;
          transition: 300ms ease;
          width: 293px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }


      }

      .active-user-link {
        color: #0A2472;

        li {
          background-color: white;

        }
      }
    }

    .input-container {
      position: relative;

      input {
        width: 192px;
        padding: 10px 14px;
        padding-left: 42px;

        background-color: rgba(255, 255, 255, 8%);
        border: 1px solid rgba(211, 228, 255, 100%);
        border-radius: 8px;
        outline: none;
        color: rgba(211, 228, 255, 100%);

        &::placeholder {
          color: rgba(211, 228, 255, 100%);
        }
      }

      svg {
        position: absolute;
        top: 8px;
        left: 14px;
      }
    }


  }

  .community_member-body {
    width: 100%;
    margin-top: 20px;
  }
}
</style>