<template>
  <div class="spinner"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.spinner {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  border-top: 2px solid var(--blue-500);
  border-right: 2px solid var(--blue-500);
  border-bottom: 2px solid var(--blue-500);
  border-left: 2px solid transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
