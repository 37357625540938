<template>
  this is diagnosis responses
</template>

<script>
export default {

}
</script>

<style>

</style>