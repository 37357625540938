<template>
  <div class="card-container">
    <div class="card-header">
      <div class="left">
        <h3>Community Health Worker</h3>
      </div>
      <div class="right">
        <ul>
          <li class="export excel" @click="exportToExcel">
            <i class="fas fa-file-excel"></i>
          </li>
          <li class="export pdf" @click="exportToPdf">
            <i class="fas fa-file-pdf"></i>
          </li>
          <li>
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                v-model="searchQuery"
                @input="filterData"
                placeholder="Search for user"
              />
              <div class="icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <table>
      <thead>
        <th>No</th>
        <th>Name</th>
        <th>District</th>
        <th>Servey</th>
        <th>Health Facility</th>
        <th>Comment</th>
        <th>Urgency</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>{{ item.name }}</td>
          <td>0798663921</td>
          <td>
            <button @click="openModal" class="viewAnswers">View Servey</button>
          </td>
          <td>Male</td>
          <td>Gasabo</td>
          <td><div class="argency-badge">High</div></td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
  </div>

  <div class="modal-wrapper">
    <teleport to="body">
      <transition name="fade">
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal" @click.stop>
            <div class="modal-header">
              <div class="circle" @click="closeModal">
                <i class="fa fa-close"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="modal-body-container">
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
                <div class="question-container">
                  <h3>Q1. This is Question</h3>
                  <div class="answer-container">
                    <div class="line"></div>
                    <p>Yes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");
import axios from "axios";

export default {
  data() {
    return {
      showModal: false,
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 7,
      users: [
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
        {
          name: "Prince",
          age: 12,
          address: "Kigali",
        },
      ],
    };
  },
  mounted() {
    this.fetch_community_members();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter((item) => regex.test(item.name));
      }
    },

    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Community Members.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text("Community Members", doc.internal.pageSize.getWidth() / 2, 10, {
        align: "center",
      });

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("community members.pdf");
    },
    async fetch_community_members() {
      try {
        const response = await axios.get(
          "http://iclm.ubumenyi.rw/api/admin/all/users/community_member"
        );

        console.log(response);
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid var(--blue-500);

    thead {
      background-color: var(--blue-500);
      color: var(--white);
      position: sticky;
      top: 50px;

      th {
        padding: 10px;
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;

          .argency-badge {
            padding: 4px 2px;
            border-radius: 30px;
            font-size: 14px;
            background-color: rgba(230, 72, 24, 0.829);
            width: 80px;
            color: var(--white);
            font-weight: bolder;
          }

          .viewAnswers {
            background-color: var(--blue-500);
            color: var(--white);
            padding: 5px;
            border: none;
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: var(--blue-600);
            }
          }
        }

        &:nth-child(even) {
          background-color: rgb(218, 218, 218);
        }
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}

.modal-wrapper {
  position: relative;
  // overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(15px);
  z-index: 99999999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  width: 50%;
  min-height: 40%;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    position: sticky;
    top: 0;

    .circle {
      background-color: var(--blue-500);
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        background-color: var(--blue-600);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 50px;

    .modal-body-container {
      display: flex;
      gap: 30px;
      flex-direction: column;

      .question-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h3 {
          color: var(--blue-500);
        }
        .answer-container {
          display: flex;
          align-items: center;
          gap: 10px;
          .line {
            width: 30px;
            height: 1px;
            background-color: var(--blue-500);
          }
        }
      }
    }
  }
}

.fade-enter-active {
  $modalOpen: True;
  @if $modalOpen == True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
