// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import { createStore } from 'vuex';
import axios from "@/helpers/axios.js";
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
    user: null,
    permissions: {
      tools: [],
      conditions: [],
      other: [],
      all: []
    },
    token: null
  },
  getters: {
    hasPermission: (state) => (category, permission) => {
      if (state.permissions.all.includes('all')) {
        return true;
      }
      if (state.permissions[category]) {
        return state.permissions[category].includes(permission);
      }
      return false;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPermissions(state, permissions) {
      state.permissions = {
        tools: permissions.tools || [],
        conditions: permissions.conditions || [],
        other: permissions.other || [],
        all: permissions.all || []
      };
    },
    setToken(state, token) {
      state.token = token;
    },
    logout(state) {
      state.user = null;
      state.permissions = {
        tools: [],
        conditions: [],
        other: [],
        all: []
      };
      state.token = null;
      localStorage.clear();
    }
  },
  actions: {
    async loginUser({ commit }, credentials) {
      try {
        const response = await axios.post('admin/login', credentials);
        const { user, permissions, token } = response.data;

        commit('setUser', user);
        commit('setPermissions', permissions);
        commit('setToken', token);

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('token', token);
        localStorage.setItem('isLoggedIn', "true");

        
        console.log('Tokens:', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return response.data;
      } catch (error) {
        if (error.response && error.response.data) {
          console.error('Login error response data:', error.response.data);
          throw error;
        } else {
          console.error('Login error:', error);
          throw new Error("An unexpected error occurred. Please try again.");
        }
      }
    },
    loadUserFromLocalStorage({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'));
      const permissions = JSON.parse(localStorage.getItem('permissions'));
      const token = localStorage.getItem('token');

      if (user && permissions && token) {
        commit('setUser', user);
        commit('setPermissions', permissions);
        commit('setToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    },
    async fetchPermissions({ commit }) {
      const response = await axios.get('permissions');
      commit('setPermissions', response.data.permissions);
    }
  },
  plugins: [createPersistedState()]
});

export default store;








