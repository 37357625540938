<template>
  <div class="form-container">
    <p>Please fill the inputs with Admin's information</p>
    <form @submit.prevent="createUser" autocomplete="off">
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.first_name" spellcheck="false" id="firstname" required />
            <label for="firstname">First Name</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.last_name" spellcheck="false" id="lastname" required />
            <label for="lastname">Last Name</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.phone" spellcheck="false" id="phone" required />
            <label for="phone">Phone Number</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="text" v-model="formData.email" spellcheck="false" id="email" required />
            <label for="phone">Email</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container dob">
            <select required v-model="formData.gender" id="gender">
              <option value="" hidden>-- Choose Gender --</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <label for="gender">Gender</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <input type="password" v-model="formData.password" spellcheck="false" id="password" required />
            <label for="password">Password</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-container dob">
            <select required id="province" v-model="formData.province_id" @change="fetchDistricts">
              <option value="" hidden>-- Choose Province --</option>
              <option :value="province.id" v-for="(province, index) in provinces" :key="index">
                {{ province.name }}
              </option>
            </select>
            <label for="province">Province</label>
          </div>
        </div>
        <div class="col">
          <div class="input-container dob">
            <select required id="district" v-model="formData.district_id">
              <option value="" hidden>-- Choose District --</option>
              <option :value="district.id" v-for="(district, index) in districts" :key="index">
                {{ district.name }}
              </option>
            </select>
            <label for="district">District</label>
          </div>
        </div>
      </div>
      <!-- Permissions Section -->
      <div class="permissions-section">
        <h3>Permissions</h3>
        <div class="permissions-grid">
          <div v-for="group in Object.keys(permissionGroups)" :key="group" class="permission-group">
            <h4>{{ logAndCapitalize(group) }}</h4>
            <div v-for="permission in permissionGroups[group]" :key="permission.id">
              <label>
                <input type="checkbox" v-model="formData.permissions[group]" :value="permission.name" />
                {{ permission.description }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button @click="createUser">
            <span v-if="!showSpinner">
              <span v-if="!showSpinner" class="btn-wrapper">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9 7.5C10.6569 7.5 12 6.15685 12 4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5C6 6.15685 7.34315 7.5 9 7.5ZM9 15.75C11.8995 15.75 14.25 14.4069 14.25 12.75C14.25 11.0931 11.8995 9.75 9 9.75C6.1005 9.75 3.75 11.0931 3.75 12.75C3.75 14.4069 6.1005 15.75 9 15.75Z"
                    fill="white" />
                </svg> <span>Create</span>
              </span>
            </span>
            <spinnerVue v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import spinnerVue from "@/components/spinner.vue";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        password: "",
        user_type: "admin",
        province_id: "",
        district_id: "",
        permissions: {
          tools: [],
          conditions: [],
          locations: [],
          other: []
        }
      },
      showSpinner: false,
      provinces: [],
      districts:[],
      showDistricts: [],
      selectedProvince: null,
      permissions: [],
      permissionGroups: {
        tools: [],
        conditions: [],
        locations: [],
        other: []
      }
    };
  },
  mounted() {
    this.fetchPermissions();
    this.fetchProvinces();
  },
  methods: {
    async fetchPermissions() {
      try {
        const response = await axios.get("/permissions");
        this.permissions = response.data.data;
        this.groupPermissions();
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    },
    groupPermissions() {
      this.permissionGroups = {
        tools: [],
        conditions: [],
        locations: [],
        other: []
      };
      this.permissions.forEach(permission => {
        if (this.permissionGroups[permission.type]) {
          this.permissionGroups[permission.type].push(permission);
        }
      });
    },
    async fetchProvinces() {
      const response = await axios.get("province");
      this.provinces = response.data.data;
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(
          `districts/${this.formData.province_id}`
        );
        this.districts = response.data.data;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async toggleDistricts(province_id) {
      if (this.selectedProvince === province_id) {
        this.selectedProvince = null;
        this.showDistricts = [];
      } else {
        try {
          const response = await axios.get(`districts/${province_id}`);
          this.showDistricts = response.data.data;
          this.selectedProvince = province_id;
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      }
    },
    async createUser() {
      this.showSpinner = true;
      try {
        const response = await axios.post("/admin/create-admin", this.formData);
        if (response.data.status) {
          Toast.success("User Created Successfully");
          this.$router.push({ name: "admins" });
        }
      } catch (error) {
        console.error("Error creating user:", error);
        // Toast.error(error.response.data.message);
      } finally {
        this.showSpinner = false;
      }
    },
    capitalizeFirstLetter(string) {
      if (!string || typeof string !== 'string') {
        return '';
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    logAndCapitalize(string) {
      console.log('String received:', string);
      return this.capitalizeFirstLetter(string);
    },
  },
  components: {
    spinnerVue
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #E4E7EC;
  border-radius: 8px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row {
      display: flex;
      gap: 20px;

      .col {
        width: 50%;

        button {
          background-color: #006AF5;
          color: var(--white);
          width: 100px;
          height: 40px;
          border: none;
          border-radius: 5px;
          transition: 200ms ease;
          padding: 11px 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
          }

          &:hover {
            background-color: var(--blue-600);
            cursor: pointer;
          }
        }

        .input-container {
          position: relative;

          input,
          select {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);
            background-color: white;

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);

              +label {
                left: 14px;
                scale: 0.9;
                transform: translateY(-20px);
                background-color: white;
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 10px;
            left: 14px;
            transition: 200ms ease;
          }
        }

        .dob {
          position: relative;

          input {
            width: 100%;
            border: none;
            padding: 12px;
            outline: none;
            border-radius: 5px;
            border: 2px solid rgb(202, 202, 202);

            &:focus,
            &:valid {
              border: 2px solid var(--blue-500);
              color: var(--blue-500);

              +label {
                color: var(--blue-500);
                font-weight: bolder;
              }
            }
          }

          label {
            position: absolute;
            top: 12px;
            background-color: white;
            left: 9px;
            scale: 0.9;
            transform: translateY(-20px);
          }
        }
      }
    }
  }

  .permissions-section {
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;

    h3 {
      margin-bottom: 10px;
    }

    .permissions-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;

      .permission-group {
        h4 {
          font-size: 1.1em;
          margin-bottom: 10px;
        }

        h5 {
          cursor: pointer;
          margin: 5px 0;
          font-size: 1em;

          &:hover {
            color: var(--blue-500);
          }
        }

        label {
          display: block;
          margin-bottom: 5px;
          cursor: pointer;

          input {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
