<template>
  <div class="survey-page">
    <nav class="breadcrumb">
      <router-link to="/">Dashboard</router-link> &gt;
      <router-link to="/main/reports/health-facility-team/patient-tool">Survey</router-link> &gt;
      <span>Survey Details</span>
    </nav>
    <h1>Survey Details</h1>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <div class="survey-metadata">
        <div v-if="iclmMember" class="metadata-section">
          <h2>ICLM Member Details</h2>
          <p><strong>Name:</strong> {{ iclmMember.first_name }} {{ iclmMember.last_name }}</p>
          <p><strong>Phone:</strong> {{ iclmMember.phone }}</p>
        </div>
        <div v-if="participant" class="metadata-section">
          <h2>Patient Details</h2>
          <p><strong>Name:</strong> {{ participant.full_name }}</p>
          <p><strong>Phone:</strong> {{ participant.phone }}</p>
        </div>
        <div class="metadata-section" v-if="interviewRating">
          <h2>Survey Information</h2>
          <p><strong>Done on:</strong> {{ formatDate(interviewRating.created_at) }}</p>
          <p><strong>Rating:</strong> {{ interviewRating.rating }}</p>
          <p><strong>Status:</strong> {{ interviewRating.status }}</p>
        </div>
      </div>
    </div>

    <div class="survey-container">
      <div v-if="surveyData">
        <div class="introduction-section">
          <h2>Introduction Questions</h2>
          <div v-for="(question, index) in filteredIntroductionQuestions" :key="index" class="question-container">
            <h3 class="question">{{ question.question }}</h3>
            <div class="answers-list">
              <div v-for="answer in question.answers" :key="answer.id" class="answer-container">
                <p>{{ answer.response_option ? answer.response_option.response : answer.response }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="main-questions-section">
          <h2>Main Questions and Answers</h2><br>
          <div v-for="(question, index) in filteredMainQuestions" :key="index" class="question-container">
            <h3 class="question">{{ question.question }}</h3>
            <div class="answers-list">
              <div v-for="answer in question.answers" :key="answer.id" class="answer-container">
                <p>{{ answer.response_option ? answer.response_option.response : answer.response }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="loading">Loading...</p>
      </div>
    </div>
    
  </div><br><br>
</template>

<script>
import axios from "@/helpers/axios.js";

export default {
  data() {
    return {
      surveyData: null,
      introductionData: null,
      iclmMember: null,
      participant: null,
      interviewRating: null,
      isLoading: true,
    };
  },
  created() {
    this.fetchSurveyData();
    this.fetchIntroductionData();
    // this.fetchIclmMemberData();
    // this.fetchParticipantData();
    // this.fetchInterviewRating();
    this.fetchData();
  },
  computed: {
    filteredIntroductionQuestions() {
      return this.introductionData?.questions.filter(q => q.answers.length > 0) || [];
    },
    filteredMainQuestions() {
      return this.surveyData?.questions.filter(q => q.answers.length > 0) || [];
    }
  },
  methods: {
    async fetchData() {
      try {
        const { userId, sessionId, participantId } = this.$route.params;
        this.isLoading = true;

        const [surveyResponse, interviewResponse, iclmResponse, participantResponse] = await Promise.all([
          axios.get(`admin/patient-tool/answers/${userId}/${sessionId}/${participantId}`),
          axios.get(`admin/interview-rating/${userId}/${participantId}/${sessionId}`),
          axios.get(`admin/member-details/${userId}`),
          axios.get(`admin/participant-details/${participantId}`),
        ]);

        if (surveyResponse.data.status && surveyResponse.data.data) {
          this.surveyData = surveyResponse.data.data;
          this.introQuestions = this.surveyData.questions.filter(q => q.is_intro);
          this.mainQuestions = this.surveyData.questions.filter(q => !q.is_intro);
        }

        if (interviewResponse.data.status && interviewResponse.data.data) {
          this.interviewRating = interviewResponse.data.data;
        }

        if (iclmResponse.data.status && iclmResponse.data.data) {
          this.iclmMember = iclmResponse.data.data[0];
        }

        if (participantResponse.data.status && participantResponse.data.data) {
          this.participant = participantResponse.data.data[0];
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async fetchSurveyData() {
      try {
        const { userId, sessionId, participantId } = this.$route.params;
        const response = await axios.get(`admin/patient-tool/answers/${userId}/${sessionId}/${participantId}`);

        if (response.data.status && response.data.data && response.data.data.questions) {
          this.surveyData = response.data.data;
          console.log("Main Questions Data:", this.surveyData);
        } else {
          console.error('Error: Unable to fetch survey data');
        }
      } catch (error) {
        console.error('Error fetching survey data:', error);
      }
    },
    async fetchIntroductionData() {
      try {
        const { userId, sessionId, participantId } = this.$route.params;
        const response = await axios.get(`admin/patient-tool/intro-answers/${userId}/${sessionId}/${participantId}`);

        if (response.data.status && response.data.data && response.data.data.questions) {
          this.introductionData = response.data.data;
          console.log("Introduction Questions Data:", this.introductionData);
        } else {
          console.error('Error: Unable to fetch introduction data');
        }
      } catch (error) {
        console.error('Error fetching introduction data:', error);
      }
    },
    async fetchIclmMemberData() {
      try {
        const { userId } = this.$route.params;
        const response = await axios.get(`admin/member-details/${userId}`);

        if (response.data.status && response.data.data.length > 0) {
          this.iclmMember = response.data.data[0];
          console.log("ICLM Member Data:", this.iclmMember);
        } else {
          console.error('Error: Unable to fetch ICLM member data');
        }
      } catch (error) {
        console.error('Error fetching ICLM member data:', error);
      }
    },
    async fetchParticipantData() {
      try {
        const { participantId } = this.$route.params;
        const response = await axios.get(`admin/participant-details/${participantId}`);

        if (response.data.status && response.data.data.length > 0) {
          this.participant = response.data.data[0];
          console.log("Participant Data:", this.participant);
        } else {
          console.error('Error: Unable to fetch participant data');
        }
      } catch (error) {
        console.error('Error fetching participant data:', error);
      }
    },
    async fetchInterviewRating() {
      try {
        const { userId, sessionId, participantId } = this.$route.params;
        const response = await axios.get(`admin/interview-rating/${userId}/${participantId}/${sessionId}`);

        if (response.data.status && response.data.data) {
          this.interviewRating = response.data.data;
        } else {
          console.error('Error: Unable to fetch interview rating');
        }
      } catch (error) {
        console.error('Error fetching interview rating:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  }
};
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
  overflow: auto;
}
.survey-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 14px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.survey-metadata {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.metadata-section {
  flex: 1;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
}

.metadata-section h2 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}

.metadata-section p {
  margin: 5px 0;
  font-size: 14px;
  color: #777;
}

.survey-container {
  flex: 1;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.introduction-section,
.main-questions-section {
  margin-bottom: 20px;
}

.question-container {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.question {
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}

.answers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer-container {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.answer-container p {
  margin: 0;
}

.loading {
  font-size: 1.2em;
  text-align: center;
  margin-top: 20px;
}
</style>
