<template>
  <div class="forgot">
    <div class="forgot-header">
      <h2>Forgot Password</h2>
      <p>
        Please enter your phone number and we will send you instructions on how
        to reset your password
      </p>
    </div>
    <div class="forgot-body">
      <div class="input-container">
        <input type="phone" placeholder="Phone number" />
        <div class="icon"><i class="fa fa-phone"></i></div>
      </div>
      <div class="forgot-footer">
        <button>
          <span v-if="!showSpinner">Submit</span> <spinner v-else />
        </button>
        <router-link :to="{ name: 'login' }">Back to login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from "../../components/spinner.vue";

export default {
  data() {
    return {
      showSpinner: false,
    };
  },
  components: {
    spinner,
  },
};
</script>

<style lang="scss" scoped>
.forgot {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .forgot-header {
    color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-size: 30px;
    }
  }

  .forgot-body {
    .input-container {
      position: relative;

      input {
        width: 300px;
        padding: 10px;
        background-color: transparent;
        border: 1px solid var(--white);
        outline: none;
        padding-left: 35px;
        border-radius: 3px;
        color: white;

        &::placeholder {
          color: white;
        }
      }

      .icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: var(--white);
      }
    }
  }

  .forgot-footer {
    padding-top: 30px;
    display: flex;
    align-items: center;
    gap: 100px;
    button {
      width: 100px;
      height: 40px;
      border: none;
      color: var(--blue-500);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    a {
      color: var(--white);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
