<template>
  <div class="login-container">
 
    <router-view></router-view>
    <div class="footer">
      <p>Powered by <a target="_blank" href="https://ivas.rw">Innovative VAS</a></p>
    </div>
  </div>
</template>

<script>
import spinner from "../../components/spinner.vue";

export default {
  data() {
    return {
      showSpinner: false,
    };
  },
  components: {
    spinner,
  },
  mounted() {
    // window.addEventListener('load', ()=> {
    //   alert('it worked')
    // })
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/BG.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .footer {
    color: rgb(101, 128, 219);
    position: fixed;
    bottom: 30px;

    transform: translate(-0%, 50%);

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}
</style>
