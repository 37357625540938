<template>
  <div class="see-users-container">
    <div class="sidebar">
      <div class="left">
        <ul>
          <router-link :to="{ name: 'community_members' }" :class="{
            'active-user-link': $route.path.includes('/members'),
          }">
            <li>Community Based Team</li>
          </router-link>
          <router-link :to="{ name: 'health_facility_teams' }" :class="{
            'active-user-link': $route.path.includes('/health_facility_team'),
          }">
            <li>Health Facility Team</li>
          </router-link>
          <router-link :to="{ name: 'health_facility_leads' }" :class="{
            'active-user-link': $route.path.includes('/health_facility_lead'),
          }">
            <li>Health Facility Lead</li>
          </router-link>
          <router-link :to="{ name: 'admins' }" :class="{
            'active-user-link': $route.path.includes('/admins'),
          }">
            <li>Admins</li>
          </router-link>
        </ul>
      </div>

      <div class="right">
        <div class="input-container">
          <input type="text" spellcheck="false" autocomplete="off" name="" id="searchInput" placeholder="Search">

         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#D3E4FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg> 

        </div>
      </div>
    </div>
    <div class="see-users-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  mounted() {
    var searchInput = document.getElementById('searchInput');
    var tr = document.getElementsByTagName('tr');

    searchInput.addEventListener('keyup', () => {
      var myValue = searchInput.value.toUpperCase();

      for (var i = 0; i < tr.length; i++) {
        var td = tr[i].getElementsByTagName('td');

        for (var index = 0; index < td.length; index++) {
          if (td[index].innerHTML.toUpperCase().indexOf(myValue) > -1) {
            tr[i].style.display = '';
            break;
          } else {
            tr[i].style.display = 'none';
          }
        }
      }
    });
  }

};
</script>

<style lang="scss" scoped>
.see-users-container {
  display: flex;

  align-items: flex-start;
  flex-direction: column;
  padding: 20px 0;

  .sidebar {
    min-height: 25px;
    width: 100%;
    background-color: #0A2472;
    position: sticky;
    top: 0px;
    padding: 8px 8px 4px 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      .input-container {
        position: relative;

        input {
          width: 192px;
          padding: 10px 14px;
          padding-left: 42px;

          background-color: rgba(255, 255, 255, 8%);
          border: 1px solid rgba(211, 228, 255, 100%);
          border-radius: 8px;
          outline: none;
          color: rgba(211, 228, 255, 100%);

          &::placeholder {
            color: rgba(211, 228, 255, 100%);
          }
        }

        svg {
          position: absolute;
          top: 8px;
          left: 14px;
        }
      }

      
    }


    ul {
      list-style-type: none;
      // padding: 15px;
      display: flex;

      gap: 5px;

      a {
        text-decoration: none;
        color: white;

        li {
          padding: 10px;
          background-color: transparent;

          
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

        }


      }

      .active-user-link {
        li {
          background-color: white;
          color: #0A2472;
          font-weight: bold;
        }
      }
    }
  }

  .see-users-body {
    width: 100%;
  }
}
</style>
