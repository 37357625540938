<template>
    <div>
        <div class="horizontal-tabs">
            <ul class="sub-tabs">
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Analysis' }"
                    @click.prevent="activeCustomizeTab = 'Analysis'" href="#">
                    <li class="sub-link">
                        <svg class="analysisIcon" width="25" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 17C3 16.4477 3.44772 16 4 16H5C5.55228 16 6 16.4477 6 17V19C6 19.5523 5.55228 20 5 20H4C3.44772 20 3 19.5523 3 19V17ZM10 14C9.44772 14 9 14.4477 9 15V19C9 19.5523 9.44772 20 10 20H11C11.5523 20 12 19.5523 12 19V15C12 14.4477 11.5523 14 11 14H10ZM15 10C14.4477 10 14 10.4477 14 11V19C14 19.5523 14.4477 20 15 20H16C16.5523 20 17 19.5523 17 19V11C17 10.4477 16.5523 10 16 10H15ZM20 7C19.4477 7 19 7.44772 19 8V19C19 19.5523 19.4477 20 20 20H21C21.5523 20 22 19.5523 22 19V8C22 7.44772 21.5523 7 21 7H20Z"
                                fill="#1C274C" fill-opacity="0.3" />
                        </svg>
                        <span>Analysis Approach</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Locations' }"
                    @click.prevent="activeCustomizeTab = 'Locations'" href="#">
                    <li class="sub-link">
                        <svg class="locationIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.6667 2C8.79666 2 5.66666 5.13 5.66666 9C5.66666 14.25 12.6667 22 12.6667 22C12.6667 22 19.6667 14.25 19.6667 9C19.6667 5.13 16.5367 2 12.6667 2ZM7.66666 9C7.66666 6.24 9.90666 4 12.6667 4C15.4267 4 17.6667 6.24 17.6667 9C17.6667 11.88 14.7867 16.19 12.6667 18.88C10.5867 16.21 7.66666 11.85 7.66666 9ZM10.1667 9C10.1667 7.61929 11.2859 6.5 12.6667 6.5C13.5598 6.5 14.3851 6.9765 14.8317 7.75C15.2783 8.5235 15.2783 9.4765 14.8317 10.25C14.3851 11.0235 13.5598 11.5 12.6667 11.5C11.2859 11.5 10.1667 10.3807 10.1667 9Z"
                                fill="#1C274C" fill-opacity="0.3" />
                        </svg>
                        <span>Locations</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Tools' }"
                    @click.prevent="activeCustomizeTab = 'Tools'" href="#">
                    <li class="sub-link">
                        <svg class="toolsIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.34308 3.80211C10.3718 2.6007 11.3862 2 12.5 2C13.6138 2 14.6282 2.6007 16.6569 3.80211L17.3431 4.20846C19.3718 5.40987 20.3862 6.01057 20.9431 7C21.5 7.98943 21.5 9.19084 21.5 11.5937V12.4063C21.5 14.8092 21.5 16.0106 20.9431 17C20.3862 17.9894 19.3718 18.5901 17.3431 19.7915L16.6569 20.1979C14.6282 21.3993 13.6138 22 12.5 22C11.3862 22 10.3718 21.3993 8.34308 20.1979L7.65692 19.7915C5.6282 18.5901 4.61384 17.9894 4.05692 17C3.5 16.0106 3.5 14.8092 3.5 12.4063V11.5937C3.5 9.19084 3.5 7.98943 4.05692 7C4.61384 6.01057 5.6282 5.40987 7.65692 4.20846L8.34308 3.80211Z"
                                stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                            <circle cx="12.5" cy="12" r="3" stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                        </svg>
                        <span>Tools</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Condition' }"
                    @click.prevent="activeCustomizeTab = 'Condition'" href="#">
                    <li class="sub-link">
                        <svg class="conditionIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.1666" cy="12" r="6.25" stroke="#1C274C" stroke-opacity="0.3" />
                            <path d="M12.1666 5L12.1666 5.5" stroke="#1C274C" stroke-opacity="0.3"
                                stroke-linecap="round" />
                            <path d="M12.1666 17.5V19.5" stroke="#1C274C" stroke-opacity="0.3" stroke-linecap="round" />
                            <path
                                d="M7.31307 7.85355C7.50833 8.04882 7.82492 8.04882 8.02018 7.85355C8.21544 7.65829 8.21544 7.34171 8.02018 7.14645L7.31307 7.85355ZM6.81307 7.35355L7.31307 7.85355L8.02018 7.14645L7.52018 6.64645L6.81307 7.35355Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <path
                                d="M15.5631 7.89645C15.3678 8.09171 15.3678 8.40829 15.5631 8.60355C15.7583 8.79882 16.0749 8.79882 16.2702 8.60355L15.5631 7.89645ZM16.8131 6.64645L15.5631 7.89645L16.2702 8.60355L17.5202 7.35355L16.8131 6.64645Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <path
                                d="M17.0202 16.1464C16.8249 15.9512 16.5083 15.9512 16.3131 16.1464C16.1178 16.3417 16.1178 16.6583 16.3131 16.8536L17.0202 16.1464ZM17.5202 16.6464L17.0202 16.1464L16.3131 16.8536L16.8131 17.3536L17.5202 16.6464Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <path d="M4.66663 12L5.66663 12" stroke="#1C274C" stroke-opacity="0.3"
                                stroke-linecap="round" />
                            <path d="M17.6666 12L19.6666 12" stroke="#1C274C" stroke-opacity="0.3"
                                stroke-linecap="round" />
                            <path
                                d="M8.77018 16.1036C8.96544 15.9083 8.96544 15.5917 8.77018 15.3964C8.57492 15.2012 8.25833 15.2012 8.06307 15.3964L8.77018 16.1036ZM7.52018 17.3536L8.77018 16.1036L8.06307 15.3964L6.81307 16.6464L7.52018 17.3536Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <path
                                d="M12.6666 9C12.6666 9.55228 12.2189 10 11.6666 10C11.1143 10 10.6666 9.55228 10.6666 9C10.6666 8.44772 11.1143 8 11.6666 8C12.2189 8 12.6666 8.44772 12.6666 9Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <path
                                d="M10.6666 13C10.6666 13.5523 10.2189 14 9.66663 14C9.11434 14 8.66663 13.5523 8.66663 13C8.66663 12.4477 9.11434 12 9.66663 12C10.2189 12 10.6666 12.4477 10.6666 13Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <path
                                d="M16.1666 14C16.1666 14.5523 15.7189 15 15.1666 15C14.6143 15 14.1666 14.5523 14.1666 14C14.1666 13.4477 14.6143 13 15.1666 13C15.7189 13 16.1666 13.4477 16.1666 14Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11.1666 4.5)" stroke="#1C274C"
                                stroke-opacity="0.3" />
                            <path
                                d="M13.1666 20.5C13.1666 19.9477 12.7189 19.5 12.1666 19.5C11.6143 19.5 11.1666 19.9477 11.1666 20.5C11.1666 21.0523 11.6143 21.5 12.1666 21.5C12.7189 21.5 13.1666 21.0523 13.1666 20.5Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5.16663 7)" stroke="#1C274C"
                                stroke-opacity="0.3" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17.1666 7)" stroke="#1C274C"
                                stroke-opacity="0.3" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17.1666 19)" stroke="#1C274C"
                                stroke-opacity="0.3" />
                            <path
                                d="M4.66663 12C4.66663 11.4477 4.21891 11 3.66663 11C3.11434 11 2.66663 11.4477 2.66663 12C2.66663 12.5523 3.11434 13 3.66663 13C4.21891 13 4.66663 12.5523 4.66663 12Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <path
                                d="M21.6666 12C21.6666 11.4477 21.2189 11 20.6666 11C20.1143 11 19.6666 11.4477 19.6666 12C19.6666 12.5523 20.1143 13 20.6666 13C21.2189 13 21.6666 12.5523 21.6666 12Z"
                                stroke="#1C274C" stroke-opacity="0.3" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5.16663 19)" stroke="#1C274C"
                                stroke-opacity="0.3" />
                        </svg>
                        <span>Condition</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Question' }"
                    @click.prevent="activeCustomizeTab = 'Question'" href="#">
                    <li class="sub-link">
                        <svg class="questionsIcon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.99988 12C1.99988 7.28595 1.99988 4.92893 3.46434 3.46447C4.92881 2 7.28583 2 11.9999 2C16.7139 2 19.0709 2 20.5354 3.46447C21.9999 4.92893 21.9999 7.28595 21.9999 12C21.9999 16.714 21.9999 19.0711 20.5354 20.5355C19.0709 22 16.7139 22 11.9999 22C7.28583 22 4.92881 22 3.46434 20.5355C1.99988 19.0711 1.99988 16.714 1.99988 12Z"
                                stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" />
                            <path
                                d="M10.1249 8.875C10.1249 7.83947 10.9643 7 11.9999 7C13.0354 7 13.8749 7.83947 13.8749 8.875C13.8749 9.56245 13.5049 10.1635 12.9532 10.4899C12.4779 10.7711 11.9999 11.1977 11.9999 11.75V13"
                                stroke="#1C274C" stroke-opacity="0.3" stroke-width="1.5" stroke-linecap="round" />
                            <circle cx="11.9999" cy="16" r="1" fill="#1C274C" fill-opacity="0.3" />
                        </svg>
                        <span>Question</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Type' }"
                    @click.prevent="activeCustomizeTab = 'Type'" href="#">
                    <li class="sub-link">
                        <svg class="statusIcon" width="25" height="24" viewBox="0 0 25 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M15.8265 6.93496C16.1385 7.20737 16.1707 7.68116 15.8983 7.99319L8.04111 16.9932C7.89868 17.1563 7.69269 17.2499 7.47612 17.2499C7.25955 17.2499 7.05356 17.1563 6.91113 16.9932L3.76827 13.3932C3.49586 13.0812 3.52799 12.6074 3.84002 12.335C4.15205 12.0626 4.62584 12.0947 4.89825 12.4067L7.47612 15.3595L14.7683 7.00671C15.0407 6.69467 15.5145 6.66255 15.8265 6.93496Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M20.8507 7.0194C21.1506 7.30507 21.1622 7.7798 20.8765 8.07975L12.3049 17.0797C12.1534 17.2388 11.9398 17.3235 11.7205 17.3114C11.5011 17.2993 11.2981 17.1916 11.1651 17.0169L10.7368 16.4544C10.4858 16.1248 10.5495 15.6542 10.8791 15.4033C11.1622 15.1877 11.5494 15.2044 11.812 15.4223L19.7904 7.04525C20.076 6.7453 20.5508 6.73373 20.8507 7.0194Z"
                                fill="#1C274C" fill-opacity="0.3" />
                        </svg>

                        <span>Type</span>
                    </li>
                </a>
                <a class="nav-link" :class="{ active: activeCustomizeTab === 'Dates' }"
                    @click.prevent="activeCustomizeTab = 'Dates'" href="#">
                    <li class="sub-link">
                        <svg class="dateIcon" width="25" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M7 2C6.44772 2 6 2.44772 6 3V4H4C2.89543 4 2 4.89543 2 6V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V6C22 4.89543 21.1046 4 20 4H18V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3C8 2.44772 7.55228 2 7 2ZM20 8H4V20H20V8Z"
                                fill="#1C274C" fill-opacity="0.3" />
                            <rect x="6" y="10" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="10" y="10" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="14" y="10" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="18" y="10" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="6" y="14" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="10" y="14" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                            <rect x="14" y="14" width="2" height="2" fill="#1C274C" fill-opacity="0.3" />
                        </svg>
                        <span>Dates</span>
                    </li>
                </a>

            </ul>
        </div>

        <!-- new change -->
        <div v-if="activeCustomizeTab === 'Question'">
            <div class="row">
                <div class="col" style="margin-top: 20px;">
                    <div class="scrollable-card">
                        <br>
                        <h4>Questions</h4><br>
                        <div v-if="activeCustomizeTab === 'Question' && selectedAnalysisApproach !== 'answerBase'"
                            class="customize-tab customize-tab-question">
                            <ul class="styled-list">
                                <li v-for="question in questions" :key="question.id"
                                    class="styled-list-item styled-list-item-question">
                                    <input type="checkbox" :id="'question-' + question.id" :value="question.id"
                                        v-model="selectedQuestions" />
                                    <label :for="'question-' + question.id">
                                        {{ truncateText(question.question, 300) }}
                                        <span v-if="question.question.length > 300" @click="showFullQuestion(question)">
                                            ... (View)
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div v-if="selectedAnalysisApproach === 'answerBase' && activeCustomizeTab === 'Question'"
                            class="answer-base-analysis">
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Questions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="question in questions" :key="question.id">
                                        <tr>
                                            <td @click="toggleExpand(question.id)">
                                                <button class="toggle-btn"
                                                    :class="{ 'expanded': question.expanded, 'plus': !question.expanded, 'minus': question.expanded }">
                                                    {{ question.expanded ? '-' : '+' }}
                                                </button>
                                            </td>
                                            <td>{{ question.question || 'Question not available' }}</td>
                                        </tr>
                                        <tr v-if="question.expanded">
                                            <td colspan="2">
                                                <div class="response-selection">
                                                    <label v-for="response in visibleResponses[question.id]"
                                                        :key="response.id" class="response-option">
                                                        <input type="radio" :name="'response-' + question.id"
                                                            :value="response.id"
                                                            v-model="selectedResponses[question.id]" />
                                                        {{ response.response || 'Response not available' }}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>


            <div class="row">
                <div class="col" style="margin-top: 25px; margin-bottom: 50px;">
                    <div class="card">
                        <div class="content"></div>
                        <ul class="selected-items">
                            <li v-for="(district, districtId) in selectedDistricts" :key="districtId"
                                class="selected-item">
                                {{ district.name }}
                                <span v-if="district.healthCenters.length > 0">
                                    , {{ district.healthCenters.join(', ') }}
                                </span>
                                <button @click="removeDistrict(districtId)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg>
                                </button>
                            </li>

                            <li v-for="tool in selectedToolsDetails" :key="tool.id" class="selected-item">
                                {{ tool.name }}
                                <button @click="removeTool(tool.id)"><svg width="18" height="18" viewBox="0 0 18 18"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                            <li v-for="condition in selectedConditionsDetails" :key="condition.id"
                                class="selected-item">
                                {{ condition.name }}
                                <button @click="removeCondition(condition.id)"><svg width="18" height="18"
                                        viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                            <li v-for="question in selectedQuestionsDetails" :key="question.id" class="selected-item">
                                {{ question.question }}
                                <div v-if="selectedAnalysisApproach === 'answerBase' && question.selectedResponse">
                                    <p><b>: {{ getResponseTextById(question.id, question.selectedResponse)
                                            }}</b></p>
                                </div>
                                <button @click="removeQuestionWithResponse(question.id)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg>
                                </button>
                            </li>


                            <li v-for="urgency in selectedUrgency" :key="urgency" class="selected-item">
                                {{ urgency }}
                                <button @click="removeUrgency(urgency)"><svg width="18" height="18" viewBox="0 0 18 18"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                        </ul>

                        <div class="button-container">
                            <div class="left">
                                <button @click="resetSelections" class="btn-cancel">Cancel</button>
                                <button @click="previewCharts" class="btn-preview">Preview</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-bottom: 20px;"></div>
            </div>
        </div>

        <div v-else class="row">
            <div :class="['col-3', 'sidebar', { 'sidebar-wide': activeCustomizeTab === 'Question' }]">
                <div class="content">
                    <div v-if="activeCustomizeTab === 'Analysis'" class="customize-tab">
                        <div class="analysis-options">
                            <label class="analysis-option">
                                <input type="radio" value="questionBase" v-model="selectedAnalysisApproach" />
                                <span class="custom-radio"></span>
                                <span>Question Base Analysis Tool</span>
                            </label>
                            <label class="analysis-option">
                                <input type="radio" value="answerBase" v-model="selectedAnalysisApproach" />
                                <span class="custom-radio"></span>
                                <span>Answer Base Analysis</span>
                            </label>
                        </div>
                    </div>
                    <div v-if="activeCustomizeTab === 'Locations'">
                        <div class="container">
                            <ul class="tree">
                                <li v-for="province in provinces" :key="province.id" class="province">
                                    <button class="accordion" @click.prevent="toggleProvince(province.id)">
                                        <span v-if="expandedProvinces.includes(province.id)">-</span>
                                        <span v-else>+</span>
                                        {{ province.name }}
                                    </button>
                                    <ul v-show="expandedProvinces.includes(province.id)" class="panel">
                                        <li v-if="loadingDistricts[province.id]" class="loading">Loading...</li>
                                        <li v-else v-for="district in districtsByProvince[province.id]"
                                            :key="district.id">
                                            <a href="#" @click.prevent="selectDistrict(province.id, district.id)">
                                                {{ district.name }}
                                            </a>
                                            <ul v-if="selectedDistricts[district.id]" class="location-type-panel">
                                                <li>
                                                    <label for="locationType">Location Type:</label>

                                                    <select v-model="selectedLocationTypes[district.id]"
                                                        @change="fetchHealthCenters(province.id, district.id)">
                                                        <option value="" disabled>Select Type</option>
                                                        <option v-for="type in locationTypes" :key="type.id"
                                                            :value="type.id">
                                                            {{ type.name }}
                                                        </option>
                                                    </select>


                                                </li>
                                                <li v-if="loadingHealthCenters[district.id]">Loading ...
                                                </li>
                                                <li
                                                    v-else-if="healthCentersByProvince[province.id]?.[district.id]?.length">
                                                    <ul>
                                                        <li v-for="center in healthCentersByProvince[province.id][district.id]"
                                                            :key="center.id" class="health-center-item">
                                                            <input type="checkbox"
                                                                :id="'center-' + province.id + '-' + district.id + '-' + center.id"
                                                                :value="center.id" v-model="selectedHealthCenters"
                                                                @change="selectHealthCenter(district.id, center.id)" />
                                                            <label
                                                                :for="'center-' + province.id + '-' + district.id + '-' + center.id">
                                                                {{ center.health_center_name }}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li v-else>
                                                    <p>.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="activeCustomizeTab === 'Tools'" class="customize-tab">
                        <h4>Tools</h4>
                        <ul class="styled-list">
                            <li v-for="tool in tools" :key="tool.id" class="styled-list-item">
                                <input type="checkbox" :id="'tool-' + tool.id" :value="tool.id"
                                    v-model="selectedTools" />
                                <label :for="'tool-' + tool.id">{{ tool.name }}</label>
                            </li>
                        </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Condition'" class="customize-tab">
                        <h4>Conditions</h4>
                        <ul class="styled-list">
                            <li v-for="condition in conditions" :key="condition.id" class="styled-list-item">
                                <input type="checkbox" :id="'condition-' + condition.id" :value="condition.id"
                                    v-model="selectedConditions" />
                                <label :for="'condition-' + condition.id">{{ condition.name }}</label>
                            </li>
                        </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Question' && selectedAnalysisApproach !== 'answerBase'"
                        class="customize-tab customize-tab-question">
                        <ul class="styled-list">
                            <li v-for="question in questions" :key="question.id"
                                class="styled-list-item styled-list-item-question">
                                <input type="checkbox" :id="'question-' + question.id" :value="question.id"
                                    v-model="selectedQuestions" />
                                <label :for="'question-' + question.id">
                                    {{ truncateText(question.question, 50) }}
                                    <span v-if="question.question.length > 50" @click="showFullQuestion(question)">
                                        ... (View)
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div v-if="selectedAnalysisApproach === 'answerBase' && activeCustomizeTab === 'Question'"
                        class="answer-base-analysis">
                        <table class="styled-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="question in questions" :key="question.id">
                                    <tr>
                                        <td @click="toggleExpand(question.id)">
                                            <button class="toggle-btn"
                                                :class="{ 'expanded': question.expanded, 'plus': !question.expanded, 'minus': question.expanded }">
                                                {{ question.expanded ? '-' : '+' }}
                                            </button>
                                        </td>
                                        <td>{{ question.question || 'Question not available' }}</td>
                                    </tr>
                                    <tr v-if="question.expanded">
                                        <td colspan="2">
                                            <div class="response-selection">
                                                <label v-for="response in visibleResponses[question.id]"
                                                    :key="response.id" class="response-option">
                                                    <input type="radio" :name="'response-' + question.id"
                                                        :value="response.id" v-model="selectedResponses[question.id]" />
                                                    {{ response.response || 'Response not available' }}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="activeCustomizeTab === 'Type'" class="customize-tab">
                        <h4>Type</h4>
                        <ul class="styled-list">
                            <li class="styled-list-item">
                                <input type="radio" id="graph-type" value="hc_graph" v-model="selectedType" />
                                <label for="graph-type">Graph</label>
                            </li>
                            <li class="styled-list-item">
                                <input type="radio" id="table-type" value="table" v-model="selectedType" />
                                <label for="table-type">Table</label>
                            </li>
                        </ul>
                    </div>
                    <div v-if="activeCustomizeTab === 'Dates'" class="customize-tab">
                        <ul class="styled-list">
                            <li class="styled-list-item date-item">
                                <label for="from-date" class="date-label">From:</label>
                                <input type="date" id="from-date" v-model="from_date" class="date-input" />
                            </li>
                            <li class="styled-list-item date-item">
                                <label for="to-date" class="date-label">To:</label>
                                <input type="date" id="to-date" v-model="to_date" class="date-input" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col" style="margin-top: 20px;">
                <div class="card">
                    <div class="content">
                        <ul class="selected-items">
                            <li v-for="(district, districtId) in selectedDistricts" :key="districtId"
                                class="selected-item">
                                {{ district.name }}
                                <span v-if="district.healthCenters.length > 0">
                                    , {{ district.healthCenters.join(', ') }}
                                </span>
                                <button @click="removeDistrict(districtId)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg>
                                </button>
                            </li>

                            <li v-for="tool in selectedToolsDetails" :key="tool.id" class="selected-item">
                                {{ tool.name }}
                                <button @click="removeTool(tool.id)"><svg width="18" height="18" viewBox="0 0 18 18"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                            <li v-for="condition in selectedConditionsDetails" :key="condition.id"
                                class="selected-item">
                                {{ condition.name }}
                                <button @click="removeCondition(condition.id)"><svg width="18" height="18"
                                        viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                            <li v-for="question in selectedQuestionsDetails" :key="question.id" class="selected-item">
                                {{ question.question }}
                                <div v-if="selectedAnalysisApproach === 'answerBase' && question.selectedResponse">
                                    <p><b>: {{ getResponseTextById(question.id, question.selectedResponse)
                                            }}</b></p>
                                </div>
                                <button @click="removeQuestionWithResponse(question.id)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg>
                                </button>
                            </li>


                            <li v-for="urgency in selectedUrgency" :key="urgency" class="selected-item">
                                {{ urgency }}
                                <button @click="removeUrgency(urgency)"><svg width="18" height="18" viewBox="0 0 18 18"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2499 4.8075L13.1924 3.75L8.99992 7.9425L4.80742 3.75L3.74992 4.8075L7.94242 9L3.74992 13.1925L4.80742 14.25L8.99992 10.0575L13.1924 14.25L14.2499 13.1925L10.0574 9L14.2499 4.8075Z"
                                            fill="#44474F" />
                                    </svg></button>
                            </li>
                        </ul>
                        <div class="button-container">
                            <div class="left">
                                <button @click="resetSelections" class="btn-cancel">Cancel</button>
                                <button @click="previewCharts" class="btn-preview">Preview</button>
                                <!-- <button @click="createCharts" class="btn-create">Create</button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showChartModal" class="chart-preview-modal">
                    <div class="chart-preview-modal-content">
                        <span class="chart-preview-close" @click="closeChartModal">&times;</span>
                        <h3>Dashboard Preview</h3>
                        <div :class="['chart-preview-container', { 'table-mode': selectedType === 'table' }]">
                            <!-- Chart Preview -->
                            <div v-if="selectedType === 'hc_graph'">
                                <div v-for="(chart, index) in chartsDataForRender" :key="chart.id"
                                    class="chart-preview-canvas-container">
                                    <!-- Chart Header -->
                                    <div class="chart-header">
                                        <div class="chart-header-left">
                                            <div>
                                                <!-- Hardcode the "bar" value as default -->
                                                <select :value="chartTypes[chart.id] || 'bar'"
                                                    @change="updateChartType(chart.id, index, $event.target.value)">
                                                    <option value="bar">Bar</option>
                                                    <option value="line">Line</option>
                                                    <option value="doughnut">Doughnut</option>
                                                    <option value="funnel">Funnel</option>
                                                    <option value="polarBar">Polar and Angle</option>
                                                    <option value="stackedBar">Stacked Horizontal Bar</option>
                                                    <option value="radar">Basic Radar Chart</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="chart-header-right">
                                            <div class="remove-icon" @click="removeChart(chart.id, index)"
                                                title="Remove Chart">
                                                <i class="fas fa-trash"></i>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- Chart Container -->
                                    <div :id="'chart-' + chart.id" style="width: 100%; height: 300px;"></div>
                                </div>
                            </div>


                        </div>
                        <div class="table-preview-container">
                            <!-- Table Preview -->
                            <div class="table-container" v-if="selectedType === 'table'">
                                <p v-if="!tableData.length">No data available for the table.</p> <!-- Add this -->
                                <table v-else>
                                    <thead>
                                        <tr>
                                            <th>Condition</th>
                                            <th>Question</th>
                                            <th>Answers</th>
                                            <th>District</th>
                                            <th>Area</th>
                                            <th v-for="center in healthCenterNames" :key="center">{{ center }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(question, qIndex) in tableData" :key="qIndex">
                                            <template v-for="(response, rIndex) in question.responses" :key="rIndex">
                                                <tr>
                                                    <td v-if="rIndex === 0"
                                                        :rowspan="getTotalRowspan(question.responses)">
                                                        {{ question.condition }}
                                                    </td>
                                                    <td v-if="rIndex === 0"
                                                        :rowspan="getTotalRowspan(question.responses)">
                                                        {{ question.question }}
                                                    </td>
                                                    <td :rowspan="response.health_centers.length">
                                                        {{ response.response }}
                                                    </td>
                                                    <td>{{ response.health_centers[0].district }}</td>
                                                    <td>{{ response.health_centers[0].location }}</td>
                                                    <td v-for="center in healthCenterNames" :key="center">
                                                        {{ getHealthCenterCount(response.health_centers, center,
                                                            response.health_centers[0].health_center) }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="chart-preview-modal-actions">
                            <button @click="closeChartModal" class="btn-cancel">Close</button>
                            <div class="right">
                                <button @click="saveDashboard" class="btn-save">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.75 3H8.25V8.25002L3 8.24999V9.74999L8.25 9.75002V15H9.75V9.75002H15V8.25002L9.75 8.24999V3Z"
                                            fill="white" />
                                    </svg>
                                    <span>Save Dashboard</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isNameModalOpen" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeNameModal">&times;</span>
                <h3>Name Your Dashboard</h3>
                <input type="text" v-model="dashboardName" placeholder="Enter dashboard name" required />
                <div class="modal-actions">
                    <button class="save" @click="saveDashboardWithName">Save</button>
                    <button class="cancel" @click="closeNameModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { mapGetters } from "vuex";
import axiosRetry from "axios-retry";
import * as echarts from "echarts";
import VueDraggableResizable from "vue3-draggable-resizable";
import { useToast } from "vue-toastification";
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/radar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/funnel';

const Toast = useToast();

axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => retryCount * 2000,
    retryCondition: (error) => error.response && error.response.status === 429,
});
export default {
    components: {
        VueDraggableResizable,
    },
    data() {
        return {
            expandedProvinces: [],
            loadingDistricts: {},
            loadingHealthCenters: {},
            locationTypes: [
                { id: 1, name: "Rural" },
                { id: 2, name: "Urban" },
            ],
            healthCentersByProvince: {},
            selectedLocationTypes: {},
            selectedHealthCenters: [],
            selectedAnalysisApproach: null,
            activeTab: "Main",
            activeCustomizeTab: "Analysis",
            username: localStorage.getItem("fullname"),
            userId: JSON.parse(localStorage.getItem("user")).id,
            dashboards: [],
            provinces: [],
            districtsByProvince: {},
            selectedDistricts: {},
            selectedType: '',
            tools: [],
            selectedTools: [],
            conditions: [],
            selectedConditions: [],
            questions: [],
            selectedQuestions: [],
            fullQuestion: "",
            showModal: false,
            chartsData: {
                questions: [],
            },
            tableData: [],
            healthCenterNames: [],
            isNameModalOpen: false,
            dashboardName: "",
            chartsDataForRender: [],
            chartInstances: {},
            chartSizes: {},
            chartTypes: {},
            visibleResponses: {},
            selectedResponses: {},
            from_date: null,
            to_date: null,
            showChartModal: false,
        };
    },
    watch: {
        showChartModal(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    // Wait for the transition to complete before rendering
                    setTimeout(() => {
                        this.renderCharts();
                    }, 300); // Adjust delay to match your transition duration
                });
            } else {
                this.destroyCharts();
            }
        },
        chartsDataForRender: {
            immediate: true,
            handler(newData) {
                // Watch changes to chart data and initialize chart types
                this.initializeChartTypes();
            },
        },
    },
    mounted() {
        this.fetchProvinces();
        this.fetchTools();
        this.fetchConditions();
        this.fetchDashboards();
        this.initializeChartTypes();

        this.$nextTick(() => {
            console.log("Initializing chartTypes...");
            this.chartsData.questions.forEach((question) => {
                if (!this.chartTypes[question.id]) {
                    this.$set(this.chartTypes, question.id, "bar");
                }
            });
            console.log("Initialized chartTypes:", this.chartTypes);
            this.renderCharts();
        });

        window.addEventListener("resize", this.handleWindowResize);
        const card = this.$refs.scrollableCard;
        if (card) card.scrollTop = 0;
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleWindowResize);
        this.destroyCharts();
    },

    methods: {
        async fetchResponses(questionId) {
            try {
                const response = await axios.get(`/question-responses/${questionId}`);
                console.log(response.data.data);
                if (response.data.status) {
                    this.visibleResponses = {
                        ...this.visibleResponses,
                        [questionId]: response.data.data
                    };
                } else {
                    console.error("Unexpected response:", response);
                }
            } catch (error) {
                console.error("Error fetching responses:", error);
            }
        },
        handleWindowResize() {
            Object.values(this.chartInstances).forEach((chartInstance) => {
                if (chartInstance) {
                    chartInstance.resize();
                }
            });
        },
        initializeChartTypes() {
            // Ensure `chartTypes` is initialized for each chart in `chartsDataForRender`
            this.chartsDataForRender.forEach((chart) => {
                if (!this.chartTypes[chart.id]) {
                    this.$set(this.chartTypes, chart.id, "bar"); // Default to "bar"
                }
            });
        },
        updateChartType(chartId, index, type) {
            console.log(`Updating chartType for chart ${chartId} to:`, type);
            this.chartTypes[chartId] = type;
            this.renderChart(chartId, index);
        },

        getQuestionTextById(questionId) {
            const question = this.questions.find(q => q.id === questionId);
            return question ? question.question : "Unknown Question";
        },

        getResponseTextById(questionId, responseId) {
            const responses = this.visibleResponses[questionId];
            if (responses) {
                const response = responses.find(r => r.id === responseId);
                return response ? response.response : "Unknown Response";
            }
            return "No Responses";
        },

        toggleExpand(questionId) {
            const question = this.questions.find(q => q.id === questionId);
            if (question) {
                if (!question.expanded) {
                    this.fetchResponses(questionId);
                    if (!this.selectedQuestions.includes(questionId)) {
                        this.selectedQuestions.push(questionId);
                    }
                }
                question.expanded = !question.expanded;
            }
        },
        isResponseVisible(questionId) {
            return !!this.visibleResponses[questionId];
        },
        selectResponse(questionId, responseId) {
            if (!this.selectedQuestions.includes(questionId)) {
                this.selectedQuestions.push(questionId);
            }
            this.$set(this.selectedResponses, questionId, responseId);
        },
        removeQuestionWithResponse(questionId) {
            this.selectedQuestions = this.selectedQuestions.filter(id => id !== questionId);

            if (this.selectedResponses[questionId]) {
                delete this.selectedResponses[questionId];
            }
        },
        getSelectedHealthCenters(districtId) {
            return this.selectedDistricts[districtId]?.healthCenters || [];
        },
        async fetchProvinces() {
            try {
                const response = await axios.get("/province");
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts(provinceId) {
            this.loadingDistricts = { ...this.loadingDistricts, [provinceId]: true };
            try {
                const response = await axios.get(`/districts/${provinceId}`);
                this.districtsByProvince = { ...this.districtsByProvince, [provinceId]: response.data.data };
                this.loadingDistricts = { ...this.loadingDistricts, [provinceId]: false };
            } catch (error) {
                console.error("Error fetching districts:", error);
                this.loadingDistricts = { ...this.loadingDistricts, [provinceId]: false };
            }
        },
        async fetchHealthCenters(provinceId, districtId) {
            if (this.selectedLocationTypes[districtId] && districtId) {
                this.loadingHealthCenters = { ...this.loadingHealthCenters, [districtId]: true };
                try {
                    const response = await axios.get(
                        `/health-center/${districtId}/${this.selectedLocationTypes[districtId]}`
                    );
                    if (!this.healthCentersByProvince[provinceId]) {
                        this.healthCentersByProvince = { ...this.healthCentersByProvince, [provinceId]: {} };
                    }
                    this.healthCentersByProvince[provinceId] = {
                        ...this.healthCentersByProvince[provinceId],
                        [districtId]: response.data.data,
                    };
                    this.loadingHealthCenters = { ...this.loadingHealthCenters, [districtId]: false };
                } catch (error) {
                    console.error("Error fetching health centers:", error);
                    this.loadingHealthCenters = { ...this.loadingHealthCenters, [districtId]: false };
                }
            }
        },
        toggleProvince(provinceId) {
            if (this.expandedProvinces.includes(provinceId)) {
                this.expandedProvinces = this.expandedProvinces.filter(id => id !== provinceId);
            } else {
                this.expandedProvinces.push(provinceId);
                this.fetchDistricts(provinceId);
            }
        },
        selectDistrict(provinceId, districtId) {
            if (!this.selectedDistricts[districtId]) {
                this.selectedDistricts = {
                    ...this.selectedDistricts,
                    [districtId]: {
                        provinceId,
                        name: this.getDistrictName(provinceId, districtId),
                        healthCenters: []
                    }
                };
                this.selectedLocationTypes = { ...this.selectedLocationTypes, [districtId]: '' };
                this.fetchHealthCenters(provinceId, districtId);
            }
        },
        selectHealthCenter(districtId, centerId) {
            const centerName = this.getHealthCenterName(districtId, centerId);
            if (centerName && this.selectedDistricts[districtId]) {
                const centerIndex = this.selectedDistricts[districtId].healthCenters.indexOf(centerName);
                if (centerIndex === -1) {
                    this.selectedDistricts[districtId].healthCenters.push(centerName);
                } else {
                    this.selectedDistricts[districtId].healthCenters.splice(centerIndex, 1);
                }
                this.$forceUpdate(); // Trigger re-render
            }
        },
        getDistrictName(provinceId, districtId) {
            const district = this.districtsByProvince[provinceId]?.find(d => d.id === districtId);
            return district ? district.name : '';
        },
        getHealthCenterName(districtId, centerId) {
            const provinceId = this.selectedDistricts[districtId]?.provinceId;
            const center = this.healthCentersByProvince[provinceId]?.[districtId]?.find(c => c.id === centerId);
            return center ? center.health_center_name : '';
        },
        fetchConditions() {
            axios
                .get("/conditions")
                .then((response) => {
                    if (response.data.status) {
                        this.conditions = response.data.data;
                    }
                })
                .catch((error) => {
                    console.error("Error fetching conditions:", error);
                });
        },
        fetchQuestions() {
            axios.post('/customized-questions', {
                tool_id: this.selectedTools,
                condition_id: this.selectedConditions,
                district_id: Object.keys(this.selectedDistricts),
                health_center_id: Object.values(this.selectedHealthCenters)
            })
                .then(response => {
                    if (response.data.status) {
                        this.questions = response.data.data || [];
                        console.log('Fetched Questions:', this.questions);
                    }
                })
                .catch(error => {
                    console.error("Error fetching questions:", error);
                });
        },
        fetchTools() {
            axios
                .get("/tools")
                .then((response) => {
                    if (response.data.status) {
                        this.tools = response.data.data;
                    }
                })
                .catch((error) => {
                    console.error("Error fetching tools:", error);
                });
        },
        fetchCount(tool, toolId) {
            let selectedProvince = "";
            let selectedDistrict = "";
            if (tool === "patientTool") {
                selectedProvince = this.patientToolProvince;
                selectedDistrict = this.patientToolDistrict;
            } else if (tool === "communityBased") {
                selectedProvince = this.communityBasedProvince;
                selectedDistrict = this.communityBasedDistrict;
            } else if (tool === "healthFacility") {
                selectedProvince = this.healthFacilityProvince;
                selectedDistrict = this.healthFacilityDistrict;
            } else if (tool === "serviceProvider") {
                selectedProvince = this.serviceProviderProvince;
                selectedDistrict = this.serviceProviderDistrict;
            }
            const requestData = {
                tool_id: toolId,
            };
            if (selectedProvince) {
                requestData.province_id = selectedProvince;
            }
            if (selectedDistrict) {
                requestData.district_id = selectedDistrict;
            }
            axios
                .post("admin/count-participants", requestData)
                .then((response) => {
                    if (response.status === 200 && response.data.status) {
                        if (tool === "patientTool") {
                            this.patientToolCount = response.data.data.count;
                        } else if (tool === "communityBased") {
                            this.communityBasedCount = response.data.data.count;
                        } else if (tool === "healthFacility") {
                            this.healthFacilityCount = response.data.data.count;
                        } else if (tool === "serviceProvider") {
                            this.serviceProviderCount = response.data.data.count;
                        }
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error("Error fetching participant count:", error.response.data);
                    } else {
                        console.error("Error fetching participant count:", error.message);
                    }
                });
        },
        truncateText(text, length) {
            return text.length > length ? text.slice(0, length) : text;
        },
        showFullQuestion(question) {
            this.fullQuestion = question?.question || 'Full question not available';
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.fullQuestion = "";
        },
        removeDistrict(districtId) {
            const { [districtId]: _, ...newSelectedDistricts } = this.selectedDistricts;
            this.selectedDistricts = newSelectedDistricts;
        },
        removeTool(toolId) {
            this.selectedTools = this.selectedTools.filter((id) => id !== toolId);
        },
        removeCondition(conditionId) {
            this.selectedConditions = this.selectedConditions.filter((id) => id !== conditionId);
        },
        removeQuestion(questionId) {
            this.selectedQuestions = this.selectedQuestions.filter((id) => id !== questionId);
        },
        resetSelections() {
            this.selectedDistricts = {};
            this.selectedTools = [];
            this.selectedConditions = [];
            this.selectedQuestions = [];
            this.selectedHealthCenters = {};
        },
        openNameModal() {
            this.isNameModalOpen = true;
        },
        closeNameModal() {
            this.isNameModalOpen = false;
            this.dashboardName = "";
        },
        saveDashboard() {
            this.showChartModal = false;
            this.openNameModal();
        },
        saveDashboardWithName() {
            if (!this.dashboardName) {
                Toast.error("Please enter a name for the dashboard.");
                return;
            }
            if (!this.selectedTools.length || !this.selectedConditions.length || !Object.keys(this.selectedDistricts).length || !Object.keys(this.selectedHealthCenters).length) {
                Toast.error("Please select tools, conditions, districts, and health centers before saving the dashboard.");
                return;
            }

            const formattedChartTypes = JSON.stringify(this.chartTypes);

            const dashboardData = {
                user_id: this.userId,
                name: this.dashboardName,
                tool_id: this.selectedTools,
                condition_id: this.selectedConditions,
                district_id: Object.keys(this.selectedDistricts),
                health_center_id: Object.values(this.selectedHealthCenters),
                questions: this.selectedQuestions,
                charts_data: this.chartsData,
                chart_sizes: this.chartSizes,
                chart_types: formattedChartTypes,
                type: this.selectedType
            };

            axios
                .post("/save-dashboard", dashboardData)
                .then((response) => {
                    if (response.data.status) {
                        this.closeNameModal();
                        Toast.success("Dashboard saved successfully");
                        // this.fetchDashboardsAndActivate(response.data.data.id);
                        window.location.reload();
                        // window.location.href = window.location.href;
                    } else {
                        console.error("Unexpected response:", response);
                        Toast.error("Failed to save dashboard. Please try again.");
                    }
                })
                .catch((error) => {
                    console.error("Error saving dashboard:", error);
                    // Toast.error("An error occurred while saving the dashboard.");
                });
        },

        fetchDashboardsAndActivate(newDashboardId) {
            axios
                .get(`/dashboards?user_id=${this.userId}`)
                .then((response) => {
                    if (response.data.status) {
                        this.dashboards = response.data.data;
                        this.activeTab = 'Dashboard-' + newDashboardId;
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching dashboards:", error);
                    // Toast.error("An error occurred while fetching dashboards.");
                });
        },
        fetchDashboards() {
            axios
                .get(`/dashboards?user_id=${this.userId}`)
                .then((response) => {
                    if (response.data.status) {
                        this.dashboards = response.data.data;
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching dashboards:", error);
                });
        },
        loadDashboard(dashboardId) {
            axios
                .get(`/dashboard/${dashboardId}`)
                .then((response) => {
                    if (response.data.status) {
                        const data = response.data.data;
                        this.selectedTools = data.tool_id;
                        this.selectedConditions = data.condition_id;
                        this.selectedDistricts = data.district_id.reduce((result, districtId) => {
                            result[districtId] = true;
                            return result;
                        }, {});
                        this.selectedHealthCenters = data.health_center_id.reduce((result, centerId) => {
                            result[centerId] = true;
                            return result;
                        }, {});
                        this.selectedQuestions = data.questions;
                        this.chartsData = data.charts_data;
                        this.chartSizes = data.chart_sizes || {};
                        this.chartTypes = data.chart_types || {};
                        this.activeTab = "Dashboard-" + dashboardId;
                        this.destroyCharts();
                        this.renderCharts();
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error loading dashboard:", error);
                });
        },

        previewCharts() {
            const requestData = {
                tool_id: this.selectedTools,
                condition_id: this.selectedConditions,
                district_id: Object.keys(this.selectedDistricts),
                health_center_id: Object.values(this.selectedHealthCenters),
                questions: this.selectedQuestions,
            };

            axios.post("health-center/questions-with-answers-chart", requestData)
                .then((response) => {
                    if (response.data.status) {
                        this.chartsData.questions = response.data.data;

                        // Transform the data for rendering
                        this.chartsDataForRender = response.data.data.map((question, index) => ({
                            id: question.id || `chart-${index}`, // Ensure a unique ID
                            question: question.question,
                            responses: question.responses.map((response) => ({
                                response: response.response,
                                health_centers: response.health_centers.map((center) => ({
                                    health_center: center.health_center,
                                    count: center.count,
                                })),
                            })),
                        }));

                        this.showChartModal = true;
                        this.$nextTick(() => this.renderCharts());
                    } else {
                        console.warn("No chart data available:", response.data.message);
                    }
                })
                .catch((error) => console.error("Error fetching chart data:", error));
        },
        closeChartModal() {
            this.showChartModal = false;
        },
        createCharts() {
            const requestData = {
                tool_id: this.selectedTools,
                condition_id: this.selectedConditions,
                district_id: Object.keys(this.selectedDistricts),
                health_center_id: Object.values(this.selectedHealthCenters),
                questions: this.selectedQuestions,
                response_options: Object.values(this.selectedResponses),
            };

            const endpoint = this.selectedAnalysisApproach === 'answerBase'
                ? (this.selectedType === 'hc_graph' ? "/health-center/answer-base-anaylsis-chart" : "/health-center/answer-based-table")
                : (this.selectedType === 'hc_graph' ? "/health-center/questions-with-answers-chart" : "/questions-with-answers-table");

            console.log("Request Data:", requestData);
            console.log("Endpoint:", endpoint);

            axios.post(endpoint, requestData)
                .then(response => {
                    console.log("Response Data:", response.data);
                    if (response.data.status) {
                        if (this.selectedType === "table") {
                            this.tableData = response.data.data || [];
                            console.log("Populated tableData:", this.tableData);

                            this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
                            console.log("Extracted healthCenterNames:", this.healthCenterNames);
                        } else if (this.selectedType === "hc_graph") {
                            this.chartsData.questions = response.data.data;
                            this.renderCharts();
                        }
                    } else {
                        console.warn("No responses found:", response.data.message);
                        this.clearCharts();
                    }
                })
                .catch(error => {
                    console.error("Error creating charts:", error);
                });
        },
        clearCharts() {
            if (this.selectedType === 'hc_graph') {
                this.chartsData.questions = [];
                this.renderCharts();
            } else {
                this.tableData = [];
            }
        },

        destroyCharts() {
            Object.keys(this.chartInstances).forEach((key) => {
                if (this.chartInstances[key]) {
                    this.chartInstances[key].destroy();
                    delete this.chartInstances[key];
                }
            });
        },
        renderCharts() {
            this.$nextTick(() => {
                this.chartsDataForRender.forEach((chart, index) => {
                    this.renderChart(chart.id, index);
                });
            });
        },

        renderChart(chartId, index) {
            const chartContainer = document.getElementById(`chart-${chartId}`);
            if (!chartContainer) {
                console.error(`Chart container not found for chart-${chartId}`);
                return;
            }

            // Dispose of previous chart instance
            if (this.chartInstances[chartId]) {
                this.chartInstances[chartId].dispose();
            }

            const chartInstance = echarts.init(chartContainer);
            const questionData = this.chartsDataForRender[index];

            if (!questionData || !Array.isArray(questionData.responses)) {
                console.error(`Invalid or missing data for chart ID ${chartId}`);
                return;
            }

            const healthCenters = Array.from(
                new Set(
                    questionData.responses.flatMap((response) =>
                        response.health_centers?.map((center) => center.health_center) || []
                    )
                )
            );

            const chartType = this.chartTypes[chartId] || 'bar'; // Default to bar
            const seriesData = this.prepareSeriesData(chartType, questionData.responses, healthCenters);

            if (!seriesData.length) {
                console.error(`Series data is empty for chart ID ${chartId}`);
                return;
            }

            const options = this.getChartOptions(chartType, {
                question: questionData.question,
                healthCenters,
                seriesData,
            });

            chartInstance.setOption(options);
            this.chartInstances[chartId] = chartInstance;
        },
        prepareSeriesData(chartType, responses, healthCenters) {
            responses = responses || [];
            healthCenters = healthCenters || [];

            switch (chartType) {
                case 'bar':
                case 'line':
                case 'polarBar':
                case 'stackedBar':
                    return responses.map((response) => ({
                        name: response.response || 'Unnamed Response',
                        type: chartType === 'polarBar' ? 'bar' : chartType,
                        stack: chartType === 'stackedBar' ? 'total' : undefined,
                        data: healthCenters.map((centerName) => {
                            const center = response.health_centers?.find(
                                (center) => center.health_center === centerName
                            );
                            return center ? center.count : 0;
                        }),
                    }));
                case 'doughnut': // Special data preparation for doughnut
                    return responses.map((response) => ({
                        value: response.health_centers?.reduce((sum, center) => sum + center.count, 0) || 0,
                        name: response.response || 'Unnamed Response',
                    }));
                case 'radar':
                    return [
                        {
                            value: responses.map((response) =>
                                response.health_centers?.reduce((sum, center) => sum + center.count, 0) || 0
                            ),
                            name: 'Responses',
                        },
                    ];
                default:
                    return [];
            }
        },

        removeChart(chartId, index) {
            if (confirm("Are you sure you want to remove this chart?")) {
                if (this.chartInstances[chartId]) {
                    this.chartInstances[chartId].dispose();
                    delete this.chartInstances[chartId];
                }

                if (Array.isArray(this.chartsDataForRender) && index >= 0) {
                    this.chartsDataForRender.splice(index, 1);
                } else {
                    console.error("chartsDataForRender is not reactive or index is invalid");
                }

                if (this.chartsData.questions) {
                    this.chartsData.questions = this.chartsData.questions.filter(
                        (chart) => chart.id !== chartId
                    );
                }

                delete this.chartTypes[chartId];

                console.log("Updated chartsDataForRender:", this.chartsDataForRender);
            }
        },

        getChartOptions(type, { question, healthCenters, seriesData }) {
            const baseOptions = {
                title: {
                    text: question || "No Question Available",
                    left: 'center',
                    top: '10',
                    textStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        lineHeight: 20,
                    },
                },
                tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                legend: {
                    data: seriesData.map((series) => series.name || 'Unnamed Series'),
                    top: '40',
                    left: 'center',
                },
                grid: {
                    top: '80',
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true,
                },
            };

            switch (type) {
                case 'bar':
                    return {
                        ...baseOptions,
                        xAxis: { type: 'category', data: healthCenters, axisLabel: { rotate: 45 } },
                        yAxis: { type: 'value', name: 'Count' },
                        series: seriesData.map((s) => ({
                            ...s,
                            type: 'bar', // Explicitly set to bar for bar charts
                            itemStyle: {
                                borderRadius: [10, 10, 0, 0], // Rounded corners for bar chart
                            },
                        })),
                    };

                case 'line':
                    return {
                        ...baseOptions,
                        xAxis: { type: 'category', data: healthCenters, axisLabel: { rotate: 45 } },
                        yAxis: { type: 'value', name: 'Count' },
                        series: seriesData.map((s) => ({
                            ...s,
                            type: 'line', // Explicitly set to line for line charts
                            smooth: true, // Optional: Smooth the line
                            lineStyle: {
                                width: 2, // Line thickness
                            },
                        })),
                    };

                case 'stackedBar':
                    return {
                        ...baseOptions,
                        xAxis: { type: 'category', data: healthCenters, axisLabel: { rotate: 45 } },
                        yAxis: { type: 'value', name: 'Count' },
                        series: seriesData.map((s) => ({
                            ...s,
                            type: 'bar',
                            itemStyle: {
                                borderRadius: [10, 10, 0, 0],
                            },
                        })),
                    };
                case 'polarBar':
                    return {
                        ...baseOptions,
                        tooltip: { trigger: 'item', formatter: '{b}: {c}' },
                        angleAxis: { type: 'category', data: healthCenters },
                        radiusAxis: {},
                        polar: {},
                        series: seriesData.map((s) => ({
                            ...s,
                            coordinateSystem: 'polar', // Essential for polar charts
                        })),
                    };
                case 'radar':
                    return {
                        ...baseOptions,
                        tooltip: { trigger: 'item' },
                        radar: {
                            indicator: healthCenters.map((hc) => ({
                                name: hc,
                                max: Math.max(...seriesData[0]?.value || [0]),
                            })),
                        },
                        series: seriesData.map((s) => ({
                            ...s,
                            type: 'radar',
                        })),
                    };
                case 'funnel':
                    return {
                        ...baseOptions,
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}: {c}', // Tooltip format for funnel
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                            top: 'middle',
                            formatter: (name) => {
                                // Append health center to the legend name
                                const dataItem = seriesData.find((item) => item.name === name);
                                if (dataItem && dataItem.healthCenters) {
                                    return `${name} - ${dataItem.healthCenters.join(', ')}`;
                                }
                                return name;
                            },
                            data: seriesData.map((s) => s.name || 'Unnamed Series'),
                        },
                        series: [
                            {
                                name: 'Health Center Data',
                                type: 'funnel',
                                left: '10%',
                                top: '10%',
                                bottom: '10%',
                                width: '80%',
                                min: 0,
                                max: Math.max(...seriesData.map((d) => d.value)), // Dynamically set the max value
                                data: seriesData.map((s) => ({
                                    name: s.name,
                                    value: s.value,
                                    healthCenters: s.healthCenters || [], // Include health center info in each data point
                                })),
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: '{b}: {c}', // Show response and value inside funnel
                                },
                                labelLine: {
                                    show: false,
                                },
                            },
                        ],
                    };


                case 'doughnut':
                    return {
                        ...baseOptions,
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}: {c} ({d}%)', // Tooltip format for hover
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left',
                            top: 'middle',
                            formatter: (name) => {
                                // Append health center to the legend name
                                const dataItem = seriesData.find((item) => item.name === name);
                                if (dataItem && dataItem.healthCenters) {
                                    return `${name} - ${dataItem.healthCenters.join(', ')}`;
                                }
                                return name;
                            },
                            data: seriesData.map((s) => s.name || 'Unnamed Series'),
                        },
                        series: [
                            {
                                name: 'Health Center Data',
                                type: 'pie',
                                radius: ['40%', '70%'], // Inner and outer radius for doughnut
                                center: ['50%', '55%'], // Center positioning
                                data: seriesData.map((s) => ({
                                    name: s.name,
                                    value: s.value,
                                    healthCenters: s.healthCenters || [], // Include health center info in each data point
                                })),
                                label: {
                                    show: false, // Hide labels by default
                                },
                                emphasis: {
                                    label: {
                                        show: true, // Show label only on hover
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        formatter: '{b}\n{c} ({d}%)', // Dynamic label format
                                    },
                                },
                                labelLine: {
                                    show: false, // Hide label lines
                                },
                            },
                        ],
                    };



                // Other chart types remain as is
                default:
                    return baseOptions;
            }
        },

        destroyCharts() {
            Object.keys(this.chartInstances).forEach((key) => {
                if (this.chartInstances[key]) {
                    this.chartInstances[key].dispose();
                    delete this.chartInstances[key];
                }
            });
        },

        extractHealthCenterNames(data) {
            const healthCenterNamesSet = new Set();
            data.forEach(question => {
                question.responses.forEach(response => {
                    response.health_centers.forEach(center => {
                        healthCenterNamesSet.add(center.health_center);
                    });
                });
            });
            return Array.from(healthCenterNamesSet);
        },
        getResponseRowspan(responses) {
            return responses.reduce((total, response) => total + response.health_centers.length, 0);
        },
        getHealthCenterCount(centers, centerName, currentCenterName) {
            if (currentCenterName === centerName) {
                const center = centers.find(center => center.health_center === centerName);
                return center ? center.count : 0;
            }
            return 0;
        },
        getTotalRowspan(responses) {
            return responses.reduce((total, response) => total + response.health_centers.length, 0);
        },
        getRowspan(responses) {
            return responses.reduce((total, response) => total + response.health_centers.length, 0);
        },
        confirmDelete(dashboardId) {
            if (confirm("Are you sure you want to delete this dashboard?")) {
                this.deleteDashboard(dashboardId);
            }
        },
        deleteDashboard(dashboardId) {
            axios
                .delete(`/dashboard/${dashboardId}`)
                .then((response) => {
                    if (response.data.status) {
                        this.fetchDashboards();
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error deleting dashboard:", error);
                });
        },
        onResizing(chartId, { width, height }) {
            console.log("Resizing in progress for chart:", chartId, "Current size:", width, height);
        },
        onResizeStop(chartId, { width, height }) {
            console.log("Resize stopped for chart:", chartId, "New size:", width, height);
            if (width && height) {
                this.chartSizes[chartId] = { width, height };
                console.log("Updated chartSizes:", JSON.stringify(this.chartSizes, null, 2));
            } else {
                console.error("Invalid width or height:", { width, height });
            }
        },
        getChartSize(chartId, dimension, defaultValue) {
            if (!this.chartSizes[chartId]) {
                this.chartSizes[chartId] = { width: 600, height: 300 };
            }
            const size = this.chartSizes[chartId][dimension];
            console.log(`Getting chart size for ${chartId} - ${dimension}:`, size);
            return size || defaultValue;
        },
        saveChartSettings() {
            console.log("Saving chart sizes:", this.chartSizes);
            axios
                .post(`/dashboard/${this.activeTab.split("-")[1]}/save-settings`, {
                    chart_sizes: this.chartSizes,
                    chart_types: this.chartTypes,
                })
                .then((response) => {
                    if (response.data.status) {
                        alert("Settings saved successfully");
                    } else {
                        console.error("Unexpected response:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error saving settings:", error);
                });
        },
    },
    computed: {
        ...mapGetters({
            hasPermission: "hasPermission",
        }),
        selectedDistrictsDetails() {
            return Object.values(this.selectedDistricts).map(district => {
                const centers = district.healthCenters.join(', ');
                return `${district.name}, ${centers}`;
            });
        },
        selectedToolsDetails() {
            return this.tools.filter((tool) => this.selectedTools.includes(tool.id));
        },
        selectedConditionsDetails() {
            return this.conditions.filter((condition) => this.selectedConditions.includes(condition.id));
        },
        selectedQuestionsDetails() {
            if (this.selectedAnalysisApproach === 'answerBase') {
                return this.questions.filter(question =>
                    this.selectedQuestions.includes(question.id) &&
                    this.selectedResponses[question.id]
                ).map(question => ({
                    ...question,
                    selectedResponse: this.selectedResponses[question.id]
                }));
            }
            return this.questions.filter(question => this.selectedQuestions.includes(question.id));
        },
    },
    watch: {
        selectedDistrict() {
            this.fetchHealthCenters();
        },
        selectedLocationType() {
            this.fetchHealthCenters();
        },
        selectedType(newType) {
            console.log("Selected type changed to:", newType);
            if (newType === 'table') {
                this.createCharts(); // Ensure charts are created for the table.
            }
        },
        selectedTools: "fetchQuestions",
        selectedConditions: "fetchQuestions",
        selectedDistricts: "fetchQuestions",
        selectedHealthCenters: "fetchQuestions",
    },
};
</script>


<style lang="scss" scoped>
:root {
    --color-blue: #4a90e2;
    --color-red: #ff6f61;
    --color-green: #50e3c2;
    --color-yellow: #f8e71c;
    --color-orange: #f5a623;
    --color-purple: #9013fe;
    --color-pink: #ff8c94;
    --color-teal: #4a9abf;
}

.location-item {
    width: 100%;

    button {
        width: 100%;
        padding: 10px;
    }

    .location-details {
        padding: 0 18px;
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
}

.accordion {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    border-radius: 8px;
    margin: 2px 0;
    height: auto;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.dashboard-container {
    height: 100%;
    padding: 36px 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .row {
        display: flex;
        gap: 24px;

        .col {
            flex: 1;

            h1 {
                color: var(--blue-original-2);
                font-size: 24px;
                line-height: 20px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .card {
                background-color: white;
                border-radius: 10px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: center;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                border: 1px solid #d0d5dd;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }

                .card-content {
                    width: 100%;

                    .c1 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .main-count {
                            background-color: var(--blue-light);
                            padding: 6px 12px;
                            border-radius: 30px;
                        }

                        h3 {
                            font-size: 14px;
                        }

                        p {
                            font-weight: regular;
                        }
                    }

                    h3 {
                        margin: 0;
                        font-size: 1.2em;
                        color: var(--blue-500);
                        font-weight: regular;
                    }

                    .count {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--blue-original);
                    }

                    select {
                        margin-top: 10px;
                        padding: 5px;
                        border-radius: 8px;
                        border: 1px solid #d0d5dd;
                        width: 100%;
                        font-size: 1em;
                        outline: none;
                        padding-left: 16px;
                        background-color: transparent;

                        &:focus {
                            border: 1px solid var(--blue-original);
                        }
                    }
                }
            }
        }

        .usersCol {
            width: 455px;
        }

        .WeeklyDiagnosis {
            width: 100%;
        }

        .tabs {
            width: 70%;
        }

        .questionCol {
            width: 60%;
            margin-top: 24px;
        }
    }

    .nav-tabs {
        display: flex;
        list-style: none;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
        border-radius: 8px;
        background-color: rgba(33, 72, 192, 10%);

        .nav-item {
            margin-bottom: -1px;
            font-size: 16px;
            font-weight: 600;
            padding: 8px 16px;
            text-align: center;

            .nav-link {
                display: block;
                padding: 10px 15px;
                color: #555;
                text-decoration: none;
                cursor: pointer;
                font-weight: 400;
                white-space: nowrap;

                &:hover {
                    color: #000;
                    background-color: #e9ecef;
                }

                &.active {
                    color: var(--blue-original);
                    background-color: #fff;
                    border-radius: 8px;
                    font-weight: bold;
                    border: 2px solid #e4e7ec;

                    a {
                        li {
                            padding: 12px;
                            border-bottom: 4px solid #2148c0;
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        padding-top: 20px;
        border-top: none;
    }

    .cards-row {
        margin-bottom: 30px;
    }

    .sidebar {
        width: 245px;
        padding: 8px;
        background-color: #f9fafb;
        border-radius: 8px;
        transition: width 0.3s ease;

        &.sidebar-wide {
            width: 545px;
        }

        .card {
            text-align: left;

            h3 {
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 10px;

                    a {
                        color: #007bff;
                        text-decoration: none;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    input[type="checkbox"] {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .selected-items {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        list-style: none;
        padding: 0;
        margin-bottom: 10px;
        align-items: center;
        padding-left: 6px;

        .selected-item {
            display: flex;
            align-items: center;
            padding: 4px;
            border-radius: 5px;
            margin-bottom: 10px;
            padding-left: 6px;
            border: 1px solid #e4e7ec;

            button {
                background: none;
                border: none;
                color: red;
                font-size: 16px;
                cursor: pointer;
                margin-left: 5px;
                margin-right: 6px;
                display: flex;
                justify-content: center;
            }
        }
    }

    .content {
        width: 100%;
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .btn-cancel {
        background-color: transparent;
        color: #ca150c;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #ca150c41;
        }
    }

    .btn-create {
        background-color: #2fab4f;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
    }

    .btn-save {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        margin-left: 30px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .btn-cancel:hover,
    .btn-create:hover,
    .btn-save:hover {
        opacity: 0.8;
    }

    .dashboard-item {
        display: flex;
        align-items: center;
    }

    .btn-delete {
        color: #ff0d0d;
        border: none;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50%;
    }

    .chart-container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .canvas-container {
            position: relative;

            .vue-draggable-resizable {
                background-color: #fff;
            }

            .card {
                background-color: white;
                border-radius: 10px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                border: 1px solid #d0d5dd;
                height: 100%;
                width: 100%;
                overflow: hidden;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }

                .chart-wrapper {
                    width: 100%;
                    height: 100%;

                    canvas {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }

    /* .chart-header {
        position: absolute;
        top: 10px;
        right: 10px;
        background: white;
        padding: 5px;
        z-index: 10;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    } */
    .chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .chart-header-left select {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    .chart-header-right .remove-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: #ffefef;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #ff4d4d;
    }

    .chart-header-right .remove-icon i {
        font-size: 18px;
        color: #ff4d4d;
    }

    .chart-header-right .remove-icon:hover {
        background-color: #ffcccc;
        transform: scale(1.1);
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        max-width: 600px;
        border-radius: 10px;
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .modal-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px;
        padding: 2px;

        button {
            padding: 5px 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 200ms ease;

            &.save {
                background-color: var(--blue-500);
                color: var(--white);
                padding: 10px;
                width: 100px;
                font-size: 14px;

                &:hover {
                    background-color: var(--blue-600);
                }
            }

            &.cancel {
                background-color: var(--gray);
                color: var(--white);
                padding: 10px;
                width: 100px;
                font-size: 14px;

                &:hover {
                    background-color: #504e74;
                }
            }
        }
    }

    input[type="text"] {
        width: 100%;
        padding: 10px;
        border: 1px solid var(--gray);
        border-radius: 5px;
    }
}

.container {
    position: relative;
    width: 100%;

    svg {
        background-color: white;
        position: absolute;
        top: 60%;
        right: 0;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

select {
    height: 40px;
    padding-left: 15px;
    font-size: 16px;
    border: 1.9px solid lightgray;
    border-radius: 4px;
    color: #39325a;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-color: transparent;
}

.sub-tabs {
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 8px;

    .active {
        li {
            padding: 12px;
            border-bottom: 4px solid #2148c0;
        }

        .sub-link {
            display: flex;
            align-items: center;
            gap: 10px;

            .analysisIcon {
                path {
                    fill: #1c274c;
                    fill-opacity: 1;
                }
            }

            .locationIcon {
                path {
                    fill: #1c274c;
                    fill-opacity: 1;
                }
            }

            .toolsIcon {
                path {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }

                circle {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }
            }

            .urgencyIcon {
                path {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }

                circle {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }
            }

            .conditionIcon {
                path {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }

                circle {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }
            }

            .questionsIcon {
                path {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }

                circle {
                    stroke: #1c274c;
                    stroke-opacity: 1;
                }
            }

            .statusIcon {
                path {
                    fill: #1c274c;
                    fill-opacity: 1;
                }
            }

            .dateIcon {
                path {
                    fill: #1c274c;
                    fill-opacity: 1;
                }
            }

            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #0a2472;
            }
        }
    }

    .sub-link {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #d7d8da;
        }
    }

    a {
        text-decoration: none;

        li {
            padding: 12px;
            border-bottom: 4px solid transparent;
        }
    }

    li {
        min-width: 160px;
    }
}

.province {
    width: 100%;
    margin-bottom: 10px;

    .accordion {
        width: 100%;
        padding: 12px;
        background: #f9fafb;
        color: #444;
        cursor: pointer;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        text-align: left;
        outline: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #e9ecef;
        }

        .icon {
            display: flex;
            align-items: center;
            margin-left: auto;

            svg {
                transition: transform 0.3s ease;
            }
        }
    }

    .panel {
        padding: 3px 0px 195px 25px;
        background-color: #f9fafb;
        border-radius: 8px;
        margin-top: 8px;

        ul {
            list-style: none;
            padding: 0;

            li {
                margin: 8px 0;
                display: flex;
                align-items: center;
                flex-direction: column;

                select {
                    width: 100%;
                    height: 40px;
                    padding-left: 15px;
                    font-size: 16px;
                    border: 1.9px solid lightgray;
                    border-radius: 4px;
                    color: #39325a;
                    outline: none;
                    appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    background-color: transparent;
                    margin-top: 10px;
                }
            }

            .loading {
                text-align: center;
                padding: 10px;
                color: #888;
            }
        }
    }
}

.dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    margin: 0;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 100;
    border-radius: 4px;
    padding: 10px;

    a {
        padding: 5px 10px;
        text-decoration: none;
        color: #444;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}

.save-change-button {
    height: 48px;
    width: 151px;
    border-radius: 8px;
    border: none;
    background-color: rgb(0, 106, 245);
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: 200ms ease;
    margin-left: 85%;
    margin-top: -35px;
    margin-bottom: 8px;
}

.save-change-button:hover {
    background-color: rgb(4, 91, 206);
}

.save-change-button button {
    height: 48px;
    width: 151px;
    border-radius: 8px;
    border: none;
    background-color: rgb(0, 106, 245);
    color: white;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: 200ms ease;
}

.customize-tab {
    margin: 20px;
}

.customize-tab-question {
    width: 100%;
}

.analysis-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.analysis-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #44474F;
}

.analysis-option input[type="radio"] {
    display: none;
}

.analysis-option .custom-radio {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #1C274C;
    margin-right: 10px;
    position: relative;
    flex-shrink: 0;
}

.analysis-option input[type="radio"]:checked+.custom-radio::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #1C274C;
    position: absolute;
    top: 2px;
    left: 2px;
}

.analysis-option span {
    display: inline-block;
    line-height: 20px;
}


h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: var(--color-blue);
}

.styled-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .styled-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        // background: #f9f9f9;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        input[type="checkbox"] {
            margin-right: 10px;
            transform: scale(1.2);
        }

        label {
            font-size: 1em;
            color: #333;
            cursor: pointer;
        }
    }

    .styled-list-item-question {
        padding: 10px;
        font-size: 1.1em;
        width: 90%;
    }
}

.date-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.date-label {
    font-weight: bold;
    color: #333;
    margin-right: 10px;
    font-size: 16px;
}

.date-input {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    max-width: 150px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.date-input:focus {
    border-color: #007bff;
    outline: none;
}

.tree {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #333;
    font-size: 16px;

    li {
        margin: 10px 0;
        padding-left: 20px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 100%;
            border-left: 2px solid #ccc;
        }

        &:after {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 10px;
            height: 0;
            border-top: 2px solid #ccc;
        }

        &.last:before {
            height: 10px;
        }

        .location-type-panel {
            padding-left: 20px;
            margin-top: 10px;
        }
    }
}

.tree li:first-child:before {
    top: 10px;
}

.tree li:last-child:before {
    height: 10px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

.health-center-item {
    display: flex;
    align-items: center;
}

.health-center-item input[type="checkbox"] {
    margin-right: 110px;
    margin-bottom: -15px;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
}

.styled-table th,
.styled-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.response-selection {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.response-option {
    display: block;
    margin-bottom: 5px;
}

.selected-items {
    margin-top: 20px;
    padding: 10px;
    // background-color: #f1f1f1;
    border-radius: 5px;
}

.selected-items h4 {
    margin-bottom: 10px;
}

.selected-items ul {
    list-style: none;
    padding: 0;
}

.selected-items li {
    padding: 5px 0;
}

.toggle-btn {
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &.plus {
        background-color: #55be7e;
    }

    &.minus {
        background-color: #e25244;
    }

    &:hover {
        opacity: 0.8;
    }
}


.toggle-btn.expanded {
    font-weight: bold;
}

.chart-preview-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.chart-preview-modal-content {
    background-color: #fff;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 1200px;
    border-radius: 8px;
    overflow-y: auto;
    max-height: 80vh;
}

.chart-preview-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;

    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
    }
}

.chart-preview-container {
    margin-top: 20px;

    &.table-mode {
        display: flex;
        flex-direction: column;
    }

    &>div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
    }
}


.chart-preview-canvas-container {
    position: relative;
    width: 100%;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .18);
    transition: transform .3s ease, box-shadow .3s ease;
    overflow: hidden;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.table-container {
    margin-top: 20px;
    overflow-x: auto;
    max-width: 100%;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container th,
.table-container td {
    padding: 8px 12px;
    border: 1px solid #ddd;
}

.table-container th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.chart-preview-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.btn-preview {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #2980b9;
    }
}

.chart-preview-modal-content {
    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 3px solid #f1f1f1;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.chart-preview-container {
    padding-right: 10px;
}

.scrollable-card {
    height: 400px;
    /* Fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding: 0 16px;
    /* Remove top padding */
    margin: 0;
    /* Ensure no additional margins */
    background-color: #fff;
    /* Background color */
    border: 1px solid #d0d5dd;
    /* Optional: Card border */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: Card shadow */
    scroll-behavior: smooth;
    /* Smooth scrolling */
}

.scrollable-card ul {
    margin: 0;
    padding: 0;
    /* Reset padding */
    list-style: none;
    /* Remove list styles */
}

.scrollable-card::-webkit-scrollbar {
    width: 8px;
    /* Scrollbar width */
}

.scrollable-card::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Scrollbar track color */
}

.scrollable-card::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 8px;
    /* Rounded corners for the scrollbar */
}

.scrollable-card::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Hover color */
}

/* Ensure content starts at the top */
.scrollable-card>* {
    margin: 0;
    padding: 0;
}
</style>
