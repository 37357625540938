<template>
    <div class="card-container">
        <table>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>ICLM Member</th>
                    <th>Health Facility Member</th>
                    <th>Questionnaire</th>
                    <th>Urgency</th>
                    <th>Comment</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in displayedData" :key="index">
                    <td>{{ calculateIndex(index) }}</td>
                    <td>{{ formatDateTime(item.created_at) }}</td>
                    <td>
                        <span v-if="item.iclm_member">
                            {{ item.iclm_member.first_name }} {{ item.iclm_member.last_name }}
                        </span>
                        <span v-else>N/A</span>
                    </td>
                    <td>
                        <span v-if="item.participant_details">
                            {{ item.participant_details.full_name }}
                        </span>
                        <span v-else>N/A</span>
                    </td>
                    <td>
                        <a href="#" @click="viewSurvey(item)" class="viewAnswers">View Survey</a>
                    </td>
                    <td>
                        <div :class="getRatingClass(item.rating)" class="agency-badge">{{ item.rating }}</div>
                    </td>
                    <td>
                        <a href="#" @click="viewComment(item)" class="viewAnswers">Comment</a>
                    </td>
                    <td>
                        <span>
                            {{ item.newStatus }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="tb-footer">
            <div class="prev">
                <button @click="prevPage" :disabled="currentPage === 1">
                    <i class="fa fa-caret-left"></i>
                </button>
            </div>
            <div class="center">

                <!-- Generate pagination buttons -->
                <button v-for="page in totalPages" :key="page" @click="gotoPage(page)"
                    :class="{ activePagination: currentPage === page }">
                    {{ page }}
                </button>

            </div>
            <div class="next">
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    <i class="fa fa-caret-right"></i>
                </button>
            </div>
        </div>

        <div class="modal-wrapper">
            <teleport to="body">
                <transition name="fade">
                    <div v-if="showModal" class="modal-overlay" @click="closeModal">
                        <div class="modal" @click.stop>
                            <div class="modal-header">
                                <div class="circle" @click="closeModal">
                                    <i class="fa fa-close"></i>
                                </div>
                            </div>
                            <div class="modal-body">
                                <div class="modal-body-container">
                                    <center><b>Comment</b></center>
                                    <div v-for="comment in comments" :key="comment.id" class="comment-container">
                                        <p>{{ comment.comment }}</p>
                                        <img v-if="comment.image" :src="comment.image" alt="Comment Image"
                                            class="comment-image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </teleport>
        </div>
    </div>
</template>




<script>
import axios from "@/helpers/axios.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
var XLSX = require("xlsx");

export default {
    data() {
        return {
            showModal: false,
            searchQuery: "",
            currentPage: 1,
            itemsPerPage: 5,
            users: [],
            filteredData: [],
            surveyData: null,
            comments: [],
            item: {
                status: 'pending',
                newStatus: 'pending'
            },
            showDropdown: false
        };
    },
    mounted() {
        this.fetch_health_facility_tool();
    },
    computed: {
        selectOptions() {
            return [
                { value: 'pending', text: 'Pending' },
                { value: 'progress', text: 'In Progress' },
                { value: 'resolved', text: 'Resolved' }
            ];
        },
        totalPages() {
            return Math.ceil(this.filteredData.length / this.itemsPerPage);
        },
        paginatedData() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.users.slice(startIndex, endIndex);
        },
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        endIndex() {
            return Math.min(
                this.currentPage * this.itemsPerPage,
                this.filteredData.length
            );
        },
        filteredData() {
            if (!this.searchQuery) {
                return this.users;
            } else {
                const regex = new RegExp(this.searchQuery.trim(), "i");
                return this.users.filter((item) =>
                    regex.test(item.iclm_member ? `${item.iclm_member.first_name} ${item.iclm_member.last_name}` : '') ||
                    regex.test(item.participant_details ? item.participant_details.full_name : '')
                );
            }
        },
        displayedData() {
            if (Array.isArray(this.filteredData)) {
                return this.filteredData.slice(this.startIndex, this.endIndex);
            } else {
                return [];
            }
        },
    },
    methods: {
        gotoPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        viewSurvey(item) {
            const userId = item.iclm_member.id;
            const sessionId = item.session_id;
            const participantId = item.participant_details.id;

            this.$router.push(`/survey/${userId}/${sessionId}/${participantId}`);
        },
        async viewComment(item) {
            try {
                const user_id = item.iclm_member.id;
                const session_id = item.session_id;
                const participant_id = item.participant_details.id;

                const response = await axios.post("/admin/get-interviewer-comments", {
                    session_id: session_id,
                    user_id: user_id,
                    participant_id: participant_id
                });

                if (response.data.status && response.data.data) {
                    this.comments = response.data.data;
                    this.showModal = true;
                } else {
                    console.error('Error: Unable to fetch comments');
                }
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        },
        closeModal() {
            this.showModal = false;
            this.comments = [];
        },
        calculateIndex(index) {
            return (this.currentPage - 1) * this.itemsPerPage + index + 1;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        exportToExcel() {
            const ws = XLSX.utils.json_to_sheet(this.users);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "Community Members.xlsx");
        },
        exportToPdf() {
            const doc = new jsPDF();
            const header = [["Name", "Age"]];
            const data = this.users.map((item) => [item.name, item.age]);

            doc.text("Community Members", doc.internal.pageSize.getWidth() / 2, 10, {
                align: "center",
            });

            doc.autoTable({
                head: header,
                body: data,
            });
            doc.save("community members.pdf");
        },
        getRatingClass(rating) {
            switch (rating) {
                case 'high':
                    return 'high-rating';
                case 'medium':
                    return 'medium-rating';
                case 'low':
                    return 'low-rating';
                default:
                    return '';
            }
        },
        async fetch_health_facility_tool() {
            try {
                const response = await axios.get("admin/survey/2");
                this.users = response.data.data.map(user => ({
                    ...user,
                    newStatus: user.status
                }));
                this.filteredData = this.users;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        formatDateTime(dateTimeString) {
            const date = new Date(dateTimeString);
            return date.toLocaleString();
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        updateStatus(item) {
            if (!item.newStatus) {
                alert("Please select a status.");
                return;
            }
            if (confirm("Are you sure you want to update the status?")) {
                const requestData = {
                    rating_id: item.rating_id,
                    status: item.newStatus
                };
                axios.post('/admin/update-status', requestData)
                    .then(response => {
                        console.log("Status updated successfully:", response.data);
                    })
                    .catch(error => {
                        console.error("Error updating status:", error);
                    });
            }
        },
    },
};
</script>




<style lang="scss" scoped>
.card-container {
    .card-header {
        background-color: var(--blue-500);
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: sticky;
        top: 0px;

        .right {
            ul {
                display: flex;
                align-items: center;
                gap: 20px;
                list-style-type: none;

                li {
                    .input-container {
                        position: relative;
                        border-radius: 5px;
                        overflow: hidden;

                        input {
                            padding: 10px;
                            outline: none;
                            border: none;
                            padding-right: 40px;
                        }

                        .icon {
                            position: absolute;
                            color: gray;
                            top: 8px;
                            right: 10px;
                        }
                    }
                }

                .export {
                    cursor: pointer;
                    transition: 200ms ease;

                    &:hover {
                        transform: scale(1.5);
                        rotate: -10deg;
                    }
                }

                .excel {
                    &:hover {
                        color: rgb(142, 233, 188);
                    }
                }

                .pdf {
                    &:hover {
                        color: rgb(233, 142, 142);
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        background-color: white;



        thead {
            background-color: #F9FAFB;
            color: #344054;
            border-radius: 8px;
            border-bottom: 1px solid #E4E7EC;


            th {
                padding: 15px 16px;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #E4E7EC;

                td {
                    padding: 10px;
                    font-size: 14px;

                    .argency-badge {
                        padding: 4px 2px;
                        border-radius: 30px;
                        font-size: 14px;
                        background-color: rgba(110, 64, 50, 0.829);
                        display: flex;
                        color: var(--white);
                        font-weight: bolder;
                    }

                    .viewAnswers {
                        color: var(--blue-500);
                        // color: var(--white);
                        padding: 5px;
                        border: none;
                        cursor: pointer;
                        transition: 200ms ease;


                    }
                }

            }
        }
    }

    .tb-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 12px;

        .center {
            display: flex;
            gap: 8px;

            .activePagination {
                background-color: #D4DBE2;

            }
        }


        button {
            width: 32px;
            height: 32px;
            background-color: transparent;
            border-radius: 8px;
            border: 1px solid #D4DBE2;
            cursor: pointer;

            &:hover {
                background-color: #dfe4e9;
            }
        }
    }
}

.modal-wrapper {
    position: relative;
    // overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(15px);
    z-index: 99999999;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    transform: scale(1);
    transition: 300ms ease;
    z-index: 99999;
    width: 50%;
    min-height: 40%;
    max-height: 90%;
    overflow-y: scroll;
    position: relative;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;

    .modal-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        position: sticky;
        top: 0;

        .circle {
            background-color: var(--blue-500);
            color: white;
            width: 20px;
            height: 20px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 200ms ease;
            cursor: pointer;

            &:hover {
                background-color: var(--blue-600);
            }
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-right: 50px;

        .modal-body-container {
            display: flex;
            gap: 30px;
            flex-direction: column;

            .question-container {
                display: flex;
                flex-direction: column;
                gap: 20px;

                h3 {
                    color: var(--blue-500);
                }

                .answer-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .line {
                        width: 30px;
                        height: 1px;
                        background-color: var(--blue-500);
                    }
                }
            }
        }
    }
}

.fade-enter-active {
    $modalOpen: True;

    @if $modalOpen ==True {
        .modal {
            transform: scale(0);
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transform: scale(1);
    transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.select-container {
    position: relative;
}

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 28px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    width: 100%;
    cursor: pointer;
}

.custom-select:focus {
    outline: none;
    border-color: #4CAF50;
}

.select-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #555;
}

.high-rating {
    background-color: rgba(231, 29, 19, 0.1);
    color: rgba(202, 21, 12, 1);
    padding: 4px;
    border-radius: 12px;
    font-weight: 400;
    width: 51px;
    height: 26px;
}

.medium-rating {
    background-color: rgba(250, 228, 208, 1);
    color: rgba(253, 135, 0, 1);
    padding: 4px;
    border-radius: 12px;
    font-weight: 400;
    width: 70px;
    height: 26px;
}

.low-rating {
    background-color: rgba(0, 106, 245, 0.1);
    color: black;
    padding: 4px;
    border-radius: 12px;
    font-weight: 400;
    width: 48px;
    height: 26px;
}

.comment-image {
    max-width: 100%;
    margin-top: 10px;
}
</style>