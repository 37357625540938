<template>
  <div class="forum-container">


    <div class="forum-header">
      <ul>
        <li :class="{ active: selectedToolId === null }" @click="fetchForum(null)">All Tools</li>
        <li :class="{ active: selectedToolId === 1 }" @click="fetchForum(1)">Patient Tool</li>
        <li :class="{ active: selectedToolId === 2 }" @click="fetchForum(2)">Health Facility Tool</li>
        <li :class="{ active: selectedToolId === 3 }" @click="fetchForum(3)">Community Based Tool</li>
        <li :class="{ active: selectedToolId === 4 }" @click="fetchForum(4)">Observation Tool</li>
      </ul>
    </div>


    <div class="filter-container">
      <div class="left">

        <div class="container">
          <select v-model="filters.urgency">
            <option value="" selected>Urgency</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            data-v-20395248="">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" data-v-20395248=""></path>
          </svg>
        </div>

        <div class="container">
          <select v-model="filters.status">
            <option value="" selected>Status</option>
            <option value="pending">Pending</option>
            <option value="progress">In Progress</option>
            <option value="resolved">Resolved</option>
          </select>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            data-v-20395248="">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" data-v-20395248=""></path>
          </svg>
        </div>

        <div class="container">
          <select v-model="filters.district_id">
            <option value="" selected>Locations</option>
            <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
            </option>
          </select>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            data-v-20395248="">
            <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" data-v-20395248=""></path>
          </svg>
        </div>

        <div class="container">
          <input type="date" v-model="filters.fromDate" placeholder="From Date" class="date-picker">
        </div>

        <div class="container">
          <input type="date" v-model="filters.toDate" placeholder="To Date" class="date-picker">
        </div>

      </div>
    </div>


    <div v-if="loading" class="loading-spinner">
      Loading...
    </div>

    <div v-else-if="forums.length === 0">
      <p>No forum data available.</p>
    </div>

    <div v-else class="form-content">
      <div v-for="(forum, index) in forums" :key="index" class="forum-container">
        <div class="q-header">
          <div class="left-q-header">

            <svg
              v-if="forum.participant.type == 'health_facility_member' || forum.participant.type == 'service_provider'"
              width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="44" height="44" rx="22" fill="#1C274C" fill-opacity="0.1" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20.75 12H22.75C24.6356 12 25.5784 12 26.1642 12.5858C26.75 13.1716 26.75 14.1144 26.75 16V31.25H31.25H31.75C32.1642 31.25 32.5 31.5858 32.5 32C32.5 32.4142 32.1642 32.75 31.75 32.75H11.75C11.3358 32.75 11 32.4142 11 32C11 31.5858 11.3358 31.25 11.75 31.25H15.25H16.75V16C16.75 14.1144 16.75 13.1716 17.3358 12.5858C17.9216 12 18.8644 12 20.75 12ZM21.75 14.25C22.1642 14.25 22.5 14.5858 22.5 15V16.25H23.75C24.1642 16.25 24.5 16.5858 24.5 17C24.5 17.4142 24.1642 17.75 23.75 17.75H22.5V19C22.5 19.4142 22.1642 19.75 21.75 19.75C21.3358 19.75 21 19.4142 21 19V17.75H19.75C19.3358 17.75 19 17.4142 19 17C19 16.5858 19.3358 16.25 19.75 16.25H21V15C21 14.5858 21.3358 14.25 21.75 14.25ZM19 22C19 21.5858 19.3358 21.25 19.75 21.25H23.75C24.1642 21.25 24.5 21.5858 24.5 22C24.5 22.4142 24.1642 22.75 23.75 22.75H19.75C19.3358 22.75 19 22.4142 19 22ZM19 25C19 24.5858 19.3358 24.25 19.75 24.25H23.75C24.1642 24.25 24.5 24.5858 24.5 25C24.5 25.4142 24.1642 25.75 23.75 25.75H19.75C19.3358 25.75 19 25.4142 19 25ZM21.75 28.25C22.1642 28.25 22.5 28.5858 22.5 29V31.25H21V29C21 28.5858 21.3358 28.25 21.75 28.25Z"
                fill="#1C274C" />
              <path opacity="0.5"
                d="M30.9129 15.8887C31.25 16.3931 31.25 17.0954 31.25 18.4998V31.2498H31.75C32.1642 31.2498 32.5 31.5856 32.5 31.9998C32.5 32.414 32.1642 32.7498 31.75 32.7498H11.75C11.3358 32.7498 11 32.414 11 31.9998C11 31.5856 11.3358 31.2498 11.75 31.2498H12.25V18.4998C12.25 17.0954 12.25 16.3931 12.5871 15.8887C12.733 15.6703 12.9205 15.4828 13.1389 15.3369C13.5801 15.0421 15.6756 15.0051 16.757 15.0005C16.7529 15.2919 16.7529 15.5964 16.753 15.9103L16.753 15.9998V17.2498H14.25C13.8358 17.2498 13.5 17.5856 13.5 17.9998C13.5 18.414 13.8358 18.7498 14.25 18.7498H16.753V20.2498H14.25C13.8358 20.2498 13.5 20.5856 13.5 20.9998C13.5 21.414 13.8358 21.7498 14.25 21.7498H16.753V23.2498H14.25C13.8358 23.2498 13.5 23.5856 13.5 23.9998C13.5 24.414 13.8358 24.7498 14.25 24.7498H16.753V31.2498H26.7529V24.7498H29.25C29.6642 24.7498 30 24.414 30 23.9998C30 23.5856 29.6642 23.2498 29.25 23.2498H26.7529V21.7498H29.25C29.6642 21.7498 30 21.414 30 20.9998C30 20.5856 29.6642 20.2498 29.25 20.2498H26.7529V18.7498H29.25C29.6642 18.7498 30 18.414 30 17.9998C30 17.5856 29.6642 17.2498 29.25 17.2498H26.7529V15.9998L26.7529 15.9103V15.9103C26.753 15.5964 26.753 15.2919 26.7489 15.0005C27.8303 15.0051 29.9199 15.0421 30.3611 15.3369C30.5795 15.4828 30.767 15.6703 30.9129 15.8887Z"
                fill="#1C274C" />
            </svg>



            <svg v-else width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="44" height="44" rx="22" fill="#1C274C" fill-opacity="0.1" />
              <path opacity="0.5"
                d="M31.9982 22.193C31.9994 22.1288 32 22.0645 32 22C32 16.4772 27.5228 12 22 12C16.4772 12 12 16.4772 12 22C12 22.1294 12.0025 22.2582 12.0073 22.3864L16.9872 24.1969L19.8645 23.046C21.2354 22.4977 22.7646 22.4977 24.1355 23.046L27 24.1918L31.9982 22.193Z"
                fill="#1C274C" />
              <path
                d="M20 20.5C20 21.3284 19.5523 22 19 22C18.4477 22 18 21.3284 18 20.5C18 19.6716 18.4477 19 19 19C19.5523 19 20 19.6716 20 20.5Z"
                fill="#1C274C" />
              <path
                d="M26 20.5C26 21.3284 25.5523 22 25 22C24.4477 22 24 21.3284 24 20.5C24 19.6716 24.4477 19 25 19C25.5523 19 26 19.6716 26 20.5Z"
                fill="#1C274C" />
              <path
                d="M25.7657 31.2667L26.21 25.4914L23.5784 24.4387C22.5652 24.0334 21.4348 24.0334 20.4216 24.4387L17.79 25.4914L18.2343 31.2667C19.3966 31.7395 20.6679 32 22 32C23.3321 32 24.6034 31.7395 25.7657 31.2667Z"
                fill="#1C274C" />
              <path d="M31.8241 23.8779L27.7122 25.5224L27.3323 30.4611C29.6317 29.009 31.2982 26.6455 31.8241 23.8779Z"
                fill="#1C274C" />
              <path d="M16.6681 30.4611L16.2895 25.539L12.2119 24.0565C12.7744 26.7471 14.4182 29.0404 16.6681 30.4611Z"
                fill="#1C274C" />
            </svg>


            <div class="user-details">
              <h1>{{ forum.participant.full_name }}</h1>
              <div class="user-type">
                <p>{{ forum.participant.type }}</p>
              </div>
            </div>
          </div>
          <div class="right-q-header">
            <div class="container" v-if="forum.status !== 'resolved'">
              <select v-model="forum.newStatus" @change="updateStatus(forum)"
                :class="{ progress: forum.status === 'progress' }">
                <option disabled value="pending">Pending</option>
                <option value="progress">In Progress</option>
                <option value="resolved">Resolved</option>
              </select>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </div>
            <div class="status-resolved" v-else>

              <p :class="statusColorClass(forum.status)"><b>{{ forum.status }}</b></p>
            </div>
            <div class="timestamp">

              {{ formatTimeAgo(forum.created_at) }}
            </div>
          </div>
        </div>
        <div class="q-body">
          <p>{{ forum.interviewee_comment }}</p><br>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "@/helpers/axios.js";

export default {
  data() {
    return {
      showResponses: [],
      forums: [],
      districts: [],
      selectedToolId: null,
      loading: false,
      showFromDatePicker: false,
      showToDatePicker: false,
      filters: {
        urgency: '',
        status: '',
        district_id: '',
        fromDate: '',
        toDate: '',
      }
    };
  },

  mounted() {
    this.fetchForum(null);
    this.allDistricts();
  },
  methods: {
    toggleResponses(index) {
      if (typeof this.showResponses[index] === "undefined") {
        this.showResponses[index] = true;
      } else {
        this.showResponses[index] = !this.showResponses[index];
      }
    },
    statusColorClass(status) {
      return {
        'text-red': status === 'pending',
        'text-blue': status === 'progress',
        'text-green': status === 'resolved'
      };
    },
    openFromDatePicker() {
      this.$refs.fromDateInput.focus();
    },
    openToDatePicker() {
      this.$refs.toDateInput.focus();
    },
    updateFromDate() {
      this.filters.fromDateFormatted = this.formatDate(this.filters.fromDate);
    },
    updateToDate() {
      this.filters.toDateFormatted = this.formatDate(this.filters.toDate);
    },
    formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    updateStatus(forum) {
      const confirmed = window.confirm('Are you sure you want to update the status?');
      console.log(forum);
      if (confirmed) {
        const requestData = {
          forum_id: forum.id,
          status: forum.newStatus
        };
        axios.post('/admin/update-status', requestData)
          .then(response => {
            this.fetchForum(this.selectedToolId);
          })
          .catch(error => {
            console.error('Error updating status:', error);
          });
      }
    },
    async fetchForum(toolId) {
      this.selectedToolId = toolId;
      this.loading = true;

      try {
        const url = toolId ? `/admin/forum/${toolId}` : '/admin/forum';

        // Prepare the query parameters
        const params = {
          rating: this.filters.urgency,
          status: this.filters.status,
          district_id: this.filters.district_id,
          from_date: this.filters.fromDate,
          to_date: this.filters.toDate
        };

        const response = await axios.get(url, { params });

        if (response.data && response.data.data) {
          this.forums = response.data.data.map(forum => ({
            ...forum,
            newStatus: forum.status || 'pending'
          }));
        } else {
          this.forums = [];
        }
      } catch (error) {
        console.error('Error fetching forum data:', error);
        this.forums = [];
      } finally {
        this.loading = false;
      }
    },

    formatTimeAgo(timestamp) {
      const now = new Date();
      const notificationTime = new Date(timestamp);
      const timeDiff = now - notificationTime;
      const seconds = Math.floor(timeDiff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30);
      const years = Math.floor(months / 12);

      if (years > 0) {
        return years === 1 ? "1 year ago" : `${years} years ago`;
      } else if (months > 0) {
        return months === 1 ? "1 month ago" : `${months} months ago`;
      } else if (days > 0) {
        if (days === 1) {
          return "yesterday";
        } else if (days < 7) {
          return `${days} days ago`;
        } else {
          const weeks = Math.floor(days / 7);
          return weeks === 1 ? "last week" : `${weeks} weeks ago`;
        }
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "just now";
      }
    },
    allDistricts() {
      axios
        .get("/districts")
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching districts:", error);
        });
    }
  },
  watch: {
    'filters.urgency': function () {
      this.fetchForum(this.selectedToolId);
    },
    'filters.status': function () {
      this.fetchForum(this.selectedToolId);
    },
    'filters.district_id': function () {
      this.fetchForum(this.selectedToolId);
    },
    'filters.toDate': function () {
      // Only trigger fetch when `to_date` is selected
      if (this.filters.toDate) {
        this.fetchForum(this.selectedToolId);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
  fill: green;
}

.forum-container {
  display: flex;
  padding: 0 3%;
  height: 100%;
  gap: 20px;
  flex-direction: column;

  .filter-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #0A2472;
    padding: 8px;
    border-radius: 8px;

    .left {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      .box {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: #D3E4FF;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .container {
        min-width: 129px;
        position: relative;
        background-color: white;
        border-radius: 7px;

        select {
          width: 100%;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
          z-index: 1;

          path {
            stroke: #667085;
          }
        }
      }

      select,
      input {
        width: 100%;
        height: 40px;
        padding-left: 15px;
        font-size: 16px;
        border: 1.9px solid lightgray;
        border-radius: 4px;
        color: #667085;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        cursor: pointer;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        position: relative;
        z-index: 2;
      }
    }

    .right {
      .input-container {
        position: relative;
        flex-grow: 1;

        input {
          width: 192px;
          padding: 10px 14px;
          padding-left: 42px;
          background-color: rgba(255, 255, 255, 8%);
          border: 1px solid rgba(211, 228, 255, 100%);
          border-radius: 8px;
          outline: none;
          color: rgba(211, 228, 255, 100%);

          &::placeholder {
            color: rgba(211, 228, 255, 100%);
          }
        }

        svg {
          position: absolute;
          top: 8px;
          left: 14px;
        }
      }
    }
  }

  .forum-header {
    ul {
      display: flex;
      align-items: center;
      gap: 12px;
      list-style-type: none;
      padding: 0;

      li {
        font-size: 16px;
        font-weight: bold;
        color: #C5C6D0;
        padding: 12px 16px;
        border-bottom: 4px solid transparent;
        cursor: pointer;

        &.active {
          color: #0A2472;
          border-bottom-color: #2148C0;
        }

        &:hover {
          color: #0A2472;
        }
      }
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .forum-container {
    background-color: var(--white);
    padding: 20px;
    width: 100%;
    min-height: 128px;
    border-radius: 5px;
    border: 1px solid #E4E7EC;
    display: flex;
    flex-direction: column;
    gap: 14px;

    .q-header {
      display: flex;
      justify-content: space-between;

      .left-q-header {
        display: flex;
        gap: 10px;
        color: var(--black);

        .icon {
          width: 50px;
          height: 50px;
          border: 1px solid var(--blue-500);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          font-size: 25px;
          color: var(--blue-500);
        }

        .user-details {
          h1 {
            font-size: 16px;
          }

          .user-type {
            display: flex;
            align-items: center;
            gap: 15px;
            color: rgba(27, 43, 65, 0.69);
            font-size: 14px;

            .line {
              width: 60px;
              height: 1px;
              background-color: gray;
            }
          }
        }
      }

      .right-q-header {
        display: flex;
        align-items: center;
        gap: 14px;

        .container {
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(-50%, -50%);
            z-index: 1;
          }

          select {
            height: 40px;
            padding-left: 15px;
            font-size: 16px;
            border: 1.9px solid lightgray;
            border-radius: 4px;
            color: #39325a;
            outline: none;
            appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
            background-color: transparent;
            min-width: 129px;
            position: relative;
            z-index: 2;
          }
        }

        .status-resolved {
          height: 40px;
          background-color: rgba(86, 217, 119, 0.12);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 14px;
          color: #389E51;
          border: 1px solid #D0D5DD;
          border-radius: 8px;
        }

        .timestamp {
          text-align: center;
          font-size: 14px;
          color: #192739;
        }
      }
    }

    .q-body {
      padding: 0;
    }

    .hideResponse {
      display: none;
    }

    .response-main-container {
      padding: 40px;

      .response-container {
        border-left: 1px solid gray;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .q-footer {
      padding: 10px 0;

      a {
        color: var(--blue-500);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.select-container {
  position: relative;
  width: 130px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 28px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  width: 100%;
  cursor: pointer;
}

.custom-select:focus {
  outline: none;
  border-color: #4CAF50;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-green {
  color: green;
}

.progress {
  background-color: rgba(250, 228, 208, 1) !important;
  color: rgba(173, 98, 0, 1) !important;
}

.progress+svg {
  z-index: 5;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  color: #667085;
  cursor: pointer;
  background-color: #fff;
  /* Ensure it's visible */

  &::placeholder {
    color: #aaa;
  }
}

.hidden-date-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  background: transparent;
}
</style>
