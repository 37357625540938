<template>
  <router-view />
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --blue-600: #1e3988;
  --blue-500: #2148c0;
  --blue-400: #264eca;
  --blue-300: #5c7fe7;
  --blue-original: #001C38;
  --blue-original-2: #1F4876;
  --white: #fff;
  --white-dark: #dddddd;
  --black: #2c3e50;
  --gray: rgb(149, 146, 187);
  --gray-light: white;
  --blue-light: #E0E3FF;
}
#app {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:"slnt" 0;
  color: var(--black);
}
</style>
