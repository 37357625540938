<template>
  <div class="uploads-container">
    <div class="uploads-header">
      <ul>
        <router-link v-if="hasPermission('tools', 'patient_tool')" :to="{ name: 'patient_tool_uploads_session' }" :class="{
          'active-user-link': $route.path.includes('/uploads/patient-tool'),
        }">
          <li><strong>Patient Tool</strong></li>
        </router-link>
        <router-link v-if="hasPermission('tools', 'health_facility_tool')" :to="{ name: 'health_facility_tool_uploads_session' }" :class="{
          'active-user-link': $route.path.includes('uploads/health-facility'),
        }">
          <li><strong>Health Facility Tool</strong></li>
        </router-link>

        <router-link  v-if="hasPermission('tools', 'community_based_tool')" :to="{ name: 'community_based_tool_uploads_session' }" :class="{
          'active-user-link': $route.path.includes(
            'uploads/community-based-tool'
          ),
        }">
          <li><strong>Community Based Tool</strong></li>
        </router-link>
        <router-link v-if="hasPermission('tools', 'observation_tool')" :to="{ name: 'observation_uploads_session' }" :class="{
          'active-user-link': $route.path.includes('observation-tool/'),
        }">
          <li><strong>Observation Tool</strong></li>
        </router-link>
        <router-link :to="{ name: 'observation_uploads_session' }" :class="{
          'active-user-link': $route.path.includes('uploads/responses'),
        }">
          <li><strong></strong></li>
        </router-link>
      </ul>
    </div>

    <div class="uploads-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['hasPermission']),
  },
};
</script>

<style lang="scss" scoped>
.uploads-container {
  height: 100%;
  padding: 0px 3%;
  display: flex;
  flex-direction: column;

  .uploads-header {
    ul {
      display: flex;
      list-style-type: none;
      gap: 12px;

      a {
        text-decoration: none;
        border-bottom: 3px solid transparent;
        color: rgb(182, 182, 182);
        transition: 300ms ease;

        li {
          padding: 12px 16px;
        }

        &:hover {
          border-bottom: 3px solid;
        }
      }

      .active-user-link {
        border-bottom: 3px solid #2148C0;
        color: #0A2472;
      }
    }
  }
}
</style>
