<template>
  <div class="card">
    <div class="card-header">
      <h3>Resolved Cases Count Per Tool, Condition and Location</h3>
    </div>
    <div class="content">
      <div class="chart-container">
        <div id="CasesChart" style="width: 100%; height: 100%;"></div>
      </div>
      <div class="dropdown-container">
        <select v-model="selectedTool" @change="fetchChartData">
          <option disabled value="">Select Tool</option>
          <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
          <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
          <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
          <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>
        </select>
        <select v-model="selectedCondition" @change="fetchChartData">
          <option disabled value="">Select Condition</option>
          <option value="1" v-if="hasPermission('conditions', 'hiv')">HIV</option>
          <option value="2" v-if="hasPermission('conditions', 'malaria')">Malaria</option>
          <option value="3" v-if="hasPermission('conditions', 'tuberculosis')">Tuberculosis</option>
        </select>
        <select v-model="selectedProvince" @change="fetchDistricts">
          <option disabled value="">Select Province</option>
          <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
        </select>
        <select v-model="selectedDistrict" @change="fetchChartData">
          <option disabled value="">Select District</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      provinces: [],
      districts: [],
      selectedTool: "",
      selectedCondition: "",
      selectedProvince: "",
      selectedDistrict: "",
      chart: null,
    };
  },
  mounted() {
    this.fetchProvinces();
    this.fetchChartData();
    this.initChart();
  },
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  },
  methods: {
    async fetchProvinces() {
      try {
        const response = await axios.get('/province');
        this.provinces = response.data.data;
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/districts/${this.selectedProvince}`);
        this.districts = response.data.data;
        this.fetchChartData();
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchChartData() {
      try {
        const response = await axios.get('/resolved-cases', {
          params: {
            tool_id: this.selectedTool,
            province_id: this.selectedProvince,
            district_id: this.selectedDistrict,
            condition_id: this.selectedCondition,
          },
        });

        const chartData = response.data.data;
        const labels = chartData.map(item => item.date);
        const data = chartData.map(item => item.count);

        this.updateChart(labels, data);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    initChart() {
      this.chart = echarts.init(document.getElementById("CasesChart"));
    },
    updateChart(labels, data) {
      const options = {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b0}: {c0}', // Formats tooltip to show date and value
        },
        xAxis: {
          type: 'category',
          data: labels,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Resolved Cases',
            type: 'line',
            data: data,
            smooth: true, 
            lineStyle: {
              width: 2, 
            },
            itemStyle: {
              color: '#3A7BD5', 
            },
            symbol: 'circle',
            symbolSize: 8, 
            areaStyle: {
              opacity: 0, 
            },
          },
        ],
      };

      this.chart.setOption(options);
    }


  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 600px;
  /* Increase the overall height of the card */
  box-shadow: 0 0 14px rgb(206, 201, 201);
  background-color: white;
  border-radius: 15px;
  padding: 20px;

  .card-header {
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 85%;
  }

  .chart-container {
    width: 75%;
    /* Increase the width of the chart container */
    height: 500px;
    /* Increase the height of the chart container */
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
      width: 100%;
      height: 100%;
      /* Ensure the canvas takes up the full height */
    }
  }

  .dropdown-container {
    width: 20%;
    /* Reduce the width of the dropdown container */
    display: flex;
    flex-direction: column;
    gap: 10px;

    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
}
</style>
