<template>
  <div class="card-container">
    <div class="card-header">
      <div class="left">
        <h3>Medications</h3>
      </div>
      <div class="right">
        <ul>
          <li class="export excel" @click="exportToExcel">
            <i class="fas fa-file-excel"></i>
          </li>
          <li class="export pdf" @click="exportToPdf">
            <i class="fas fa-file-pdf"></i>
          </li>
          <li>
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                v-model="searchQuery"
                @input="filterData"
                placeholder="Search for user"
              />
              <div class="icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <table>
      <thead>
        <th>No</th>
        <th>Health Worker</th>
        <th>District</th>
        <th>Health Center</th>
        <th>Community member</th>
        <th>ID</th>
        <th>Date</th>
        <th>Medication</th>
      </thead>
      <tbody>
        <tr v-for="(med, index) in displayedData" :key="index">
          <td>{{ calculateIndex(index) }}</td>
          <td>
            {{ med.health_worker.first_name }} {{ med.health_worker.last_name }}
          </td>
          <td>
            {{ med.health_worker.district.name }}
          </td>
          <td>{{ med.health_center ? med.health_center : "N/A" }}</td>
          <td>
            {{ med.community_member.first_name }}
            {{ med.community_member.last_name }}
          </td>
          <td>{{ med.community_member.registration_number }}</td>
          <td>
            {{ med.created_at.slice(0, 10) }} {{ med.created_at.slice(11, 19) }}
          </td>
          <td>
            <button @click="openModal(med.id)" class="activate">View</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-footer">
      <div class="left">
        <button @click="prevPage" :disabled="currentPage === 1">
          <i class="fa fa-arrow-left"></i> Previous
        </button>
        <div class="count">{{ currentPage }} / {{ totalPages }}</div>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>

      <div class="right">
        <p>
          Showing from {{ startIndex + 1 }} to {{ endIndex }} of
          {{ users.length }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-wrapper">
    <teleport to="body">
      <transition name="fade">
        <div v-if="showModal" class="modal-overlay" @click="closeModal">
          <div class="modal" @click.stop>
            <div class="modal-header">
              <div class="circle" @click="closeModal">
                <i class="fa fa-close"></i>
              </div>
            </div>
            <div class="modal-body" v-for="(med, index) in meds" :key="index">
              <h3>Medication Name</h3>
              <p>{{ med.medication_name }}</p>
              <h3>Duration</h3>
              <p>{{ med.duration }}</p>
              <h3>Quantity Per day</h3>
              <p>{{ med.frequency }}</p>
              <h3>Quantity Per Intake</h3>
              <p>{{ med.qty_per_intake }}</p>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
var XLSX = require("xlsx");

export default {
  data() {
    return {
      showModal: false,
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 6,
      users: [],
      districts: [], // Array to store districts
      provinces: [], // Array to store provinces
      meds: [],
    };
  },
  mounted() {
    this.fetchMedication();
    // this.fetchDistrictsAndProvinces();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      return this.users.slice(startIndex, endIndex);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(
        this.currentPage * this.itemsPerPage,
        this.filteredData.length
      );
    },
    filteredData() {
      if (!this.searchQuery) {
        return this.users;
      } else {
        const regex = new RegExp(this.searchQuery.trim(), "i");
        return this.users.filter((item) => regex.test(item.name));
      }
    },

    displayedData() {
      return this.filteredData.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    async fetchMedication() {
      try {
        const response = await axios.get(
          "http://iclm.ubumenyi.rw/api/admin/medications/all"
        );

        this.users = response.data.data;

        response.data.data.map((med) => {
          console.log(med);

          return { ...med };
        });
      } catch (error) {}
    },

    async getDistrict(province_id, district_id) {
      console.log(province_id);
      try {
        const response = await axios.get(
          `http://iclm.ubumenyi.rw/api/districts/${province_id}`
        );
        let districts = response.data.data;

        // Filter districts synchronously
        const district = districts.find(
          (district) => district.id == district_id
        );
        return district ? district.name : "District Not Found";
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async getDistrictName(med) {
      try {
        const district = await this.getDistrict(
          med.health_worker.province_id,
          med.health_worker.district_id
        );
        return district; // Already resolved district name
      } catch (error) {
        console.error("Error fetching district name:", error);
        return "Error Fetching District";
      }
    },

    openModal(id) {
      this.showModal = true;
      this.meds = this.users.filter((med) => med.id == id);
    },
    closeModal() {
      this.showModal = false;
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.users);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Deactivated Accounts.xlsx");
    },
    exportToPdf() {
      const doc = new jsPDF();
      const header = [["Name", "Age"]];
      const data = this.users.map((item) => [item.name, item.age]);

      // Add heading
      doc.text(
        "Deactivated Accounts",
        doc.internal.pageSize.getWidth() / 2,
        10,
        {
          align: "center",
        }
      );

      doc.autoTable({
        head: header,
        body: data,
      });
      doc.save("Deactivated Accounts.pdf");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding: 20px 3%;
  overflow-y: scroll;
  height: 100%;
  .card-header {
    background-color: var(--blue-500);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0px;

    .right {
      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        list-style-type: none;

        li {
          .input-container {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            input {
              padding: 10px;
              outline: none;
              border: none;
              padding-right: 40px;
            }

            .icon {
              position: absolute;
              color: gray;
              top: 8px;
              right: 10px;
            }
          }
        }

        .export {
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            transform: scale(1.5);
            rotate: -10deg;
          }
        }

        .excel {
          &:hover {
            color: rgb(142, 233, 188);
          }
        }

        .pdf {
          &:hover {
            color: rgb(233, 142, 142);
          }
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    background-color: white;
    border: 1px solid var(--blue-500);

    thead {
      background-color: var(--blue-500);
      color: var(--white);
      position: sticky;
      top: 50px;

      th {
        padding: 10px;
      }
    }
    tbody {
      tr {
        td {
          padding: 10px;

          .activate {
            padding: 7px;
            border: none;
            background-color: var(--blue-500);
            color: var(--white);
            cursor: pointer;
            transition: 200ms ease;

            &:hover {
              background-color: var(--blue-600);
            }
          }
        }

        &:nth-child(even) {
          background-color: rgb(218, 218, 218);
        }
      }
    }
  }

  .table-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      button {
        padding: 5px;
        background-color: var(--blue-500);
        color: var(--white);
        cursor: pointer;
        border: none;

        &:hover {
          background-color: var(--blue-600);
        }

        &:disabled {
          background-color: var(--gray);
          cursor: not-allowed;
        }
      }
    }
  }
}

.modal-wrapper {
  position: relative;
  // overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(15px);
  z-index: 99999999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  width: 50%;
  min-height: 40%;
  max-height: 90%;
  overflow-y: scroll;
  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    position: sticky;
    top: 0;

    .circle {
      background-color: var(--blue-500);
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 200ms ease;
      cursor: pointer;

      &:hover {
        background-color: var(--blue-600);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 50px;

    h3 {
      color: var(--blue-500);
    }
  }
}

.fade-enter-active {
  $modalOpen: True;
  @if $modalOpen == True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
