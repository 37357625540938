<template>
  <div class="see-users-container">
    <div class="navbar">
      <ul>
        <router-link
          :to="{
            name: 'community_based_introduction',
            params: { id: this.$route.params.id },
          }"
          :class="{
            'active-user-link': $route.path.includes('/introduction'),
          }"
        >
          <li>Introduction</li>
        </router-link>
        <router-link
          :to="{
            name: 'community_based_introductory_questions',
            params: { id: this.$route.params.id },
          }"
          :class="{
            'active-user-link': $route.path.includes('/intro-file'),
          }"
        >
          <li>Introductory Questions</li>
        </router-link>
        <router-link
          :to="{
            name: 'community_based_tool_uploads_session_files',
            params: { id: this.$route.params.id },
          }"
          :class="{
            'active-user-link': $route.path.includes('/files'),
          }"
        >
          <li>Questions</li>
        </router-link>
        <router-link v-if="hasPermission('other','edit_tool')"
          :to="{
            name: 'community_based_tool_uploads_session_upload_files',
            params: { id: this.$route.params.id },
          }"
          :class="{
            'active-user-link': $route.path.includes('/add'),
          }"
        >
          <li>Upload New File</li>
        </router-link>
        <router-link v-if="hasPermission('other','edit_tool')"
          :to="{
            name: 'community_based_tool_uploads_session_settings',
            params: { id: this.$route.params.id },
          }"
          :class="{
            'active-user-link': $route.path.includes('/settings'),
          }"
        >
          <li>Session settings</li>
        </router-link>
      </ul>
    </div>
    <div class="see-users-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      hasPermission: 'hasPermission'
    }),
  }
};
</script>


<style lang="scss" scoped>
.see-users-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;

  .navbar {
    width: 100%;
    background-color: rgba(33, 72, 192, 0.1);
    // position: sticky;
    top: 0px;
    z-index: 10;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;

    ul {
      list-style-type: none;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      gap: 20px;

      a {
        text-decoration: none;
        color: var(--dark);

        li {
          padding: 10px 20px;
          background-color: transparent;
          border-radius: 7px;
          transition: 300ms ease;
          border: 1px solid transparent;

        }


      }

      .active-user-link {
        li {
          background-color: white;
          border: 1px solid #E4E7EC;
        }
      }
    }

    .container {
      select {
        width: 136px;
        height: 48px;
      }
    }
  }

  .see-users-body {
    width: 100%;
    margin-top: 20px;
  }
}
</style>

