<template>
  <div class="form-container">
    <p>You can choose to activate or deactivate the session from here</p>
    <div class="btn-container">
      <button class="activate">
        <div class="icon"><i class="fa fa-check"></i></div>
        Activate
      </button>
      <button class="deactivate">
        <i class="fa fa-cancel"></i> Deactivate
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.form-container {
  width: 100%;
  background-color: var(--white);
  padding: 20px;

  .btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;

    button {
      padding: 10px 40px;
      border: none;
      cursor: pointer;
      display: flex;
      gap: 10px;
    }

    .activate {
      background-color: var(--blue-500);
      color: var(--white); 
    }
    .deactivate {
      background-color: rgb(194, 69, 47);
      color: var(--white);
    }
  }
}
</style>
